import React from 'react';
import { Button, ButtonProps } from '@clariness/button';
import { NavBarElement, NavBarElementProps } from '../NavBarElement/NavBarElement';

type NavBarButtonProps = NavBarElementProps & ButtonProps;

export const NavBarButton: React.FC<NavBarButtonProps> = ({
  icon,
  children,
  onClick, 
}) => {
  return (
    <Button
      variant="tertiary"
      size="medium"
      onClick={onClick}
      sx={{
        color: 'text1',
        fontWeight: 400,
      }}
    >
      <NavBarElement icon={icon}>
        {children}
      </NavBarElement>
    </Button>
  );
};
