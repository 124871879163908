import { IntlShape } from 'react-intl';

export function validatePasswordStrength(intl: IntlShape, isLogin = false) {
  if (isLogin) {
    // do not show password validation errors on login page
    return {
      validate: () => true,
    };
  }
  return {
    validate: 
    (value: any) => (/^(?=.*[a-z])(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*\-=.,'"[\](){}?_/\\;:|<>~`+])/.test(value) 
    && value.length >= 8) || intl.formatMessage({
      id: 'is.input.password_too_weak',
      defaultMessage: 
      'The password is too weak. Please use at least: ' + 
      '8 characters, one uppercase, one lowercase letter, a number and a special character',
    }),
  };
}
