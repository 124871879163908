import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { StyleObject } from '@clariness/base';
import { Text } from '@clariness/text';
import { LabeledContentTable } from './components';

type LabeledContentProps = {
  label: string | ReactNode;
  sx?: StyleObject,
  icon?: ReactElement;
}

export const LabeledContent = ({ label, icon, sx, children }: PropsWithChildren<LabeledContentProps>) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '17px',
        width: '100%',
        ...sx,
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Text
          variant="h4"
          sx={{ fontSize: '21px' }}
        >
          {label}
        </Text>

        {icon}
      </Flex>

      <Box
        as="hr"
        sx={{
          width: '100%',
          border: 'none',
          borderBottom: '1px solid #CECECE',
        }}
      />

      {children}
    </Flex>
  );
};

LabeledContent.Table = LabeledContentTable;
