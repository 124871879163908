import React from 'react';
import { Store } from 'store';
import { ArrowIcon } from '@clariness/icon';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { FormattedMessage } from 'react-intl';
import { usePatientDetailsContext } from 'components/modals/PatientDetailsModal';
import { Services } from 'services';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';

export const PatientDetailsFooter: React.FC = () => {
  const { currentReferral, referrals, isEditingNotes, setCurrentReferral } = usePatientDetailsContext();

  const currentReferralIndex = referrals.findIndex(referral => referral.id === currentReferral?.id);

  const { openModal, closeModal } = Store.useModal();

  const { redirect } = useRedirect();

  const { getPdf, isLoading } = Services.Referrals.useGetPdf();

  const { user } = Store.useUser();

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <ArrowIcon
          as="button"
          direction="top"
          disabled={Boolean(!referrals.length || currentReferralIndex === 0)}
          onClick={() => {
            const referral = referrals[currentReferralIndex - 1];
            setCurrentReferral(referral);
            redirect(Routing.HOME.getPath(referral.id));
          }}
        />

        <ArrowIcon
          as="button"
          direction="bottom"
          disabled={currentReferralIndex === referrals.length - 1}
          onClick={() => {
            const referral = referrals[currentReferralIndex + 1];
            setCurrentReferral(referral);
            redirect(Routing.HOME.getPath(referral.id));
          }}
        />

        <Button
          variant="tertiary"
          size="small"
          loading={isLoading}
          sx={{ ml: '75px' }}
          onClick={() => {
            captureEventV2(Events.InvestigatorService.InvestigatorExportedPatientProfile({ InvestigatorID: user?.id ?? '' }));
            getPdf(currentReferral); 
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.footer.export_pdf_button"
            defaultMessage="Export as PDF"
          />
        </Button>
      </Flex>

      <Flex sx={{ gap: '18px' }}>
        <Button
          variant="secondary"
          size="medium"
          disabled={isEditingNotes}
          onClick={() => {
            closeModal();
            redirect(Routing.HOME.getPath());
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.footer.cancel_button"
            defaultMessage="Cancel"
          />
        </Button>

        <Button
          disabled={isEditingNotes}
          size="medium"
          onClick={() => {
            openModal({
              modalType: 'UpdatePatientStatus',
              modalProps: {
                id: currentReferral?.id || '',
                status: currentReferral?.status,
                appointmentDateTime: currentReferral?.appointmentDateTime,
                referralDate: currentReferral?.referralDate,
                currentConsentedDate: currentReferral?.consentedDate,
                isVisited: currentReferral?.isVisited,
              },
            });
            redirect(Routing.HOME.getPath());
          }}
        >
          <FormattedMessage
            id="is.patient_details_modal.footer.update_status_button"
            defaultMessage="Update Status"
          />
        </Button>
      </Flex>
    </Flex>
  );
};
