import { FormattedMessage } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const concentedMainStatuses = {

  [ReferralStatus.RANDOMIZED]: {
    title: (
      <FormattedMessage
        id="is.referral_status.icf_signed_randomized.title"
        defaultMessage="Randomized"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.icf_signed_randomized.description"
        defaultMessage="Patient has been accepted into the clinical study."
      />
    ),
  },

  [ReferralStatus.SCREENING_FAILURE]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.icf_signed_screening_failure.title"
        defaultMessage="Screening failure"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.screening_failure.description"
        defaultMessage="Patient did not meet one or
         more criteria after the screening visit. 
        Results are formally confirmed by site."
      />
    ),
  },
};
