import * as ReactQuery from 'react-query';
import { InferMutationOptions, Services } from 'services';
import { ReferralInfoDto, ReferralSearchResultDto } from 'common/api';
import { Store } from 'store';
import * as ReactIntl from 'react-intl';
import { Events } from '@clariness/tracking-event-runtime';
import { captureEventV2 } from 'utils/events/captureEvent';
import { ReferralStatus } from 'global/referralStatus';
import { updateCallbackTimeslotAPI } from './api';

export const UPDATE_CALLBACK_TIME_KEY = '@referrals/update-callback-time';

export function useUpdateCallbackTime(
  options: InferMutationOptions<typeof updateCallbackTimeslotAPI>,
  refetchHistory: () => void,
) {
  const queryClient = ReactQuery.useQueryClient();

  const { showNotification } = Store.useNotifications();
  const { user } = Store.useUser();

  const intl = ReactIntl.useIntl();

  const {
    mutate,
    isLoading,
    isError,
  } = ReactQuery.useMutation(UPDATE_CALLBACK_TIME_KEY, updateCallbackTimeslotAPI, {
    ...options,
    onSuccess: (data, variables, context) => {
      captureEventV2(Events.InvestigatorService.InvestigatorUpdatedPatientStatus(
        { InvestigatorID: user?.id ?? '', StatusName: ReferralStatus.APPOINTMENT_SCHEDULED, PatientID: '' },
      ));
      queryClient.setQueriesData(Services.Referrals.KEYS.SEARCH_REFERRALS_KEY, ((currentData: any) => {
        const referrals = currentData as ReferralSearchResultDto;
        if (referrals) {
          const referralIndex = referrals.results.findIndex(referral => referral.id === variables.referralId);

          if (referralIndex !== -1) {
            const referral = referrals.results[referralIndex];
            referrals.results[referralIndex] = {
              ...referral,
              status: ReferralInfoDto.status[ReferralStatus.APPOINTMENT_SCHEDULED],
              appointmentDateTime: variables.callbackTimeslotDto.start,
            };
          }

          return referrals;
        }

        return referrals;
      }));

      queryClient.resetQueries(
        [Services.Referrals.KEYS.GET_REFERRAL_ACTIVITY_KEY, variables.referralId],
      );

      queryClient.resetQueries(
        [Services.Referrals.KEYS.GET_REFERRAL_KEY, variables.referralId],
      );

      showNotification({
        variant: 'success',
        description: intl.formatMessage({
          id: 'is.update_referral_status.success_notification_description',
          defaultMessage: 'Status updated',
        }),
      });

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, data, context) => {
      const errorMessage = error.isTranslationFromServer ? { id: error.translationKey, defaultMessage: error.message } : {
        id: 'server.errors.update_referral_status_error',
        defaultMessage: 'Unfortunately, something failed when updating the patient\'s status. Please, try again.',
      };
      showNotification({
        variant: 'error',
        description: intl.formatMessage({ id: errorMessage.id, defaultMessage: errorMessage.defaultMessage }),
      });

      if (options?.onError) {
        options.onError(error, data, context);
      }

      refetchHistory();
    },
  });

  return {
    updateCallbackTime: mutate,
    isLoading,
    isError,
  };
}
