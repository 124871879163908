import React, { useEffect, useState } from 'react';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { FormattedMessage } from 'react-intl';
import { Services } from 'services';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { addMinutes } from 'date-fns';
import { ShouldRender } from 'components/ShouldRender';
import { useMultiStep } from 'components/MultiStep';
import { convertDateToTargetTimeZone } from 'utils/format/convertDateToTargetTimeZone';

export const UpdatePatientStatusFooter: React.FC = () => {
  const {
    id,
    selectedStatus,
    status,
    appointmentScheduleDate,
    appointmentScheduleTime,
    initialAppointmentDate,
    initialAppointmentTime,
    currentConsentedDate,
    closeModal,
    selectedProtocol,
    consentedDate,
    currentIsVisited,
    canNextStep,
    setCanNextStep,
    parentStatus,
    setParentStatus,
    setSelectedStatus,
    isNewAppointmentNeeded,
    possibleNextStep,
    refetchHistory,
    protocolsData,
    timezoneData,
  } = useUpdatePatientStatusContext();

  const { nextStep } = useMultiStep();

  const { refetch: refetchProtocol } = Services.Referrals.useGetReferralProtocol(id as string);

  const { updateStatus, isLoading } = Services.Referrals.useUpdateReferralStatus({
    onSuccess: closeModal,
  }, refetchHistory, refetchProtocol);

  const { updateCallbackTime, isLoading: isUpdateCallbackTimeLoading } = Services.Referrals.useUpdateCallbackTime({
    onSuccess: closeModal,
  }, refetchHistory);

  const [isAppointmentScheduled, setIsAppointmentScheduled] = useState<boolean>(false);

  useEffect(() => {

    setIsAppointmentScheduled(selectedStatus === ReferralStatus.APPOINTMENT_SCHEDULED);
  }, [isAppointmentScheduled, selectedStatus]);

  const isButtonDisabled = (() => {

    if (isAppointmentScheduled) {
      const hasSelectedAppointment = appointmentScheduleDate && appointmentScheduleTime;
      const hasChangedAppointment = !(
        initialAppointmentTime === appointmentScheduleTime && initialAppointmentDate === appointmentScheduleDate
      );
      return (!hasSelectedAppointment || !hasChangedAppointment);
    }

    if (selectedStatus === ReferralStatus.CONSENTED || selectedStatus === ReferralStatus.WAITING_FOR_INFORMATION) {
      const isConsentedDateUnChanged = consentedDate ? currentConsentedDate === new Date(consentedDate)?.toISOString() : true;
      const isProtocolUnChanged = selectedProtocol ? protocolsData?.selectedProtocol?.id === selectedProtocol.id : true;
      const isAllDataSelected = protocolsData?.protocolList.length ?
        Boolean(selectedProtocol?.id && consentedDate) : Boolean(consentedDate);
      return isConsentedDateUnChanged && isProtocolUnChanged ? true : !isAllDataSelected;
    }

    if (parentStatus) {
      return selectedStatus === parentStatus;
    }

    return selectedStatus === status;
  })();

  const updateConcented = () => {
    if (!selectedProtocol?.id) {
      updateStatus({
        id,
        status: selectedStatus as ReferralStatusType,
        appointmentDate: (
          isAppointmentScheduled && appointmentScheduleDate ? appointmentScheduleDate.toISOString() : undefined
        ),
        consentedDate: consentedDate?.toISOString(),
        isVisited: Boolean(currentIsVisited),
      });
      return;
    }

    updateStatus({
      id,
      status: selectedStatus as ReferralStatusType,
      appointmentDate: (
        isAppointmentScheduled && appointmentScheduleDate ? appointmentScheduleDate.toISOString() : undefined
      ),
      protocolInfoDto: selectedProtocol,
      consentedDate: consentedDate?.toISOString(),
      isVisited: Boolean(currentIsVisited),
    });
  };

  const updateCallBackTime = (): void => {
    const appointmentScheduledEndDate = 
    convertDateToTargetTimeZone(
      addMinutes(appointmentScheduleDate as Date, 30), timezoneData?.siteTimeZone?.zoneName,
    )?.toISOString();
    updateCallbackTime({
      referralId: id as string,
      callbackTimeslotDto: {
        start: convertDateToTargetTimeZone(appointmentScheduleDate as Date, timezoneData?.siteTimeZone?.zoneName)?.toISOString(),
        end: appointmentScheduledEndDate,
      },
    });
  };

  const handleForceStatusUpdate = (forcedStatusUpdate: ReferralStatusType) => {

    if (forcedStatusUpdate === ReferralStatus.APPOINTMENT_SCHEDULED) {
      updateCallBackTime();
      return;
    }

    if (forcedStatusUpdate === ReferralStatus.CONSENTED) {
      updateConcented();
    }

    updateStatus({
      id,
      status: forcedStatusUpdate,
      isVisited: Boolean(currentIsVisited),
    });
  };

  const handleUpdateStatusClick = () => {
    if (selectedStatus === ReferralStatus.APPOINTMENT_SCHEDULED) {
      updateCallBackTime();
    } else if (selectedStatus === 'CONSENTED') {
      updateConcented();
    } else {
      updateStatus({
        id,
        status: selectedStatus as ReferralStatusType,
        appointmentDate: (
          isAppointmentScheduled && appointmentScheduleDate ? appointmentScheduleDate.toISOString() : undefined
        ),
        isVisited: Boolean(currentIsVisited),
      });
    }
  };

  const handleNextClick = () => {

    setCanNextStep(false);

    if (parentStatus === 'REVERT' && possibleNextStep) {
      handleForceStatusUpdate(possibleNextStep as ReferralStatusType);
      return;
    }

    if ((parentStatus === ReferralStatus.CONSENTED || parentStatus === ReferralStatus.WAITING_FOR_INFORMATION)
      && !currentIsVisited) {
      setParentStatus('IS_APPOINTMENT_HAPPEND');
      nextStep();
      return;
    }

    if (parentStatus === 'IS_APPOINTMENT_HAPPEND' && currentIsVisited) {
      setParentStatus(possibleNextStep || ReferralStatus.CONSENTED);
      return;
    }

    if (parentStatus === 'IS_APPOINTMENT_HAPPEND' && !currentIsVisited) {
      setParentStatus('IS_NEW_APPOINTMENT_NEEDED');
      return;
    }

    if (parentStatus === 'IS_NEW_APPOINTMENT_NEEDED' && isNewAppointmentNeeded) {
      setParentStatus(ReferralStatus.APPOINTMENT_SCHEDULED);
      setSelectedStatus(ReferralStatus.APPOINTMENT_SCHEDULED);
      return;
    }

    if (parentStatus === 'IS_NEW_APPOINTMENT_NEEDED' && !isNewAppointmentNeeded) {
      setSelectedStatus(ReferralStatus.DROPPED__NO_SHOW);
      handleForceStatusUpdate(ReferralStatus.DROPPED__NO_SHOW);
      return;
    }

    if (canNextStep) {
      nextStep();
      setCanNextStep(false);
    }
  };

  return (
    <Flex
      sx={{
        justifyContent: 'flex-end',
      }}
      gap={3}
    >
      <Button
        onClick={() => closeModal()}
      >
        <FormattedMessage
          id="is.update_protocol_modal.cancel_button"
          defaultMessage="Cancel"
        />
      </Button>
      <ShouldRender when={Boolean(canNextStep)}>
        <Button
          onClick={() => handleNextClick()}
        >
          <FormattedMessage
            id="is.update_patient_status_modal.next_button"
            defaultMessage="Next"
          />
        </Button>
      </ShouldRender>
      <ShouldRender when={Boolean(!canNextStep)}>
        <Button
          disabled={isButtonDisabled}
          loading={isLoading || isUpdateCallbackTimeLoading}
          onClick={() => handleUpdateStatusClick()}
        >
          <FormattedMessage
            id="is.update_patient_status_modal.update_button"
            defaultMessage="Update status"
          />
        </Button>
      </ShouldRender>

    </Flex>
  );
};
