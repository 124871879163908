import { ReferralStatusType } from 'global/referralStatus';

type StatusType = 'parent' | 'second';

/**
 * @param status - e.g CONTACTED, CONTACTED__ON_HOLD
 * @param type - parent | second
 * @return parsedStatus - status: CONTACTED__ON_HOLD, type: main => CONTACTED | type: second => ON_HOLD
 */
export function parseReferralStatus(status?: ReferralStatusType, type: StatusType | undefined = 'parent') {
  if (!status) {
    return null;
  }

  const splitStatus = status.split('__');
  return type === 'parent' ? splitStatus[0] : splitStatus[splitStatus.length - 1];
}
