/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LanguageDto } from './LanguageDto';
import type { ProtocolDto } from './ProtocolDto';
import type { StudyDto } from './StudyDto';

export type SiteEmployeeStudyDto = {
    id?: number;
    studySiteId?: number;
    siteCode: string;
    study: StudyDto;
    studySiteStatus: SiteEmployeeStudyDto.studySiteStatus;
    protocol: ProtocolDto;
    language: LanguageDto;
}

export namespace SiteEmployeeStudyDto {

    export enum studySiteStatus {
        SETUP = 'SETUP',
        CANCELED = 'CANCELED',
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        PAUSED = 'PAUSED',
        CLOSEOUT = 'CLOSEOUT',
        COMPLETED = 'COMPLETED',
    }


}
