import React from 'react';
import { Box } from '@clariness/box';
import { useTableContext } from 'components/Table';

export const TableHead: React.FC = () => {
  const { headerGroups } = useTableContext();

  return (
    <Box
      as="thead"
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'hgl4',
      }}
    >
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <Box
              as="th"
              sx={{
                p: '10px 15px',
                color: 'text1',
                fontSize: '1.125rem',
                fontWeight: 700,
                textAlign: 'left',
                boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
              }}
            >
              {column.render('Header')}
            </Box>
          ))}
        </tr>
      ))}
    </Box>
  );
};
