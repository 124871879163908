import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'services';
import { getReferralAPI } from './api';

export const GET_REFERRAL_KEY = '@referrals/get-referral';

export function useGetReferral(id = '', options?: InferQueryOptions<typeof getReferralAPI>) {
  const {
    data,
    isLoading,
    isError,
    refetch,
  } = ReactQuery.useQuery([GET_REFERRAL_KEY, id], () => getReferralAPI(id), {
    ...options,
    enabled: options?.enabled || Boolean(id),
  });

  return {
    referral: data,
    isLoading,
    isError,
    refetch,
  };
}
