import { ReferralStatus } from 'global/referralStatus';
import { referralStatusMapper } from 'utils/format';

export const referralFilterStatuses = {
  [ReferralStatus.NEW_REFERRAL]: {
    description: referralStatusMapper[ReferralStatus.NEW_REFERRAL].description,
  },
  [ReferralStatus.ATTEMPTING_TO_CONTACT]: {
    description: referralStatusMapper[ReferralStatus.ATTEMPTING_TO_CONTACT].description,
  },
  [ReferralStatus.CONTACTED]: {
    description: referralStatusMapper[ReferralStatus.CONTACTED].description,
    children: {
      [ReferralStatus.CONTACTED__CALLBACK]: {
        description: referralStatusMapper[ReferralStatus.CONTACTED__CALLBACK].description,
      },
      [ReferralStatus.CONTACTED__MEDICAL_RECORDS]: {
        description: referralStatusMapper[ReferralStatus.CONTACTED__MEDICAL_RECORDS].description,
      },
      [ReferralStatus.CONTACTED__WASHOUT_PERIOD]: {
        description: referralStatusMapper[ReferralStatus.CONTACTED__WASHOUT_PERIOD].description,
      },
      [ReferralStatus.CONTACTED__PATIENT_AVAILABILITY]: {
        description: referralStatusMapper[ReferralStatus.CONTACTED__PATIENT_AVAILABILITY].description,
      },
    },
  },
  [ReferralStatus.APPOINTMENT_SCHEDULED]: {
    description: referralStatusMapper[ReferralStatus.APPOINTMENT_SCHEDULED].description,
  },
  [ReferralStatus.CONSENTED]: {
    description: referralStatusMapper[ReferralStatus.CONSENTED].description,
  },
  [ReferralStatus.RANDOMIZED]: {
    description: referralStatusMapper[ReferralStatus.RANDOMIZED].description,
  },
  [ReferralStatus.SCREENING_FAILURE]: {
    description: referralStatusMapper[ReferralStatus.SCREENING_FAILURE].description,
  },

  [ReferralStatus.WAITING_FOR_INFORMATION]: {
    description: referralStatusMapper[ReferralStatus.WAITING_FOR_INFORMATION].description,
    children: {
      [ReferralStatus.WAITING_FOR_INFORMATION__MEDICAL_RECORD]: {
        description: referralStatusMapper[ReferralStatus.WAITING_FOR_INFORMATION__MEDICAL_RECORD].description,
      },
      [ReferralStatus.WAITING_FOR_INFORMATION__WASHOUT_PERIOD]: {
        description: referralStatusMapper[ReferralStatus.WAITING_FOR_INFORMATION__WASHOUT_PERIOD].description,
      },
      [ReferralStatus.WAITING_FOR_INFORMATION__WAITING_FOR_ICF]: {
        description: referralStatusMapper[ReferralStatus.WAITING_FOR_INFORMATION__WAITING_FOR_ICF].description,
      },
    },
  },
  
  [ReferralStatus.DROPPED]: {
    description: referralStatusMapper[ReferralStatus.DROPPED].description,
    children: {
      [ReferralStatus.DROPPED__UNABLE_TO_REACH]: {
        description: referralStatusMapper[ReferralStatus.DROPPED__UNABLE_TO_REACH].description,
      },
      [ReferralStatus.DROPPED__NOT_INTERESTED]: {
        description: referralStatusMapper[ReferralStatus.DROPPED__NOT_INTERESTED].description,
      },
      [ReferralStatus.DROPPED__FAILED_IN_EXCLUSION_CRITERIA]: {
        description: referralStatusMapper[ReferralStatus.DROPPED__FAILED_IN_EXCLUSION_CRITERIA].description,
      },
      [ReferralStatus.DROPPED__BAD_OR_DUPLICATE_PROFILE]: {
        description: referralStatusMapper[ReferralStatus.DROPPED__BAD_OR_DUPLICATE_PROFILE].description,
      },
      [ReferralStatus.DROPPED__NO_SHOW]: {
        description: referralStatusMapper[ReferralStatus.DROPPED__NO_SHOW].description,
      },
      [ReferralStatus.DROPPED__OTHER]: {
        description: referralStatusMapper[ReferralStatus.DROPPED__OTHER].description,
      },
    },
  },
};
