import React, { FC, useState } from 'react';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { CaretDownIcon, CaretUpIcon } from '@clariness/icon';
import { Box } from '@clariness/box';
import { StatusDot } from 'components/StatusDot';
import { useIntl } from 'react-intl';
import { ProductUpdates } from 'services/product-updates';

type ProductUpdateListItemProps = {
  id: string,
  lokaliseKey: string,
  fallbackTranslation: string,
  read: boolean,
  refetch: () => void;
}
export const ProductUpdateListItem: FC<ProductUpdateListItemProps> = ({
  id,
  lokaliseKey,
  fallbackTranslation,
  read,
  refetch,
}) => {
  const DEFAULT = 'DEFAULT';
  const [collapsed, setCollapsed] = useState(true);
  const intl = useIntl();
  let productUpdateTranslation = intl.formatMessage({
    id: lokaliseKey,
    defaultMessage: DEFAULT,
  });
  if (productUpdateTranslation === DEFAULT) productUpdateTranslation = fallbackTranslation;
  productUpdateTranslation = productUpdateTranslation.replaceAll('{linebreak}', '<br/>');
  const [title, description] = productUpdateTranslation.split(/\||{separator}/);
  const { setSiteEmployeeProductUpdatesRead } = ProductUpdates.useSetSiteEmployeeProductUpdatesRead({
    onSuccess: () => refetch(),
  });

  async function handleCollapseDown() {
    setCollapsed(false);
    if (!read) {
      await setSiteEmployeeProductUpdatesRead(id);
    }
  }

  function getDescription(): string {
    if (!description) return '';
    if (!collapsed) return description;

    if (description?.length > 30) {
      return `${description?.slice(0, 30)}...`;
    }

    return description;
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        gap: '12px',
        border: '0.5px solid',
        borderColor: 'hgl7',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        width: '100%',
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', gap: '5px' }}
      >
        <Flex gap={2}>
          <Text variant="h4">{title}</Text>
          {!read && <StatusDot color="primary1" />}
        </Flex>
        {collapsed
          ? (
            <CaretDownIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCollapseDown()}
              ml="5px"
            />
          )
          : (
            <CaretUpIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setCollapsed(true)}
              ml="5px"
            />
          )}
      </Flex>
      <Box sx={{ width: '100%' }}>
        <Text
          variant="p"
          dangerouslySetInnerHTML={{ __html: getDescription() }}
        />
      </Box>
    </Flex>
  );
};
