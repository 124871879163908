import React, { useState } from 'react';
import { Flex } from '@clariness/flex';
import { Logo } from 'components/Logo';
import { FormattedMessage } from 'react-intl';
import { Icons } from 'components/icons';
import { logout } from 'utils/auth';
import { Routing } from 'global/routing';
import { ExternalLinks } from 'global/externalLinks';
import { Store } from 'store';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { ProductUpdates } from 'components/ProductUpdates';
import { Services } from 'services';
import { PageLoader } from 'components/loaders';
import { NavBar } from './components';

export const Header: React.FC = () => {
  const { user } = Store.useUser();
  const [productUpdatesOpen, setProductUpdatesOpen] = useState(false);
  const { productUpdates, refetch } = Services.ProductUpdates.useGetSiteEmployeeProductUpdates(user?.id);
  const unreadCount = productUpdates.filter(pu => !pu.read).reduce(acc => acc + 1, 0);

  // Added loading for Authing logout (which takes time)
  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    return <PageLoader />;
  }
  
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '15px',
        px: window?.innerWidth < 1366 ? '80px' : '110px',
        boxShadow: '1px 0 2px rgba(0, 0, 0, 0.6)',
      }}
    >
      <Logo />

      <NavBar>
        <NavBar.ExternalLink
          onClick={() => captureEventV2(Events.InvestigatorService.InvestigatorAskHelp({ InvestigatorID: user?.id ?? '' }))}
          to={ExternalLinks.HELP}
          icon={<Icons.Help />}
        >
          <FormattedMessage
            id="is.nav_bar.help"
            defaultMessage="Help"
          />
        </NavBar.ExternalLink>

        <NavBar.Divider />

        <NavBar.Link
          onClick={() => {
            captureEventV2(Events.InvestigatorService.InvestigatorChecksAccount({ InvestigatorID: user?.id ?? '' }));
          }}
          to={Routing.ACCOUNT.getPath()}
          icon={<Icons.User />}
        >
          <FormattedMessage
            id="is.nav_bar.account"
            defaultMessage="Account"
          />
        </NavBar.Link>

        <NavBar.Divider />

        <NavBar.Button
          onClick={() => setProductUpdatesOpen(!productUpdatesOpen)}
          icon={<Icons.Bell counter={unreadCount} />}
        />

        <NavBar.Divider />

        <NavBar.Button
          onClick={() => {
            captureEventV2(Events.InvestigatorService.InvestigatorLogout({ InvestigatorID: user?.id ?? '' }));
            setIsLoading(true);
            logout();
          }}
          icon={<Icons.SignOut />}
        >
          <FormattedMessage
            id="is.nav_bar.logout"
            defaultMessage="Logout"
          />
        </NavBar.Button>
      </NavBar>
      <ProductUpdates
        open={productUpdatesOpen}
        setOpen={setProductUpdatesOpen}
        productUpdates={productUpdates}
        refetch={refetch}
      />
    </Flex>
  );
};
