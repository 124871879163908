/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SendAutomatedEmailToPatientDto = {
    referralId: string;
    templateKey: SendAutomatedEmailToPatientDto.templateKey;
}

export namespace SendAutomatedEmailToPatientDto {

    export enum templateKey {
        INVITE_TO_RESCHEDULE = 'INVITE_TO_RESCHEDULE',
        SCHEDULED_MEETING_CONFIRMATION = 'SCHEDULED_MEETING_CONFIRMATION',
        INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
        NEWSLETTER_INVITATION = 'NEWSLETTER_INVITATION',
        SITE_EMPLOYEE_INVITATION = 'SITE_EMPLOYEE_INVITATION',
        RESET_PASSWORD = 'RESET_PASSWORD',
        NEW_APPOINTMENT = 'NEW_APPOINTMENT',
        NEW_REFERRAL = 'NEW_REFERRAL',
        WEEKLY_NEW_REFERRAL = 'WEEKLY_NEW_REFERRAL',
        PATIENT_APPOINTMENT_REMINDER = 'PATIENT_APPOINTMENT_REMINDER',
        FOLLOWUP_ON_CLINLIFE_REGISTRATION = 'FOLLOWUP_ON_CLINLIFE_REGISTRATION',
        FINAL_ATTEMPT_NOTICE = 'FINAL_ATTEMPT_NOTICE',
    }


}
