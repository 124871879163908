import { useEffect, useState } from 'react';
import { Store } from 'store';
import { Services } from 'services';
import { LabeledContent, LabeledRow } from 'components/content';
import { formatDate } from 'utils/format';
import { ShouldRender } from 'components/ShouldRender';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProtocolDto, ProtocolInfoDto, ReferralDto } from 'common/api';
import { LabeledRadioButton } from '@clariness/labeled-radio-button';
import { Box } from '@clariness/box';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';

type PatientDetailsBasicProps = {
  referral?: ReferralDto;
};

export const PatientDetailsBasic = ({ referral }: PatientDetailsBasicProps) => {
  const intl = useIntl();
  const { protocolsData } = Services.Referrals.useGetReferralProtocol(referral?.id || '');
  const { openModal, closeModal } = Store.useModal();
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolInfoDto>();

  useEffect(() => {
    if (protocolsData?.selectedProtocol) {
      setSelectedProtocol(protocolsData?.selectedProtocol);
    }
  }, [protocolsData]);

  const handleChangeProtocol = (protocol: ProtocolInfoDto) => {
    setSelectedProtocol(protocol);
    closeModal();
    if (referral?.id) {
      openModal({
        modalType: 'ProtocolConfirmation',
        modalProps: {
          referral,
          protocol: { ...protocol },
        },
      });
    }
  };

  return (
    <>
      <LabeledContent
        label={intl.formatMessage({
          id: 'is.patient_details_modal.basic_details_title',
          defaultMessage: 'Basic Details',
        })}
      >
        <LabeledContent.Table>
          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.patient_id',
              defaultMessage: 'Patient ID',
            })}
          >
            {referral?.patient.internalId}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.first_name',
              defaultMessage: 'First Name',
            })}
          >
            {referral?.patient.firstName}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.last_name',
              defaultMessage: 'Last Name',
            })}
          >
            {referral?.patient.lastName}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.gender',
              defaultMessage: 'Gender',
            })}
          >
            {referral?.gender === ReferralDto.gender.M
              ? intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.gender.male',
                defaultMessage: 'Male',
              })
              : ''}
            {referral?.gender === ReferralDto.gender.F
              ? intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.gender.female',
                defaultMessage: 'Female',
              })
              : ''}
            {referral?.gender === ReferralDto.gender.U
              ? intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.gender.other',
                defaultMessage: 'Other',
              })
              : ''}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.age',
              defaultMessage: 'Age',
            })}
          >
            <ShouldRender when={Boolean(referral?.ageAtTimeOfRegister)}>
              <FormattedMessage
                id="is.patient_details_modal.basic_details.age_clarification"
                defaultMessage="{ age } (at point of registration)"
                values={{ age: referral?.ageAtTimeOfRegister }}
              />
            </ShouldRender>
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.registration_date',
              defaultMessage: 'Registered on',
            })}
          >
            {formatDate(
              intl.formatDate(
                referral && new Date(referral.registrationDate ? referral.registrationDate : referral.referralDate),
              ),
            )}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.language',
              defaultMessage: 'Language',
            })}
          >
            {referral?.language.name}
          </LabeledRow>

          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.study',
              defaultMessage: 'Study',
            })}
          >
            {referral?.study.name}
          </LabeledRow>
          <LabeledRow
            label={intl.formatMessage({
              id: 'is.patient_details_modal.basic_details.Site',
              defaultMessage: 'Site',
            })}
          >
            {referral?.site?.name}
          </LabeledRow>

          <ShouldRender when={
            protocolsData?.protocolList?.length !== 0 &&
            protocolsData?.protocolsCount !== 0
          }
          >
            <LabeledRow
              label={intl.formatMessage({
                id: 'is.patient_details_modal.basic_details.available_protocols',
                defaultMessage: 'Available Protocols',
              })}
            >
              {protocolsData?.protocolList?.map((protocol: ProtocolInfoDto) => (
                <Box
                  key={protocol.id}
                  sx={{
                    marginBottom: '8px !important',
                  }}
                >
                  <LabeledRadioButton
                    label={`${protocol.internalId || '...'} - ${protocol.name}`}
                    id={protocol.id.toString()}
                    value={protocol.id}
                    checked={protocol.id === selectedProtocol?.id}
                    onChange={() => handleChangeProtocol(protocol)}
                  />
                </Box>
              ))}
            </LabeledRow>

          </ShouldRender>
        </LabeledContent.Table>
      </LabeledContent>
      <ShouldRender when={Boolean(referral?.patient?.qualifiedStudyProtocols
        && referral?.patient?.qualifiedStudyProtocols?.length > 0)}
      >
        <Flex sx={{
          flexDirection: 'column',
          gap: '2',
        }}
        >
          <Text variant="pBold">
            <FormattedMessage
              id="is.qualified.study.protocols.message"
              defaultMessage="Based on screener answers eligible for :"
            />
          </Text>
          <Text variant="p">
            {referral?.patient?.qualifiedStudyProtocols?.map((protocol: ProtocolDto) => (
              ` ${protocol.name}`
            )).join(',')}
          </Text>
        </Flex>
      </ShouldRender>
    </>
  );
};
