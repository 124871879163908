import { ReferralInfoDto } from 'common/api';
import { formatDate, FormatReferralStatus } from 'utils/format';
import { Box } from '@clariness/box';
import { Button } from '@clariness/button';
import { Checkbox } from '@clariness/checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import * as ReactTable from 'react-table';
import { Table } from 'components/Table';
import { Store } from 'store';
import { ReferralStatusType } from 'global/referralStatus';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';

// Columns accessors are related to data keys
export const referralColumns = [
  {
    id: 'selection',
    Header: ({ getToggleAllPageRowsSelectedProps, onClick }: ReactTable.TableInstance) => {
      const props = getToggleAllPageRowsSelectedProps();
      const { indeterminate } = props;
      return (
        <Checkbox
          {...props}
          sx={{ cursor: 'pointer' }}
          indeterminate={indeterminate ? indeterminate.toString() : undefined}
          onClick={e => {
            if (onClick) onClick(e);
          }}
        />
      );
    },
    Cell: ({ row, onClick }: ReactTable.TableInstance) => {
      const props = row.getToggleRowSelectedProps();
      const { indeterminate } = props;
      return (
        <Checkbox
          {...props}
          key={row.original.patientInternalId}
          sx={{ cursor: 'pointer' }}
          indeterminate={indeterminate ? indeterminate.toString() : undefined}
          onClick={() => onClick(row)}
          data-test-id="referral-table-checkbox"
        />
      );
    },
  },
  {
    id: 'study',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.patient_details_modal.basic_details.study',
            defaultMessage: 'Study',
          }) as any}
        >
          <FormattedMessage
            id="is.patient_details_modal.basic_details.study"
            defaultMessage="Study"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const value = `${row?.original.trackingId} ${row.original?.studyName}`;
      return (
        <Table.CellTooltip 
          trigger={(value.substring(0, 15))}
          triggerStyles={{
            display: 'initial',
          }}
        >
          {value}
        </Table.CellTooltip>
      );
    },
  },
  {
    id: 'patientInternalId',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.referrals_table.patient_id_column',
            defaultMessage: 'Patient ID',
          }) as any}
        >
          <FormattedMessage
            id="is.referrals_table.patient_id_column"
            defaultMessage="Patient ID"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const value = row?.original?.patientInternalId;
      return (
        <Box>
          {value}
        </Box>
      );
    },
  },
  {
    id: 'patientName',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.patient_name.title',
            defaultMessage: 'Patient Name',
          }) as any}
        >
          <FormattedMessage
            id="is.patient_name.title"
            defaultMessage="Patient Name"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const value = `${row?.original.firstName} ${row.original?.lastName}`;
      return (
        <Box>
          {value}
        </Box>
      );
    },
  },
  {
    id: 'referralDate',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.referrals_table.referral_date_column',
            defaultMessage: 'Referral date',
          }) as any}
        >
          <FormattedMessage
            id="is.referrals_table.referral_date_column"
            defaultMessage="Referral date"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const intl = useIntl();
      const value = row?.original.referralDate;
      return (
        <Box>
          {formatDate(intl.formatDate(new Date(value)))}
        </Box>
      );
    },
  },
  {
    id: 'lastUpdate',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.last_update.title',
            defaultMessage: 'Last Update',
          }) as any}
        >
          <FormattedMessage
            id="is.last_update.title"
            defaultMessage="Last Update"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.TableInstance) => {
      const intl = useIntl();
      const value = row?.original.lastUpdate;
      return (
        <Box>
          {formatDate(intl.formatDate(new Date(value)))}
        </Box>
      );
    },
  },
  {
    id: 'patientDetails',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.referrals_table.patient_details_column',
            defaultMessage: 'Patient details',
          }) as any}
        >
          <FormattedMessage
            id="is.referrals_table.patient_details_column"
            defaultMessage="Patient details"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ rows, row }: ReactTable.TableInstance) => {
      const referral = row.original;
      const referrals = rows.map((row: ReactTable.Row<any>) => row.original);

      const { openModal } = Store.useModal();

      const { redirect } = useRedirect();

      const { user } = Store.useUser();

      return (
        <Button
          variant="tertiary"
          size="medium"
          sx={{
            textDecoration: 'underline',
            fontSize: '1.125rem',
            fontFamily: 'Noto Sans',
            fontWeight: '400',
            width: '164px',
          }}
          onClick={() => {
            captureEventV2(Events.InvestigatorService.InvestigatorViewedPatientProfile({ InvestigatorID: user?.id ?? '' }));
            openModal({
              modalType: 'PatientDetails',
              modalProps: { referral, referrals },
            });

            redirect(Routing.HOME.getPath(referral.id));
          }}
          data-test-id="patient-details-button"
        >
          <FormattedMessage
            id="is.contact_center.table.button_details"
            defaultMessage="Details"
          />
        </Button>
      );
    },
  },
  {
    id: 'status',
    Header: () => {
      const intl = useIntl();
      return (
        <Table.CellTooltip
          trigger={intl.formatMessage({
            id: 'is.referrals_table.status_column',
            defaultMessage: 'Status',
          }) as any}
        >
          <FormattedMessage
            id="is.referrals_table.status_column"
            defaultMessage="Status"
          />
        </Table.CellTooltip>
      );
    },
    Cell: ({ row }: ReactTable.Cell) => {
      const { openModal } = Store.useModal();
      const { id, status, appointmentDateTime, consentedDate, isVisited, referralDate } = row.original as {
        id: string,
        status: ReferralStatusType,
        appointmentDateTime?: string;
        consentedDate? : string;
        isVisited: boolean;
        referralDate: string;
      };
      
      return (
        <Box
          onClick={() => openModal({
            modalType: 'UpdatePatientStatus',
            modalProps: {
              id,
              status,
              appointmentDateTime,
              currentConsentedDate: consentedDate,
              isVisited,
              referralDate,
            },
          })}
          sx={{
            cursor: 'pointer',
          }}
        >
          {FormatReferralStatus(status, appointmentDateTime)}
        </Box>
      );
    },
  },
];

export const mapReferralsToAccessor = (referral: ReferralInfoDto) => ({
  id: referral.id,
  studyName: referral.studyName,
  trackingId: referral.trackingId,
  studyId: referral.studyId,
  appointmentDateTime: referral.appointmentDateTime,
  patientInternalId: referral.patient.internalId,
  firstName: referral.patient.firstName,
  lastName: referral.patient.lastName,
  referralDate: referral.referralDate,
  status: referral.status,
  patientLanguage: referral.patient.languageCode,
  consentedDate: referral.consentedDate,
  isVisited: referral.isVisited,
  lastUpdate: referral.lastUpdate,
});

export type Referral = ReturnType<typeof mapReferralsToAccessor>;
