import React from 'react';
import { AuthPageLayout } from 'components/layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthPageLayoutForm } from 'components/layout/AuthPageLayout/components';
import { Schemas, useFormValidation } from 'utils/validation';
import { Button } from '@clariness/button';
import { PasswordField } from '@clariness/password-field';
import { Services } from 'services';
import { useQueryParams, useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';

export const ChangePassword = () => {
  const { register, handleSubmit, formState: { errors }, getValues } = useFormValidation();

  const intl = useIntl();

  const queryParams = useQueryParams();

  const { redirect } = useRedirect();

  const { changeForgottenPassword, isLoading } = Services.SiteEmployees.useChangeForgottenPassword({
    onSuccess: () => redirect(Routing.LOGIN.getPath()),
  });

  return (
    <AuthPageLayout>
      <AuthPageLayout.Logo />

      <AuthPageLayout.Heading>
        <FormattedMessage
          id="is.change_password.heading"
          defaultMessage="Reset Password"
        />
      </AuthPageLayout.Heading>

      <AuthPageLayoutForm
        onSubmit={handleSubmit(({ newPassword }) => changeForgottenPassword({
          token: queryParams.get('token') || '',
          password: newPassword,
        }))}
      >
        <PasswordField
          {...register('newPassword', Schemas.password(intl))}
          placeholder={intl.formatMessage({
            id: 'is.change_password.new_password_input_placeholder',
            defaultMessage: 'New password',
          })}
          error={errors?.newPassword?.message}
          data-test-id="change-password-input"
        />

        <PasswordField
          {...register('confirmNewPassword', {
            validate: (value: string) => value === getValues('newPassword') || intl.formatMessage({
              id: 'is.change_password.confirm_new_password.password_no_match_validation_message',
              defaultMessage: 'Your passwords don\'t match',
            }),
          })}
          placeholder={intl.formatMessage({
            id: 'is.change_password.confirm_new_password_input_placeholder',
            defaultMessage: 'Confirm new password',
          })}
          error={errors?.confirmNewPassword?.message}
          data-test-id="confirm-change-password-input"
        />

        <Button
          type="submit"
          loading={isLoading}
          data-test-id="change-password-button"
        >
          <FormattedMessage
            id="is.change_password.main_button"
            defaultMessage="Change password"
          />
        </Button>
      </AuthPageLayoutForm>

      <AuthPageLayout.InfoLinks />
      <AuthPageLayout.Footer />
    </AuthPageLayout>
  );
};
