import React from 'react';
import { Link as StyledLink, LinkProps } from '@clariness/link';
import * as ReactRouterDom from 'react-router-dom';

export const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  return (
    <StyledLink
      as={ReactRouterDom.Link}
      {...props}
    >
      {children}
    </StyledLink>
  );
};
