import React from 'react';
import { Icon, IconProps } from '@clariness/icon';

export const Help: React.FC<IconProps> = ({ size = 21, ...props }) => {
  return (
    <Icon {...props}>
      <svg
        width={size}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M10.1208 18.3337C14.779 18.3337 18.5551 14.6027 18.5551 10.0003C18.5551 5.39795 14.779 1.66699 10.1208 1.66699C5.46269 1.66699 1.68652 5.39795 1.68652 10.0003C1.68652 14.6027 5.46269 18.3337 10.1208 18.3337Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.66699 7.50047C7.86529 6.94353 8.25668 6.4739 8.77185 6.17475C9.28702 5.87561 9.89271 5.76626 10.4817 5.86607C11.0706 5.96588 11.6048 6.26841 11.9896 6.72008C12.3745 7.17175 12.5851 7.74341 12.5842 8.33381C12.5842 10.0005 10.0539 10.8338 10.0539 10.8338"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.1211 14.167H10.1295"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Icon>
  );
};
