import { useSendInvitationLinkEmail } from 'services/site-employees/send-invitation-link-email/hook';
import { useGetCurrentSiteEmployee } from './get-current-site-employee/hook';
import { useCanRegister } from './can-register/hook';
import { useFinishRegistration } from './finish-registration/hook';
import { useGetLanguages } from './get-languages/hook';
import { useUpdateCurrentSiteEmployee } from './update-current-site-employee/hook';
import { useUpdatePassword } from './update-password/hook';
import { useSendForgotPasswordEmail } from './send-forgot-password-email/hook';
import { useChangeForgottenPassword } from './change-forgotten-password/hook';
import { useGetInvestigatorStudies } from './get-investigator-studies/hook';
import { useUpdateInvestigatorNotification } from './update-investigator-notification/hook';

export const SiteEmployees = {
  useSendInvitationLinkEmail,
  useGetCurrentSiteEmployee,
  useUpdateCurrentSiteEmployee,
  useUpdatePassword,
  useCanRegister,
  useFinishRegistration,
  useGetLanguages,
  useSendForgotPasswordEmail,
  useChangeForgottenPassword,
  useGetInvestigatorStudies,
  useUpdateInvestigatorNotification,
};
