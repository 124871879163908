/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryDto } from './CountryDto';
import type { StudySiteDto } from './StudySiteDto';
import type { TimeZoneDto } from './TimeZoneDto';

export type SiteDto = {
    id?: string;
    name: string;
    type: SiteDto.type;
    department?: string;
    street: string;
    zipCode: string;
    city: string;
    country: CountryDto;
    email?: string;
    website?: string;
    phone?: string;
    subsites: Array<SiteDto>;
    parentSiteId?: string;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    location?: string;
    distanceFromPatientInKM?: number;
    internalId?: string;
    externalId?: string;
    studySites?: Array<StudySiteDto>;
    timeZone?: TimeZoneDto;
    externalName?: string;
    externalAddress?: string;
    isDuplicated?: boolean;
    siteAvailability?: string;
}

export namespace SiteDto {

    export enum type {
        UNIVERSITY = 'UNIVERSITY',
        HOSPITAL = 'HOSPITAL',
        VIRTUAL = 'VIRTUAL',
        OTHER = 'OTHER',
        SMO = 'SMO',
        PROFESSIONAL_RESEARCH_SITE = 'PROFESSIONAL_RESEARCH_SITE',
        DOCTORS_PRACTICE = 'DOCTORS_PRACTICE',
    }


}
