import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'services';
import { getReferralProtocolAPI } from './api';

export const GET_REFERRAL_PROTOCOL_KEY = '@referrals/get-referral-protocol';

export function useGetReferralProtocol(
  referralId: string,
  options?: InferQueryOptions<typeof getReferralProtocolAPI>,
) {
  const { data, isLoading, isError, refetch } = ReactQuery.useQuery(
    [GET_REFERRAL_PROTOCOL_KEY, referralId],
    () => getReferralProtocolAPI(referralId),
    {
      enabled: !!referralId,
      ...options,
    },
  );

  return {
    protocolsData: data,
    isLoading,
    isError,
    refetch,
  };
}
