import * as ReactQuery from 'react-query';
import { Routing } from 'global/routing';
import { removeRefreshToken, setAccessToken, setRefreshToken } from 'utils/auth';
import { Services } from 'services';
import { refreshAccessTokenAPI } from './api';

const REFRESH_TOKEN_KEY = '@auth/refresh-access-token';

export function useRefreshAccessToken() {
  const { isLoading, isError, isSuccess } = ReactQuery.useQuery(
    REFRESH_TOKEN_KEY,
    refreshAccessTokenAPI,
    {
      onSuccess: data => {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
      },
      onError: () => {
        if (navigator.onLine) {
          removeRefreshToken();
          window.location.href = Routing.LOGIN.getPath();
        }
      },
      refetchInterval: 180000, // 3 minutes
      refetchIntervalInBackground: true,
      refetchOnReconnect: true,
      staleTime: 920000, // 4 minutes
    },

  );

  const siteEmployeeService = Services.SiteEmployees.useGetCurrentSiteEmployee({ enabled: isSuccess });

  return {
    isLoading: isLoading || siteEmployeeService.isLoading,
    isError,
  };
}
