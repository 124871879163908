import React from 'react';
import { Text, TextProps } from '@clariness/text';

type AccountPageLayoutParagraphProps = TextProps;

export const AccountPageLayoutParagraph = ({ children, ...props }: AccountPageLayoutParagraphProps) => {
  return (
    <Text
      variant="p"
      {...props}
    >
      {children}
    </Text>
  );
};
