import React, { ReactElement } from 'react';
import { Flex } from '@clariness/flex';
import { StyleObject } from '@clariness/base';
import { Text } from '@clariness/text';

export type NavBarElementProps = {
  sx?: StyleObject;
  icon: ReactElement;
}

export const NavBarElement: React.FC<NavBarElementProps> = ({ sx, icon, children }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: '5px',
        color: 'text-primary',
        ...sx,
      }}
    >
      {icon}

      <Text sx={{ color: 'inherit' }}>
        {children}
      </Text>
    </Flex>
  );
};
