import * as ReactQuery from 'react-query';
import { InferMutationOptions, Services } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { updateReferralNoteAPI } from './api';

const UPDATE_REFERRAL_NOTE_KEY = '@referrals/update-referral-note';

export function useUpdateReferralNote(options?: InferMutationOptions<typeof updateReferralNoteAPI>) {
  const queryClient = ReactQuery.useQueryClient();

  const { showNotification } = Store.useNotifications();

  const intl = useIntl();
  
  const {
    mutate,
    isLoading,
    isError,
  } = ReactQuery.useMutation(UPDATE_REFERRAL_NOTE_KEY, updateReferralNoteAPI, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData([Services.Referrals.KEYS.GET_REFERRAL_KEY, variables.id], (currentData: any) => ({
        ...currentData,
        note: variables.note,
      }));
      
      queryClient.resetQueries([Services.Referrals.KEYS.GET_REFERRAL_ACTIVITY_KEY, variables.id]);
      
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context); 
      }
    },
    onError: (error, data, context) => {
      showNotification({
        variant: 'error',
        description: intl.formatMessage({
          id: 'server.errors.update_referral_note_error',
          defaultMessage: 'Unfortunately, something failed when updating the patient\'s notes. Please, try again.',
        }),
      });

      if (options?.onError) {
        options.onError(error, data, context);
      }
    },
  });

  return {
    updateReferralNote: mutate,
    isLoading,
    isError,
  };
}
