import { removeRefreshToken, setAccessToken } from 'utils/auth/tokens';
import { Routing } from 'global/routing';
import { authing } from 'global/authing';

export function logout() {
  authing.logout().finally(() => {
    setAccessToken(null);
    removeRefreshToken();
    window.location.href = Routing.LOGIN.getPath();
  });
}
