import React from 'react';
import * as ReactIntl from 'react-intl';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { Flex } from '@clariness/flex';
import { Text } from '@clariness/text';
import { RadioButton } from '@clariness/radio-button';
import { FormattedMessage } from 'react-intl';
import { UpdateStatusLayout } from '../UpdateStatusLayout/UpdateStatusLayout';

export const AppointmentOutcome: React.FC = () => {
  const intl = ReactIntl.useIntl();

  const {
    setCurrentIsVisited,
    setCanNextStep,
  } = useUpdatePatientStatusContext();

  return (
    <UpdateStatusLayout
      heading={intl.formatMessage({
        id: 'is.referral_status.visit_happening_question',
        defaultMessage: 'Did the patient attend their scheduled appointment? ',
      })}
    >
      <Flex
        gap={3}
        sx={{
          alignItems: 'center',
          height: '30px',
        }}
      >
        <RadioButton
          name="isVisited"
          id="isVisited"
          value="isVisited"
          onClick={() => {
            setCurrentIsVisited(true);
            setCanNextStep(true);
          }}
        />
        <Text variant="p">
          <FormattedMessage
            id="is.yes"
            defaultMessage="Yes"
          />
        </Text>
      </Flex>
      <Flex
        gap={3}
        sx={{
          alignItems: 'center',
          height: '30px',
        }}
      >
        <RadioButton
          name="isVisited"
          id="isVisited"
          value="isVisited"
          onClick={() => {
            setCurrentIsVisited(false);
            setCanNextStep(true);
          }}
        />
        <Text variant="p">
          <FormattedMessage
            id="is.no"
            defaultMessage="No"
          />
        </Text>
      </Flex>
    </UpdateStatusLayout>
  );
};
