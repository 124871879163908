import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { getUrlLocale } from 'utils/routing';
import { fallbackLocale } from 'global/supportedCountries';
import { sendForgotPasswordEmailAPI } from './api';

const SEND_FORGOT_PASSWORD_EMAIL_KEY = '@site-employees/send-forgot-password-email';

export function useSendForgotPasswordEmail(options?: InferMutationOptions<typeof sendForgotPasswordEmailAPI>) {
  const { showNotification } = Store.useNotifications();
  
  const { user } = Store.useUser();
  
  const intl = useIntl();
  
  const { mutate, isLoading } = ReactQuery.useMutation(
    SEND_FORGOT_PASSWORD_EMAIL_KEY,
    sendForgotPasswordEmailAPI,
    {
      ...options,
      onError: (error, variables, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.send_forgot_password_email_error',
            defaultMessage: 'Unfortunately, an error occurred while we tried to send you an email. Please try again.',
          }),
        });

        if (options?.onError) {
          options.onError(error, variables, context);
        }
      },

      onSuccess: (data, variables, context) => {
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'is.send_forgot_password_email.success_notification_message',
            defaultMessage: 'We\'ve sent you an email with a link to reset your password',
          }),
        });

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
  
  return {
    sendForgotPasswordEmail: (email: string) => (
      mutate({ email, languageCode: user?.languageCode || getUrlLocale() || fallbackLocale }) 
    ),
    isLoading,
  };
}
