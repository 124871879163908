import React, { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@clariness/box';
import { Text } from '@clariness/text';

type LabeledRowProps = {
  label: string | ReactNode;
}

export const LabeledRow = ({ label, children }: PropsWithChildren<LabeledRowProps>) => {
  return (
    <Box
      as="tr"
    >
      <Box
        as="th"
        sx={{
          textAlign: 'left',
          pb: '15px',
          width: '150px',
          minWidth: '150px',
        }}
      >
        <Text
          variant="pBold"
        >
          {label}
        </Text>
      </Box>

      <Box
        as="td"
        sx={{ pl: '40px', pb: '15px' }}
      >
        <Text
          variant="p"
        >
          {children}
        </Text>
      </Box>
    </Box>
  );
};
