import React from 'react';
import { Flex } from '@clariness/flex';
import { ExternalLink } from 'components/links';
import { FormattedMessage } from 'react-intl';
import { Divider } from 'components/Divider';
import { ExternalLinks } from 'global/externalLinks';

const linkStyles = {
  fontSize: '16px',
};

export const AuthPageLayoutInfoLinks: React.FC = () => {
  return (
    <Flex
      sx={{
        gap: '15px',
        mt: '35px',
      }}
    >
      <ExternalLink
        to={ExternalLinks.PRIVACY_POLICY}
        sx={linkStyles}
      >
        <FormattedMessage
          id="is.auth_page.privacy_link"
          defaultMessage="Privacy"
        />
      </ExternalLink>

      <Divider />

      <ExternalLink
        to={ExternalLinks.IMPRINT}
        sx={linkStyles}
      >
        <FormattedMessage
          id="is.auth_page.imprint_link"
          defaultMessage="Imprint"
        />
      </ExternalLink>
    </Flex>
  );
};
