import React, { ReactNode, ReactNodeArray } from 'react';
import { Flex } from '@clariness/flex';
import { NavBarButton, NavBarDivider, NavBarExternalLink, NavBarLink } from './components';

type NavBarProps = {
  children: ReactNode | ReactNodeArray;
}

export const NavBar = ({ children }: NavBarProps) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        gap: '15px',
      }}
    >
      {children}
    </Flex>
  );
};

NavBar.Button = NavBarButton;
NavBar.Link = NavBarLink;
NavBar.ExternalLink = NavBarExternalLink;
NavBar.Divider = NavBarDivider;
