import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { MultipleInvestigatorNotificationUpdateDto } from 'common/api';
import { InferMutationOptions } from 'services';
import { updateInvestigatorNotificationAPI } from './api';

const GET_INVESTIGATOR_STUDIES_KEY = '@site-employees/get-investigator-studies';

export function useUpdateInvestigatorNotification(
  options?: InferMutationOptions<typeof updateInvestigatorNotificationAPI>,
) {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { isLoading, mutate } = ReactQuery.useMutation(GET_INVESTIGATOR_STUDIES_KEY,
    updateInvestigatorNotificationAPI, {
      ...options,
      onError: () => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.get_data_error',
            defaultMessage: 'Unfortunately, something failed when fetching data. Please, try again later.',
          }),
        });
      },

      onSuccess: (data, variables, context) => {
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'is.update_account_details.success_notification_message',
            defaultMessage: 'Your account details have been updated',
          }),
        });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
 
  return {
    updateNotification: (multipleInvestigatorNotificationUpdateDto: Partial<MultipleInvestigatorNotificationUpdateDto>) => (
      mutate({ ...multipleInvestigatorNotificationUpdateDto } as MultipleInvestigatorNotificationUpdateDto)
    ),
    isLoading,
  };
}
