import { UserAvailabilityInfo } from 'common/types/registration.types';
import { IntlShape } from 'react-intl';
import { CallbackTimeslotDto } from 'common/api';

export function displayContentValue(value: string | undefined, intl?: IntlShape) {
  return UserAvailabilityInfo.displayMessageForIS(value || '', intl) || '-';
}

export function displayCallbackTime(value?: CallbackTimeslotDto, intl?: IntlShape) {
  return UserAvailabilityInfo.parseCallbackTimeslotISUser(value, intl);
}
