import React, { ReactNode } from 'react';
import { Flex } from '@clariness/flex';
import { RadioButtonProps } from '@clariness/radio-button';
import { CheckCircleIcon } from '@clariness/icon';
import { Text } from '@clariness/text';
import { StatusType }
  from 'components/modals/UpdatePatientStatusModal/components/UpdatePatientMainStatus/UpdatePatientMainStatus';
import { ReferralActivityDto } from 'common/api';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { formatDate } from 'utils/format';
import { useIntl } from 'react-intl';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';

type UpdateStatusCheckboxProps = RadioButtonProps & {
  statusTitle?: ReactNode;
  statusDescription?: ReactNode;
  statuesType: StatusType;
  referralActivity?: ReferralActivityDto;
  historyAppointmentDataTime?: string;
  referralStatus: ReferralStatusType
}

function getStyles(statusType: StatusType) {
  switch (statusType) {
    case StatusType.ACTIVE: return {
      box: { borderColor: 'primary1' },
    };
    case StatusType.SELECTED: return {
      box: {
        borderColor: 'primary2',
        backgroundColor: 'primary-2',
      },
    };
    case StatusType.UPCOMING: return {
      box: {
        borderColor: 'primary1',
      },
    };
    case StatusType.HISTORY: return {
      text: { color: 'text-inverted' },
      box: {
        border: 'none',
        backgroundColor: 'text3',
      },
    };
    case StatusType.PAST_APPOINTMENT: return {
      box: { borderColor: 'red-alert' },
    };
    case StatusType.SELECTED_PAST_APPOINTMENT: return {
      box: {
        borderColor: 'red-alert',
        backgroundColor: '#FED8AE',
      },
    };
    default: return {};
  }
}

export const UpdateStatusTile: React.FC<UpdateStatusCheckboxProps> = ({
  statusTitle,
  statusDescription,
  statuesType,
  historyAppointmentDataTime,
  referralStatus,
  ...props
}) => {

  const intl = useIntl();

  const {
    appointmentDateTime,
    timezoneData,
    selectedProtocol,
    consentedDate,
    referralDate,
  } = useUpdatePatientStatusContext();

  const getAppointmentDateTime = (): string | undefined => {
    if (statuesType === StatusType.HISTORY && historyAppointmentDataTime) {
      return historyAppointmentDataTime;
    }
    return appointmentDateTime;
  };

  const style = getStyles(statuesType);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        border: '2px solid',
        borderRadius: '8px',
        padding: '16px 10px',
        flexShrink: 0,
        width: '100%',
        cursor: 'pointer',
        alignItems: 'center',
        gap: '5px',
        margin: 0,
        ...style.box,
      }}
      {...props}
    >

      {statuesType === StatusType.ACTIVE && (
        <CheckCircleIcon
          size={27}
          color="primary1"
        />
      )}
      {(statuesType === StatusType.PAST_APPOINTMENT || statuesType === StatusType.SELECTED_PAST_APPOINTMENT)
        && (
          <CheckCircleIcon
            size={27}
            color="red-alert"
          />
        )}

      <Text
        variant="pBold"
        sx={{ ...style.text }}
      >
        {statusTitle}
      </Text>

      {(statuesType === StatusType.UPCOMING || statuesType === StatusType.SELECTED) && (
        <Text
          variant="p"
          sx={{
            ...style.text,
          }}
        >
          {statusDescription}
        </Text>
      )}

      {referralDate && referralStatus === ReferralStatus.NEW_REFERRAL && (
        <Text
          variant="p"
          sx={{
            ...style.text,
          }}
        >
          {formatDate(intl.formatDate(new Date(referralDate as string)))}
        </Text>
      )}

      {referralStatus === ReferralStatus.APPOINTMENT_SCHEDULED && getAppointmentDateTime() && (
        <Text
          variant="p"
          sx={{
            ...style.text,
          }}
        >
          {`
            ${new Date(getAppointmentDateTime()
            ?? '')?.toLocaleDateString('de-DE', {
            timeZone: timezoneData?.siteTimeZone.zoneName,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })} 
          ${new Date(getAppointmentDateTime() ?? '')?.toLocaleTimeString('en-US',
            { timeZone: timezoneData?.siteTimeZone.zoneName, hour: '2-digit', minute: '2-digit' })
            } 
          - ${timezoneData?.siteTimeZone.zoneName}`}
        </Text>
      )}

      {referralStatus === ReferralStatus.CONSENTED &&
        (
          <>
            <Text
              variant="p"
              sx={{
                ...style.text,
              }}
            >{selectedProtocol?.name}
            </Text>
            {
              consentedDate && (
                <Text
                  variant="p"
                  sx={{
                    ...style.text,
                  }}
                >{new Date(consentedDate
                  || '')?.toLocaleDateString(timezoneData?.siteTimeZone.countryCode)}
                </Text>
              )
            }
          </>
        )}

    </Flex>
  );
};
