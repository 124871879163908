import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@clariness/box';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReferralSearchFilterDto } from 'common/api';
import { generateContext } from 'utils/context';
import { useHomePageContext } from 'pages';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';
import { Text } from '@clariness/text';
import { InputField } from '@clariness/input-field';
import { ReferralTableStudies } from './components/ReferralTableStudies/ReferralTableStudies';
import { ReferralTableFilterStatus, ReferralTableFilterTags } from './components';

type Props = {
  showFilters: boolean,
}

type ReferralTableFilterContext = {
  localFilterOptions?: ReferralSearchFilterDto,
  setLocalFilterOptions: Dispatch<SetStateAction<ReferralSearchFilterDto | undefined>>
};

export const [
  useReferralTableFilterContext,
  ReferralTableFilterProvider,
] = generateContext<ReferralTableFilterContext>();

export const ReferralTableFilter = ({ showFilters }: Props) => {

  const { user } = Store.useUser();

  const intl = useIntl();
  const { filterOptions, setFilterOptions, setPaginationOptions, setFilterTags } = useHomePageContext();

  const [localFilterOptions, setLocalFilterOptions] = useState(filterOptions);

  useEffect(() => {
    setLocalFilterOptions(filterOptions);
  }, [filterOptions]);

  return (
    <ReferralTableFilterProvider
      value={{
        localFilterOptions,
        setLocalFilterOptions,
      }}
    >
      <Flex flexDirection="column">
        <Flex
          sx={{
            alignItems: 'center',
            gap: '30px',
            marginBottom: '23px',
          }}
        >
          <ReferralTableFilterTags />
        </Flex>
        {
          showFilters && (
          <Flex
            sx={{
              alignItems: 'center',
              gap: '20px',
              marginBottom: '36px',
            }}
          >
            <Box>
              <Text
                variant="small"
                style={{
                  display: 'block',
                  marginBottom: '5px',
                }}
              >
                <FormattedMessage
                  id="is.patient_status.title"
                  defaultMessage="Patient Status"
                />
              </Text>
              <ReferralTableFilterStatus />
            </Box>

            <Box>
              <Text
                variant="small"
                style={{
                  display: 'block',
                  marginBottom: '5px',
                }}
              >
                <FormattedMessage
                  id="is.patient_name.title"
                  defaultMessage="Patient Name"
                />
                /
                <FormattedMessage
                  id="is.referrals_table.patient_id_column"
                  defaultMessage="Patient ID"
                />
              </Text>
              <InputField
                placeholder={
                intl.formatMessage({
                  id: 'is.patient_name.title',
                  defaultMessage: 'Patient Name',
                })
                }
                value={localFilterOptions?.patientName || ''}
                onChange={e => {
                  setLocalFilterOptions({
                    ...localFilterOptions,
                    patientName: e.target.value.replace(/\./g, ''),
                  });
                }}
                marginBottom="-6px"
              />
            </Box>

            <Box>
              <Text
                variant="small"
                style={{
                  display: 'block',
                  marginBottom: '5px',
                }}
              >
                <FormattedMessage
                  id="is.main_page.referrals_table.study_label"
                  defaultMessage="Study"
                />
              </Text>
              <ReferralTableStudies />
            </Box>

            <Button
              onClick={() => {
                captureEventV2(Events.InvestigatorService.InvestigatorFiltersPatientLists({ InvestigatorID: user?.id ?? '' }));
                setFilterOptions(localFilterOptions);
                setPaginationOptions(s => ({ ...s, skip: 0 }));
              }}
              data-test-id="referral-table-apply-button"
              variant="primary"
              size="medium"
              style={{
                marginTop: '28px',
              }}
            >
              <FormattedMessage
                id="is.main_page.filter_table.apply_button"
                defaultMessage="Apply"
              />
            </Button>

            <Button
              variant="tertiary"
              onClick={() => {
                setLocalFilterOptions(undefined);
                setFilterOptions(s => ({ studyIds: s?.studyIds }));
                setFilterTags(undefined);
              }}
              data-test-id="referral-table-clear-button"
              size="medium"
              style={{
                marginTop: '28px',
              }}
            >
              <FormattedMessage
                id="is.main_page.filter_table.clear_button"
                defaultMessage="Clear all"
              />
            </Button>
          </Flex>
          )
        }

      </Flex>
    </ReferralTableFilterProvider>
  );
};
