import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import * as Pages from 'pages';
import { Routing } from 'global/routing';
import { AuthGuard } from 'app/AuthGuard';
import { Header } from 'app/Header';
import { Footer } from 'app/Footer';
import { MainPageLayout } from 'app/MainPageLayout';
import { Flex } from '@clariness/flex';

export const Routes: React.FC = () => (
  <ReactRouterDom.Switch>
    <ReactRouterDom.Route
      path={Routing.LOGIN.getPath()}
      component={Pages.Login}
    />

    <ReactRouterDom.Route
      path={Routing.REGISTER.getPath()}
      component={Pages.Register}
    />

    <ReactRouterDom.Route
      path={Routing.FORGOT_PASSWORD.getPath()}
      component={Pages.ForgotPassword}
    />

    <ReactRouterDom.Route
      path={Routing.CHANGE_PASSWORD.getPath()}
      component={Pages.ChangePassword}
    />

    {/*Protected routes START*/}
    <AuthGuard>
      <MainPageLayout>
        <Header />

        <Flex sx={{ flex: 1 }}>
          <ReactRouterDom.Switch>
            <ReactRouterDom.Route
              exact
              path={Routing.BASE_URL.getPath()}
            >
              <ReactRouterDom.Redirect to={Routing.HOME.getPath()} />
            </ReactRouterDom.Route>

            <ReactRouterDom.Route
              path={Routing.HOME.getPath(':id?')}
              component={Pages.Home}
            />

            <ReactRouterDom.Route
              path={Routing.ACCOUNT.getPath()}
              component={Pages.Account}
            />
          </ReactRouterDom.Switch>
        </Flex>

        <Footer />
      </MainPageLayout>
    </AuthGuard>
    {/*Protected routes END*/}
  </ReactRouterDom.Switch>
);
