import { serviceRequest } from 'utils/http';
import { Service } from 'common/api';

type UpdateReferralNote = {
  id?: string;
  note: string | undefined;
}

export function updateReferralNoteAPI({ id, note }: UpdateReferralNote) {
  return serviceRequest(() => Service.referralControllerChangeNoteAsCurrentUser(id || '', { note }));
}
