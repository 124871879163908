import React, { useState } from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { Box } from '@clariness/box';
import { Checkbox } from '@clariness/checkbox';
import { Text } from '@clariness/text';
import { Button } from '@clariness/button';
import { ArrowIcon } from '@clariness/icon';
import { Grid } from 'theme-ui';
import { ShouldRender } from 'components/ShouldRender';
import { MultipleInvestigatorNotificationUpdateDto } from 'common/api';
import { BoxLoader } from 'components/loaders';
import { formData } from './AccountNotification';

interface Props {
  formDataList: Array<formData>,
  notifications: MultipleInvestigatorNotificationUpdateDto,
  setNotifications: (value: MultipleInvestigatorNotificationUpdateDto) => void
  isFetching: boolean,
  setIsSaveEnabled: (value: boolean) => void
}

export const NewReferralNotification: React.FC<Props> = ({
  formDataList,
  isFetching,
  setIsSaveEnabled,
  notifications,
  setNotifications,
}: Props) => {

  const [isShowStudies, setIsShowStudies] = useState(true);

  const isWeeklyChecked = (value: any): boolean => {
    return Boolean(notifications.investigatorNotificationUpdateDto
      .find(investigator => investigator.id === value)?.weeklyReferralRecipient);
  };

  const isInstantChecked = (value: any): boolean => {
    return Boolean(notifications.investigatorNotificationUpdateDto
      .find(investigator => investigator.id === value)?.referralRecipient);
  };

  const isAllChecked = (): boolean => {
    return Boolean(!notifications.investigatorNotificationUpdateDto
      .find(investigator => !investigator.weeklyReferralRecipient || !investigator.referralRecipient));
  };

  return (
    <AccountPageLayout>
      {isFetching ? <BoxLoader /> : (
        <Flex
          gap={3}
          sx={{ flexDirection: 'column' }}
        >
          <Grid sx={{
            gridTemplateColumns: '5% 80% 15%',
            alignItems: 'center',
            marginBottom: '10px',
          }}
          >
            <Checkbox
              value="allNotification"
              name="allNotification"
              className="checkbox"
              checked={isAllChecked()}
              onChange={e => {
                const updateData = notifications
                  .investigatorNotificationUpdateDto
                  .map(investigator => {
                    return { ...investigator, weeklyReferralRecipient: e.target.checked, referralRecipient: e.target.checked };
                  });
                setNotifications({
                  investigatorNotificationUpdateDto: updateData,
                });
                setIsSaveEnabled(false);
              }}
              data-test-id="all-notifications-checkbox"
            />

            <Text
              variant="h4"
              sx={{
                color: !isAllChecked() && 'text2',
              }}
            >
              <FormattedMessage
                id="is.account_notification.new_referral_label"
                defaultMessage="New referral"
              />
            </Text>

            <Button
              sx={{
                marginLeft: 'auto',
              }}
              variant="tertiary"
              size="extraLarge"
              onClick={() => setIsShowStudies(!isShowStudies)}
              icon={<ArrowIcon direction={isShowStudies ? 'top' : 'bottom'} />}
              data-test-id="site-employee-back-button"
            />
            <Box />
            <Text
              variant="p"
              sx={{
                color: !isAllChecked() && 'text2',
              }}
            >
              <FormattedMessage
                id="is.account_notification.new_referral_paragraph"
                defaultMessage="E-mail notification, send on weekly basis with a list New Referrals in Investigator Portal"
              />
            </Text>

          </Grid>

          <ShouldRender when={isShowStudies}>
            <Flex
              gap={3}
              sx={{ flexDirection: 'column' }}
            >
              {
                formDataList.map(formData => {
                  return (
                    <Box
                      sx={{
                        margin: '5px',
                      }}
                      key={formData.studyName}
                    >
                      <Grid sx={{ gridTemplateColumns: '5% 75% 20%' }}>
                        <Box />
                        <Box sx={{
                          marginBottom: '10px',
                        }}
                        >
                          <Text
                            variant="pBold"
                          >
                            {formData.studyName}
                          </Text>
                        </Box>
                        <Box />
                      </Grid>
                      <Grid sx={{ gridTemplateColumns: '5% 75% 20%' }}>
                        <Box />
                        <Box />
                        <Flex
                          gap={2}
                          sx={{ justifyContent: 'center' }}
                        >
                          <Text variant="small">
                            Weekly
                          </Text>
                          <Text variant="small">
                            Instant
                          </Text>
                        </Flex>

                        {formData.value?.map(investigator => (
                          <React.Fragment key={investigator.id}>
                            <Box />

                            <Text
                              variant="p"
                              sx={{
                                maxWidth: '400px',
                                color: !isWeeklyChecked(investigator.id) && !isInstantChecked(investigator.id) && 'text2',
                              }}
                            >
                              {investigator.studyName} ({investigator.studyLanguage})
                            </Text>
                            <Flex
                              gap={4}
                              sx={{ justifyContent: 'center' }}
                            >
                              <Checkbox
                                value={investigator.id}
                                name="weeklyNotification"
                                className="checkbox"
                                checked={isWeeklyChecked(investigator.id)}
                                onChange={e => {
                                  const update = notifications.investigatorNotificationUpdateDto
                                    .map(investigator => {
                                      if (investigator.id.toString() === e.target.value) {
                                        return { ...investigator, weeklyReferralRecipient: e.target.checked };
                                      }
                                      return { ...investigator };
                                    });
                                  setNotifications({
                                    investigatorNotificationUpdateDto: update,
                                  });
                                  setIsSaveEnabled(false);
                                }}
                              />

                              <Checkbox
                                value={investigator.id}
                                name="instantNotification"
                                className="checkbox"
                                checked={isInstantChecked(investigator.id)}
                                onChange={e => {
                                  const update = notifications.investigatorNotificationUpdateDto
                                    .map(investigator => {
                                      if (investigator.id.toString() === e.target.value) {
                                        return { ...investigator, referralRecipient: e.target.checked };
                                      }
                                      return { ...investigator };
                                    });
                                  setNotifications({
                                    investigatorNotificationUpdateDto: update,
                                  });
                                  setIsSaveEnabled(false);
                                }}
                                data-test-id="instant-notification-checkbox"
                              />
                            </Flex>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Box>
                  );
                })
              }
            </Flex>
          </ShouldRender>
        </Flex>
      )}
    </AccountPageLayout>
  );
};
