import { UpdatePatientStatusModal } from './UpdatePatientStatusModal';
import { PatientDetailsModal } from './PatientDetailsModal';
import { PatientDetailsProtocolConfirmation } from './PatientDetailsModal/components';

export * from './ModalContainer/ModalContainer';

export const Modals = {
  UpdatePatientStatus: UpdatePatientStatusModal,
  PatientDetails: PatientDetailsModal,
  ProtocolConfirmation: PatientDetailsProtocolConfirmation,
};
