/**
 * @param date - string value of a date
 * @example 26/02/2021 -> 26 / 02 / 2021
 */
export function formatDate(date: string) {
  return date.replace(/\//g, ' / ');
}

export function isDateInPast(dateString:string) {
  const now = new Date();
  const date = new Date(dateString);
  return now > date;
}
