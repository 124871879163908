import React from 'react';
import { Services } from 'services';
import { usePatientDetailsContext } from 'components/modals/PatientDetailsModal';
import { ShouldRender } from 'components/ShouldRender';
import { BoxLoader } from 'components/loaders';
import { Flex } from '@clariness/flex';
import { ServiceError } from 'components/ServiceError';
import { FormattedMessage } from 'react-intl';
import { PatientDetailsBasic, PatientDetailsContact, PatientDetailsPreScreener } from './components';

export const PatientDetailsProfile = () => {
  const { currentReferral } = usePatientDetailsContext();

  const { referral, isLoading, isError, refetch } = Services.Referrals.useGetReferral(currentReferral?.id);
  
  if (isError) {
    return (
      <ServiceError onRetry={refetch}>
        <FormattedMessage
          id="is.patient_details_modal.generic_error_message"
          defaultMessage="Something went wrong while we tried to display the patient's details."
        />
      </ServiceError>
    );
  }

  return (
    <React.Fragment>
      <Flex
        sx={{
          overflow: isLoading && 'hidden',
          width: '100%',
          height: 'fit-content',
          gap: '95px',
        }}
      >
        <Flex
          sx={{
            flexBasis: '100%',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <PatientDetailsBasic referral={referral} />
          <PatientDetailsContact referral={referral} />
        </Flex>

        <Flex sx={{ flexBasis: '70%' }}>
          <PatientDetailsPreScreener referral={referral} />
        </Flex>
      </Flex>

      <ShouldRender when={isLoading}>
        <BoxLoader />
      </ShouldRender>
    </React.Fragment>
  );
};
