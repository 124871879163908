import React from 'react';
import { Form, FormProps } from 'components/form-elements';

type AuthPageLayoutFormProps = FormProps;

export const AuthPageLayoutForm: React.FC<AuthPageLayoutFormProps> = ({ children, ...props }) => {
  return (
    <Form
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
        fontSize: '18px',
        textAlign: 'start',
      }}
      {...props}
    >
      {children}
    </Form>
  );
};
