import { FormattedMessage } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const newReferralMainStatuses = {
  [ReferralStatus.ATTEMPTING_TO_CONTACT]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.attempting_to_contact.title"
        defaultMessage="Attempting to Contact"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.attempting_to_contact.description"
        defaultMessage="I am trying to contact the patient but haven't reached them yet."
      />
    ),
  },
  [ReferralStatus.CONTACTED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.contacted.title"
        defaultMessage="Contacted"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.contacted.description"
        defaultMessage="I have contacted the patient."
      />
    ),
  },
  
  [ReferralStatus.APPOINTMENT_SCHEDULED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.contacted_appointment_scheduled.title"
        defaultMessage="Appointment scheduled"
      />
    ),
    description: (
      <FormattedMessage
        id="is.update_referral_status.contacted_appointment_scheduled.description"
        defaultMessage="I have scheduled an appointment with the patient."
      />
    ),
  },
  [ReferralStatus.DROPPED]: {
    title: (
      <FormattedMessage
        id="is.update_referral_status.dropped.title"
        defaultMessage="Dropped"
      />
    ),
    description: (
      <FormattedMessage
        id="is.referral_status.dropped.description"
        defaultMessage="The patient will not be considered for the clinical study."
      />
    ),
  },
};
