import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LanguageDto } from '../../../../../common/api/models/LanguageDto';
import { getLanguagesAPI } from './api';

const GET_LANGUAGES_KEY = '@site-employees/get-languages';

export function useGetLanguages() {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { data, isLoading } = ReactQuery.useQuery(GET_LANGUAGES_KEY, getLanguagesAPI, {
    onError: () => {
      showNotification({
        variant: 'error',
        description: intl.formatMessage({
          id: 'server.errors.get_languages_error',
          defaultMessage: 'Unfortunately, something failed when fetching your languages. Please, try again later.',
        }),
      });
    },
  });

  const { user } = Store.useUser();

  const languages = useMemo(() => {
    const userCountries = data?.filter(country => user?.sites?.find(s => s.countryCode === country.code));
    const languageList = userCountries?.map(c => c.languages).flat();

    const languageDistinct = new Array<LanguageDto>();

    if (languageList) {
      for (const l of languageList) {
        if (l && !languageDistinct.find(ld => ld.code === l.code)) {
          languageDistinct.push(l);
        }
      }
    }

    return languageDistinct || [];
  }, [data, user]);

  return {
    languages,
    isLoading,
  };
}
