import React, { useEffect, useState } from 'react';
import * as _ from 'lodash-es';
import { parseReferralStatus } from 'utils/format';

import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { BoxLoader } from 'components/loaders';
import { ReferralActivityDto } from 'common/api';
import { Flex } from '@clariness/flex';
import { useIntl } from 'react-intl';
import { getHours, getMinutes } from 'date-fns';
import { UpdateStatusTile } from '../UpdateStatusTile/UpdateStatusTile';
import * as flows from '../MainStatusflows';

export enum StatusType {
  ACTIVE,
  SELECTED,
  UPCOMING,
  HISTORY,
  PAST_APPOINTMENT,
  SELECTED_PAST_APPOINTMENT
}

export interface HistoryDataType {
  referralActivity: ReferralActivityDto,
  mainReferralStatuses: any,
  status: ReferralStatusType
}

export const UpdatePatientMainStatus: React.FC = () => {
  const { selectedStatus,
    setSelectedStatus,
    setParentStatus,
    parentStatus,
    history,
    isFetching,
    setCanNextStep,
    setPossibleNextStep,
    currentIsVisited,
    setAppointmentScheduleDate,
    setAppointmentScheduleTime,
    referralActivities,
  } = useUpdatePatientStatusContext();

  const isRadioButtonDisabled = (key: string): boolean => {
    return (key === 'ICF_SIGNED') || false;
  };

  const intl = useIntl();

  const finalStepStaus: Array<ReferralStatusType> =
    [
      ReferralStatus.RANDOMIZED,
      ReferralStatus.DROPPED,
      ReferralStatus.SCREENING_FAILURE,
    ];

  const [currectStatus, setCurrectStatus] = useState<ReferralStatusType>(selectedStatus as ReferralStatusType);
  const [selectedStatusData, setSelectedStatusData] = useState<any>();
  const [currentReferralStatusFlow, setCurrentReferralStatusFlow] = useState<any>();

  let historyData: Array<HistoryDataType> | undefined;

  useEffect(() => {
    if (currectStatus) {
      const currentParentStatus = currectStatus.split('__')[0] as ReferralStatusType;
      if (currentParentStatus) {
        setCurrectStatus(currentParentStatus);
      }

      const selectedStatusData = flows.mainReferralStatuses[currentParentStatus || currectStatus];
      setSelectedStatusData(selectedStatusData);

      switch (currentParentStatus || currectStatus) {
        case 'NEW_REFERRAL':
        case 'ATTEMPTING_TO_CONTACT':
        case 'CONTACTED':
          setCurrentReferralStatusFlow(flows.newReferralMainStatuses);
          break;
        case 'APPOINTMENT_SCHEDULED':
          setCurrentReferralStatusFlow(flows.appointmentMainStatuses);
          break;
        case 'CONSENTED':
          setCurrentReferralStatusFlow(flows.concentedMainStatuses);
          break;
        case 'WAITING_FOR_INFORMATION':
          setCurrentReferralStatusFlow(flows.waitingForInfromationMainStatuses);
          break;
        default:
          setCurrentReferralStatusFlow({});
      }
    }

  }, [currectStatus]);

  if (history) {
    history.referralActivities = history.referralActivities?.map(referralActivity => {
      return referralActivity.referralStatus ?
        referralActivity : {
          ...referralActivity,
          referralStatus: ReferralStatus.NEW_REFERRAL,
        };
    });

    historyData = history.referralActivities?.map(referralActivity => {
      return {
        referralActivity,
        mainReferralStatuses: flows.mainReferralStatuses[referralActivity.referralStatus],
        status: referralActivity.referralStatus,
      };
    });
  }

  const getOnClickHandler = (status: ReferralStatusType) => {
    if (!currentIsVisited &&
      (status === ReferralStatus.WAITING_FOR_INFORMATION || status === ReferralStatus.CONSENTED)) {
      setPossibleNextStep(status);
    }

    switch (status) {
      case ReferralStatus.NEW_REFERRAL:
        break;
      case ReferralStatus.ATTEMPTING_TO_CONTACT:
      case ReferralStatus.RANDOMIZED:
      case ReferralStatus.SCREENING_FAILURE:
        setParentStatus('');
        setCanNextStep(false);
        setSelectedStatus(status);
        break;
      case ReferralStatus.APPOINTMENT_SCHEDULED:
        setParentStatus(status);
        setSelectedStatus(status);
        setCanNextStep(true);
        break;
      default:
        setSelectedStatus(status);
        setParentStatus(status);
        setCanNextStep(true);
    }
  };

  const handleRevert = () => {
    if (historyData) { 
      setParentStatus('REVERT');

      const lastReferralActivity = 
      referralActivities?.referralActivities?.filter((item: ReferralActivityDto) => item.event === 'STATUS_CHANGE' &&
      !item.referralStatus.startsWith('DROPPED'))[0];
      
      const appointmentDateTime = lastReferralActivity ? 
        lastReferralActivity.appointmentDateTime : historyData[historyData.length - 1].referralActivity.appointmentDateTime;
      const status = lastReferralActivity ? lastReferralActivity.referralStatus : historyData[historyData.length - 1].status;

      if (appointmentDateTime) {
        setAppointmentScheduleDate(new Date(appointmentDateTime));
        setAppointmentScheduleTime(appointmentDateTime ? ({
          hours: getHours(new Date(appointmentDateTime)),
          minutes: getMinutes(new Date(appointmentDateTime)),
        }) : undefined);
      }

      const newStatus = (status === ReferralStatus.NEW_REFERRAL || status === ReferralStatus.IN_CALL_CENTER) ?
        ReferralStatus.ATTEMPTING_TO_CONTACT : status;

      setPossibleNextStep(newStatus);
      setCanNextStep(true);
    }
  };

  if (isFetching) {
    return <BoxLoader />;
  }

  const handleStatusType = () : StatusType => {
    if (finalStepStaus.includes(currectStatus)) {
      return StatusType.HISTORY;
    }
    if (parentStatus === currectStatus) {
      return StatusType.SELECTED;
    }
    return StatusType.ACTIVE;
  };

  return (
    <Flex sx={{
      flexDirection: 'column',
      gap: '12px',
      width: '100%',
    }}
    >
      {_.map(historyData, data => (
        currectStatus !== data.status && (
          <UpdateStatusTile
            key={data.status}
            value={data.status}
            checked={parseReferralStatus(selectedStatus) === data.status}
            statusTitle={data.mainReferralStatuses.title}
            statusDescription={data.mainReferralStatuses.description}
            disabled={isRadioButtonDisabled(data.status)}
            historyAppointmentDataTime={data.referralActivity.appointmentDateTime}
            statuesType={StatusType.HISTORY}
            referralActivity={data.referralActivity}
            referralStatus={data.status}
          />
        )
      ))}

      <UpdateStatusTile
        key={currectStatus}
        value={currectStatus}
        onClick={() => getOnClickHandler(currectStatus)}
        statusTitle={selectedStatusData?.title}
        statusDescription={selectedStatusData?.description}
        statuesType={handleStatusType()}
        referralStatus={currectStatus}
        referralActivity={(currectStatus === ReferralStatus.NEW_REFERRAL && 
          historyData && historyData[0].referralActivity) || undefined}
      />

      {currectStatus === 'DROPPED' && (
        <UpdateStatusTile
          key="revert"
          value="revert"
          onClick={() => handleRevert()}
          statusTitle={intl.formatMessage({
            id: 'is.referral_status.revert.title',
            defaultMessage: 'Revert status label',
          })}
          statusDescription={intl.formatMessage({
            id: 'is.referral_status.revert.description',
            defaultMessage: 'The patient status will be reverted to the previous one',
          })}
          statuesType={(parentStatus === 'REVERT') ? StatusType.SELECTED : StatusType.UPCOMING}
          referralStatus={currectStatus}
        />
      )}

      {_.map(currentReferralStatusFlow, (status, key: ReferralStatusType) => (
        !historyData?.find(history => history.status === key) && currectStatus !== key && (
          <UpdateStatusTile
            key={key}
            value={key}
            checked={parseReferralStatus(selectedStatus) === key}
            onClick={() => getOnClickHandler(key)}
            statusTitle={status.title}
            statusDescription={status.description}
            disabled={isRadioButtonDisabled(key)}
            statuesType={(selectedStatus === key || parentStatus === key) ? StatusType.SELECTED : StatusType.UPCOMING}
            referralStatus={key}
          />
        )
      ))}
    </Flex>
  );
};
