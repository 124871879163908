/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SiteEscalationDTO = {
    id?: number;
    date: string;
    reason?: SiteEscalationDTO.reason;
    details?: string;
    active?: boolean;
    studySiteId: number;
    createdBy?: string;
    updatedBy?: string;
}

export namespace SiteEscalationDTO {

    export enum reason {
        NO_RESPONSE_FROM_THE_SITE = 'NO_RESPONSE_FROM_THE_SITE',
        PATEINT_BACKLOG = 'PATEINT_BACKLOG',
        NO_RESPONSE_FROM_THE_SITE_PATIENT_BACKLOG = 'NO_RESPONSE_FROM_THE_SITE_PATIENT_BACKLOG',
        OTHER = 'OTHER',
    }


}
