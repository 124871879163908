import React from 'react';
import { MultiStep } from 'components/MultiStep';
import { AccountDetailsChangePassword, AccountDetailsForgotPassword, AccountDetailsForm } from './components';

export const AccountDetails = () => {
  return (
    <MultiStep>
      <MultiStep.Step>
        <AccountDetailsForm />
      </MultiStep.Step>

      <MultiStep.Step>
        <AccountDetailsChangePassword />
      </MultiStep.Step>

      <MultiStep.Step>
        <AccountDetailsForgotPassword />
      </MultiStep.Step>
    </MultiStep>
  );
};
