import * as ReactQuery from 'react-query';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { getUserStudiesAPI } from './api';

const GET_USER_STUDIES_KEY = '@studies/get-user-studies';

export function useGetUserStudies() {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();
  
  const { data, isLoading, isError } = ReactQuery.useQuery(GET_USER_STUDIES_KEY, getUserStudiesAPI, {
    onError: () => {
      showNotification({
        variant: 'error',
        description: intl.formatMessage({
          id: 'server.errors.get_studies_error',
          defaultMessage: 'Unfortunately, something failed when fetching your studies. Please, try again later.',
        }),
      });
    },
  });

  return {
    studies: data,
    isLoading,
    isError,
  };
}
