import axios, { AxiosResponse } from 'axios';
import { Environment } from 'global/environment';
import { OpenAPI } from 'common/api';
import { handleHttpError, handleServiceError } from './handleError';
import { RequestConfig } from './types';

OpenAPI.BASE = Environment.BACKEND_API;

export const axiosInstance = axios.create();

export async function serviceRequest<T>(apiRequest: () => Promise<T>): Promise<T>;
export async function serviceRequest<T, Data>(
  apiRequest: () => Promise<T>,
  mapResponse?: (response: T) => Data,
): Promise<Data>;
export async function serviceRequest<T, Data>(apiRequest: () => Promise<T>, mapResponse?: (response: T) => Data) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiRequest();
      resolve(mapResponse ? mapResponse(data) : data);
    } catch (err: any) {
      reject(handleServiceError(err));
    }
  });
}

function makeHttpRequest<T>(apiCall: () => Promise<AxiosResponse<T>>, mapData?: (data: any) => T): Promise<T> {
  return new Promise(async (resolve, reject) => {
    try {
      const data: AxiosResponse = await apiCall();
      resolve(mapData ? mapData(data.data) : data.data);
    } catch (e: any) {
      reject(handleHttpError(e));
    }
  });
}

export function getRequest<T>(path: string, config?: RequestConfig<T>): Promise<T> {
  return makeHttpRequest<T>(() => axiosInstance.get(path, config?.options), config?.mapData);
}

export function postRequest<T>(path: string, data: any, config?: RequestConfig<T>): Promise<T> {
  return makeHttpRequest<T>(() => axiosInstance.post(path, data, config?.options), config?.mapData);
}

export function putRequest<T>(path: string, data: any, config?: RequestConfig<T>): Promise<T> {
  return makeHttpRequest<T>(() => axiosInstance.put(path, data, config?.options), config?.mapData);
}

export function deleteRequest<T>(path: string, config?: RequestConfig<T>): Promise<T> {
  return makeHttpRequest<T>(() => axiosInstance.delete(path, config?.options), config?.mapData);
}
