/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IndicationDto } from './IndicationDto';
import type { ProtocolInfoDto } from './ProtocolInfoDto';
import type { StudyExternalIdDto } from './StudyExternalIdDto';

export type StudyInfoDto = {
    id?: string;
    name: string;
    studyType?: StudyInfoDto.studyType;
    externalIds?: Array<StudyExternalIdDto>;
    protocols?: Array<ProtocolInfoDto>;
    mainIndication: IndicationDto;
}

export namespace StudyInfoDto {

    export enum studyType {
        SUBSCRIPTION = 'SUBSCRIPTION',
        SUBSCRIPTION_VIRTUAL = 'SUBSCRIPTION_VIRTUAL',
        ACTIVE_RECRUITMENT = 'ACTIVE_RECRUITMENT',
        SURVEY = 'SURVEY',
    }


}
