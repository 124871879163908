import React from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { PageLoader } from 'components/loaders';
import { Services } from 'services';

export const AuthGuard: React.FC = ({ children }) => {
  // TODO: Bring back refresh token
  // const { isLoading } = Services.Auth.useRefreshAccessToken();

  const { isAuthenticated, isLoading } = Services.Auth.useAuthingGuard();

  return (
    <React.Fragment>
      {isAuthenticated ? (
        children
      ) : (
        <ShouldRender when={isLoading}>
          <PageLoader />
        </ShouldRender>
      )}
    </React.Fragment>
  );
};
