import React from 'react';
import { Icon, IconProps } from '@clariness/icon';
import { Link } from '@clariness/link';
import { Routing } from 'global/routing';

{ /* eslint-disable max-len */ }
export const Logo: React.FC<IconProps> = ({ size = 140, ...props }) => {
  return (
    <Link
      variant="primary"
      to={Routing.HOME.getPath()}
      data-testid="logo"
    >
      <Icon {...props}>
        <svg
          width={size}
          height="48"
          viewBox="0 0 186 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_534_7632)">
            <path
              d="M29.1234 24.8048C28.3824 12.5632 12.8871 -1.90123 13.1449 0.514875C13.2415 1.48132 13.2737 2.41555 13.2093 3.31756C12.6939 3.28534 12.2106 3.34977 11.6952 3.54306C11.1153 3.76857 5.28446 6.70011 1.93413 15.5592C0.484465 19.3927 0.0656738 22.7753 0.0656738 27.0276C0.0978886 35.79 5.99319 39.237 9.76231 39.0759C11.1475 39.0115 12.1462 38.9793 12.8871 38.8827C12.0818 40.6867 10.761 43.4249 9.34352 45.7766C8.53815 47.0974 11.4053 48.5793 12.0818 47.484C20.9086 33.9538 15.1422 12.1122 15.1422 12.1122C15.1422 12.1122 23.1314 22.0021 18.7502 37.1431C21.1663 36.1444 29.4777 31.6988 29.1234 24.8048Z"
              fill="#76BC58"
            />
            <path
              d="M61.0143 8.89225C59.3391 8.89225 57.7606 9.14997 56.2787 9.69762C54.8291 10.2453 53.5405 11.0184 52.4774 12.0815C51.4143 13.1446 50.5445 14.4654 49.9324 16.0117C49.3203 17.5902 48.9982 19.3943 48.9982 21.4882C48.9982 23.55 49.2881 25.354 49.868 26.8681C50.4478 28.3822 51.2532 29.6386 52.3163 30.6372C53.3794 31.6359 54.668 32.3768 56.1499 32.86C57.6317 33.3433 59.3069 33.5688 61.1431 33.5688C61.6586 33.5688 62.174 33.5365 62.7217 33.5043C63.2693 33.4399 63.8492 33.3755 64.4291 33.2788C65.0089 33.1822 65.5566 33.0533 66.1042 32.9567C66.6519 32.8278 67.1351 32.699 67.5539 32.5379V35.7594C66.6841 36.1137 65.6532 36.3714 64.4613 36.5647C63.3015 36.758 62.0774 36.8547 60.8532 36.8547C58.3405 36.8547 56.1176 36.5003 54.1848 35.8238C52.2519 35.1151 50.6411 34.1164 49.3203 32.8278C47.9995 31.5392 47.0009 29.9285 46.3566 28.06C45.6801 26.1916 45.3579 24.0332 45.3579 21.6493C45.3579 19.2976 45.7123 17.1714 46.421 15.2063C47.1297 13.2412 48.1606 11.5661 49.5136 10.1486C50.8666 8.73118 52.4774 7.63588 54.378 6.86272C56.2787 6.08957 58.4371 5.67078 60.821 5.67078C61.4653 5.67078 62.0774 5.70299 62.6895 5.73521C63.3015 5.79964 63.8814 5.86406 64.4613 5.96071C65.0089 6.05735 65.5566 6.18621 66.0398 6.31507C66.523 6.44393 66.9418 6.605 67.264 6.73386V9.98755C66.3619 9.69762 65.3633 9.4399 64.268 9.24661C63.1727 8.9889 62.1096 8.89225 61.0143 8.89225Z"
              fill="#76BC58"
            />
            <path
              d="M73.3838 36.2743V3.7052H76.863V36.2743H73.3838Z"
              fill="#76BC58"
            />
            <path
              d="M83.984 9.95475V6.05676H87.4632V9.95475H83.984ZM84.0484 36.2742V14.9158H87.5276V36.2742H84.0484Z"
              fill="#76BC58"
            />
            <path
              d="M97.901 17.8791H97.9654C98.352 17.3959 98.7708 16.9126 99.254 16.4939C99.7372 16.0751 100.253 15.6885 100.833 15.3663C101.412 15.0442 102.057 14.7865 102.765 14.6254C103.474 14.4321 104.215 14.3677 105.053 14.3677C106.019 14.3677 106.953 14.4965 107.791 14.7865C108.628 15.0764 109.402 15.4952 110.014 16.0751C110.658 16.6871 111.141 17.4603 111.496 18.4267C111.85 19.3932 112.043 20.5851 112.043 22.0026V36.3059H108.564V22.5502C108.564 20.714 108.21 19.3932 107.501 18.5556C106.792 17.7502 105.761 17.3314 104.376 17.3314C103.184 17.3314 102.024 17.6536 100.897 18.3301C99.7694 19.0066 98.803 19.9086 97.9976 21.1006V36.2737H94.5184V14.9153H97.2245L97.901 17.8791Z"
              fill="#76BC58"
            />
            <path
              d="M122.93 6.18616V32.9566H137.619V36.2747H119.257V6.18616H122.93Z"
              fill="#76BC58"
            />
            <path
              d="M142.904 9.95475V6.05676H146.383V9.95475H142.904ZM143.001 36.2742V14.9158H146.48V36.2742H143.001Z"
              fill="#76BC58"
            />
            <path
              d="M165.841 6.47559C165.39 6.37895 164.907 6.31452 164.359 6.2823C163.812 6.25009 163.361 6.21787 162.974 6.21787C162.201 6.21787 161.492 6.31452 160.88 6.50781C160.268 6.70109 159.753 7.02324 159.302 7.44203C158.883 7.86083 158.561 8.44069 158.335 9.1172C158.11 9.82593 158.013 10.6635 158.013 11.6622V14.9481H164.391V17.944H158.013V36.3065H154.534V17.944H150.765V14.9481H154.534V11.6622C154.534 10.1159 154.727 8.82727 155.146 7.76418C155.565 6.70109 156.112 5.8313 156.853 5.15479C157.562 4.47828 158.432 3.96284 159.398 3.64069C160.365 3.31854 161.428 3.15747 162.555 3.15747C163.264 3.15747 163.94 3.18969 164.553 3.25412C165.165 3.31854 165.616 3.41519 165.906 3.51183V6.47559H165.841Z"
              fill="#76BC58"
            />
            <path
              d="M178.244 33.7932C179.404 33.7932 180.467 33.6965 181.401 33.5032C182.367 33.31 183.302 33.0522 184.236 32.6979V35.5972C183.463 35.9838 182.528 36.2737 181.401 36.4992C180.273 36.7247 179.081 36.8214 177.793 36.8214C176.279 36.8214 174.829 36.6281 173.476 36.2415C172.123 35.8549 170.963 35.2106 169.997 34.3408C169.03 33.471 168.257 32.3435 167.677 30.9261C167.13 29.5086 166.84 27.8334 166.84 25.8683C166.84 23.9355 167.098 22.2281 167.645 20.8106C168.193 19.3932 168.902 18.169 169.804 17.2348C170.706 16.2683 171.736 15.5596 172.896 15.0764C174.056 14.5932 175.28 14.3677 176.569 14.3677C177.761 14.3677 178.888 14.561 179.919 14.9798C180.95 15.3663 181.852 16.0106 182.625 16.8482C183.398 17.718 183.978 18.8133 184.429 20.1663C184.88 21.5194 185.106 23.1301 185.106 25.0308C185.106 25.2241 185.106 25.3529 185.106 25.4818C185.106 25.6106 185.106 25.9006 185.073 26.3516H170.319C170.319 27.7046 170.512 28.8643 170.931 29.8308C171.35 30.7972 171.898 31.5704 172.606 32.1502C173.315 32.7301 174.153 33.1811 175.119 33.4388C176.085 33.6643 177.116 33.7932 178.244 33.7932ZM176.408 17.3314C175.602 17.3314 174.861 17.4925 174.153 17.8147C173.476 18.1368 172.864 18.5556 172.349 19.1032C171.833 19.6509 171.414 20.263 171.092 21.0039C170.77 21.7449 170.577 22.518 170.512 23.3234H181.433C181.433 22.4858 181.336 21.7126 181.111 20.9717C180.885 20.2308 180.563 19.6187 180.145 19.071C179.726 18.5234 179.21 18.1046 178.566 17.8147C177.954 17.4925 177.213 17.3314 176.408 17.3314Z"
              fill="#76BC58"
            />
          </g>
          <defs>
            <clipPath id="clip0_534_7632">
              <rect
                width="185.235"
                height="48"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </Icon>
    </Link>
  );
};
