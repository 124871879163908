import React from 'react';
import { Icon, IconProps } from '@clariness/icon';

export const SignOut: React.FC<IconProps> = ({ size = 23, ...props }) => {
  return (
    <Icon {...props}>
      <svg
        width={size}
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.625 20.125H4.79167C4.28334 20.125 3.79582 19.9231 3.43638 19.5636C3.07693 19.2042 2.875 18.7167 2.875 18.2083V4.79167C2.875 4.28334 3.07693 3.79582 3.43638 3.43638C3.79582 3.07693 4.28334 2.875 4.79167 2.875H8.625"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.333 16.2913L20.1247 11.4997L15.333 6.70801"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.125 11.5H8.625"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
