import React from 'react';
import { createStoreProvider } from 'utils/context';
import { SiteEmployeeDto } from 'common/api';

export type User = SiteEmployeeDto;

function useUserState() {
  const [user, setUser] = React.useState<User|null>(null);

  return {
    user,
    isAuthenticated: Boolean(user),
    setUser,
  };
}

export const [useUser, UserProvider] = createStoreProvider(useUserState);
