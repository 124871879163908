import React from 'react';
import { TabMenu } from '@clariness/tab-menu';
import { useMultiStep } from 'components/MultiStep';
import { FormattedMessage } from 'react-intl';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { Store } from 'store';

export const PatientDetailsTabMenu: React.FC = () => {
  const { activeStep, gotoStep } = useMultiStep();

  const { user } = Store.useUser();
  return (
    <TabMenu>
      <TabMenu.Tab
        isActive={activeStep === 1}
        onClick={() => {
          captureEventV2(Events.InvestigatorService.InvestigatorViewedPatientProfile({ InvestigatorID: user?.id ?? '' }));
          gotoStep(1); 
        }}
      >
        <FormattedMessage
          id="is.patient_details_modal.profile_tab"
          defaultMessage="Profile"
        />
      </TabMenu.Tab>

      <TabMenu.Tab
        isActive={activeStep === 2}
        onClick={() => {
          captureEventV2(Events.InvestigatorService.InvestigatorViewedPatientActivity({ InvestigatorID: user?.id ?? '' }));
          gotoStep(2); 
        }}
      >
        <FormattedMessage
          id="is.patient_details_modal.activity_tab"
          defaultMessage="Activity"
        />
      </TabMenu.Tab>

      <TabMenu.Tab
        isActive={activeStep === 3}
        onClick={() => {
          captureEventV2(Events.InvestigatorService.InvestigatorViewedPatientNotes({ InvestigatorID: user?.id ?? '' }));
          gotoStep(3); 
        }}
      >
        <FormattedMessage
          id="is.patient_details_modal.notes_tab"
          defaultMessage="Notes"
        />
      </TabMenu.Tab>
    </TabMenu>
  );
};
