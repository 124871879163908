import React from 'react';
import { Icon, IconProps } from '@clariness/icon';

export const User: React.FC<IconProps> = ({ size = 22, ...props }) => {
  return (
    <Icon {...props}>
      <svg
        width={size}
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8134 17.5V15.8333C17.8134 14.9493 17.4579 14.1014 16.8252 13.4763C16.1925 12.8512 15.3344 12.5 14.4396 12.5H7.69212C6.79734 12.5 5.93921 12.8512 5.30651 13.4763C4.67381 14.1014 4.31836 14.9493 4.31836 15.8333V17.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0652 9.16667C12.9284 9.16667 14.4389 7.67428 14.4389 5.83333C14.4389 3.99238 12.9284 2.5 11.0652 2.5C9.20189 2.5 7.69141 3.99238 7.69141 5.83333C7.69141 7.67428 9.20189 9.16667 11.0652 9.16667Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
