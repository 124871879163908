import { ReferralStatusChangeDto } from 'common/api';

export const ReferralStatus = {
  NEW_REFERRAL: ReferralStatusChangeDto.referralStatus.NEW_REFERRAL,
  ATTEMPTING_TO_CONTACT: ReferralStatusChangeDto.referralStatus.ATTEMPTING_TO_CONTACT,
  CONTACTED: ReferralStatusChangeDto.referralStatus.CONTACTED,
  CONTACTED__CALLBACK: ReferralStatusChangeDto.referralStatus.CONTACTED__CALLBACK,
  APPOINTMENT_SCHEDULED: ReferralStatusChangeDto.referralStatus.APPOINTMENT_SCHEDULED,
  CONSENTED: ReferralStatusChangeDto.referralStatus.CONSENTED,
  RANDOMIZED: ReferralStatusChangeDto.referralStatus.RANDOMIZED,
  SCREENING_FAILURE: ReferralStatusChangeDto.referralStatus.SCREENING_FAILURE,
  CONTACTED__MEDICAL_RECORDS: ReferralStatusChangeDto.referralStatus.CONTACTED__MEDICAL_RECORDS,
  CONTACTED__WASHOUT_PERIOD: ReferralStatusChangeDto.referralStatus.CONTACTED__WASHOUT_PERIOD,
  CONTACTED__PATIENT_AVAILABILITY: ReferralStatusChangeDto.referralStatus.CONTACTED__PATIENT_AVAILABILITY,

  WAITING_FOR_INFORMATION: ReferralStatusChangeDto.referralStatus.WAITING_FOR_INFORMATION,
  WAITING_FOR_INFORMATION__MEDICAL_RECORD: ReferralStatusChangeDto.referralStatus.WAITING_FOR_INFORMATION__MEDICAL_RECORD,
  WAITING_FOR_INFORMATION__WASHOUT_PERIOD: ReferralStatusChangeDto.referralStatus.WAITING_FOR_INFORMATION__WASHOUT_PERIOD,
  WAITING_FOR_INFORMATION__WAITING_FOR_ICF: ReferralStatusChangeDto.referralStatus.WAITING_FOR_INFORMATION__WAITING_FOR_ICF,

  DROPPED__NO_SHOW: ReferralStatusChangeDto.referralStatus.DROPPED__NO_SHOW,

  DROPPED: ReferralStatusChangeDto.referralStatus.DROPPED,
  DROPPED__UNABLE_TO_REACH: ReferralStatusChangeDto.referralStatus.DROPPED__UNABLE_TO_REACH,
  DROPPED__FAILED_IN_EXCLUSION_CRITERIA: ReferralStatusChangeDto.referralStatus.DROPPED__FAILED_IN_EXCLUSION_CRITERIA,
  DROPPED__NOT_INTERESTED: ReferralStatusChangeDto.referralStatus.DROPPED__NOT_INTERESTED,
  DROPPED__BAD_OR_DUPLICATE_PROFILE: ReferralStatusChangeDto.referralStatus.DROPPED__BAD_OR_DUPLICATE_PROFILE,
  DROPPED__TOO_FAR_AWAY: ReferralStatusChangeDto.referralStatus.DROPPED__TOO_FAR_AWAY,
  DROPPED__OTHER: ReferralStatusChangeDto.referralStatus.DROPPED__OTHER,
  DROPPED_BY_CC: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC,
  DROPPED_BY_CC__UNABLE_TO_REACH: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC__UNABLE_TO_REACH,
  DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC__FAILED_IN_EXCLUSION_CRITERIA,
  DROPPED_BY_CC__NOT_INTERESTED: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC__NOT_INTERESTED,
  DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC__BAD_OR_DUPLICATE_PROFILE,
  DROPPED_BY_CC__TOO_FAR_AWAY: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC__TOO_FAR_AWAY,
  DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT: ReferralStatusChangeDto.referralStatus
    .DROPPED_BY_CC__DUE_TO_THE_END_OF_THE_ENROLLMENT,
  DROPPED_BY_CC__OTHER: ReferralStatusChangeDto.referralStatus.DROPPED_BY_CC__OTHER,
  IN_CALL_CENTER: ReferralStatusChangeDto.referralStatus.IN_CALL_CENTER,
  ON_HOLD_IN_CALL_CENTER: ReferralStatusChangeDto.referralStatus.ON_HOLD_IN_CALL_CENTER,
  RELOCATION: ReferralStatusChangeDto.referralStatus.RELOCATION,
};

export type ReferralStatusType = keyof typeof ReferralStatus;
