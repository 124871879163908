import React, { useState } from 'react';
import { Flex } from '@clariness/flex';
import { Tag } from '@clariness/tag';
import { referralStatusMapper } from 'utils/format';
import { useHomePageContext } from 'pages';
import { FormattedMessage } from 'react-intl';
import { useReferralTableFilterContext } from 'pages/Home/components';
import { Button } from '@clariness/button';

type Tag = {
  id: string,
  name: string,
  type: 'status' | 'study',
}

export const ReferralTableFilterTags = () => {

  const { filterTags, setFilterTags } = useHomePageContext();
  const { localFilterOptions, setLocalFilterOptions } = useReferralTableFilterContext();

  const [showMore, setShowMore] = useState<boolean>(false);

  const allTags: Array<Tag> = [];
  localFilterOptions?.statuses?.map(status => allTags.push({
    type: 'status',
    name: status,
    id: status,
  }));
  filterTags?.selectedStudies?.map(study => allTags.push({ ...study, type: 'study' }));

  const tagList = !showMore ? allTags?.slice(0, 3) : allTags;

  return (
    <Flex
      sx={{
        flexWrap: 'wrap',
        columnGap: '18px',
        rowGap: '10px',
        fontFamily: 'Noto Sans',
        
      }}
      data-test-id="referral-table-filter-tags"
    >
      {tagList.map(tag => (
        <Tag
          key={tag.id}
          onClose={tag.type === 'study' ? () => {
            const studyIds = localFilterOptions?.selectedStudyIds?.filter(id => id !== tag.id);
            setLocalFilterOptions({ ...localFilterOptions, selectedStudyIds: studyIds });
            const selectedStudies = filterTags?.selectedStudies?.filter(study => study.id !== tag.id);
            setFilterTags({ ...filterTags, selectedStudies });
          } :
            () => {
              const statuses = localFilterOptions?.statuses?.filter(status => status !== tag.id);
              setLocalFilterOptions({ ...localFilterOptions, statuses });
            }}
        >
          {
            tag.type === 'study' ?
              tag.name : (
                <>
                  <FormattedMessage
                    id="is.referrals_table.status_column"
                    defaultMessage="Status"
                  /> -
                  {referralStatusMapper[tag.name].description}
                </>
              )
          }
        </Tag>
      ))}
      {
        allTags?.length > 3 && (
        <Button
          variant="base"
          onClick={() => setShowMore(!showMore)}
          sx={{
            color: '#398717',
            fontWeight: 'bold',
          }}
        >
          {!showMore ? `+${allTags?.length - 3} More` : 'Hide'}
        </Button>
        )
      }
    </Flex>
  );
};
