import React from 'react';
import * as _ from 'lodash-es';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { useMultiStep } from 'components/MultiStep';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { UpdateStatusLayout } from '../UpdateStatusLayout/UpdateStatusLayout';
import { UpdateStatusTile } from '../UpdateStatusTile/UpdateStatusTile';
import { StatusType } from '..';
import { subReferralStatuses } from './subReferralStatuses';

export const UpdatePatientSubStatus: React.FC = () => {
  const { parentStatus,
    selectedStatus,
    setSelectedStatus,
    protocolsData,
  } = useUpdatePatientStatusContext();

  const { nextStep } = useMultiStep();
 
  const isRadioButtonDisabled = (key: string): boolean => {
    return (key === 'APPOINTMENT_SCHEDULED') || false;
  };

  const getOnClickHandler = (status: ReferralStatusType) => {
    if (
      status === ReferralStatus.APPOINTMENT_SCHEDULED ||
      (protocolsData?.protocolList.length &&
        protocolsData?.protocolsCount > 0)
    ) {
      return () => {
        setSelectedStatus(status);
        nextStep();
      };
    }

    return () => setSelectedStatus(status);
  };

  return (
    <UpdateStatusLayout
      heading=""
    >
      {_.map(subReferralStatuses[parentStatus], (status, key: ReferralStatusType) => {
        return status && (
        <UpdateStatusTile
          key={key}
          value={key}
          checked={selectedStatus === key}
          onClick={getOnClickHandler(key)}
          onChange={() => { }}
          statusTitle={status.title}
          statusDescription={status.description}
          disabled={isRadioButtonDisabled(key)}
          statuesType={selectedStatus === key ? StatusType.SELECTED : StatusType.UPCOMING}
          referralStatus={key}
        />
        );
      })}
    </UpdateStatusLayout>
  );
};
