import { useEffect, useMemo, useState } from 'react';
import { Modal } from '@clariness/modal';
import { Store } from 'store';
import * as ReactIntl from 'react-intl';
import { MultiStep } from 'components/MultiStep';
import { generateContext } from 'utils/context';
import { ReferralStatus, ReferralStatusType } from 'global/referralStatus';
import { getHours, getMinutes } from 'date-fns';
import { breakpoints } from 'utils/breakpoints';
import { Services } from 'services';
import { ProtocolInfoDto } from 'common/api';
import {
  UpdatePatientMainStatus,
  UpdatePatientStatusFooter,
  UpdatePatientSubStatus,
  subReferralStatuses,
  UpdatePatientAppointmentSchedule,
  UpdateProtocol,
  AppointmentOutcome,
  NewAppointmentNeeded,
} from './components';
import { UpdatePatientStatusModalContext } from './types';

type UpdatePatientStatusModalProps = {
  id?: string;
  status?: ReferralStatusType;
  appointmentDateTime?: string;
  currentConsentedDate?: string
  isVisited?: boolean;
  referralDate?: string;
};

export const [useUpdatePatientStatusContext, UpdatePatientStatusProvider] =
  generateContext<UpdatePatientStatusModalContext>();

export const UpdatePatientStatusModal = ({
  id,
  status,
  appointmentDateTime,
  currentConsentedDate,
  isVisited,
  referralDate,
}
  : UpdatePatientStatusModalProps) => {
  const [selectedStatus, setSelectedStatus] = useState<ReferralStatusType>(status as ReferralStatusType);
  const [parentStatus, setParentStatus] = useState<keyof typeof subReferralStatuses>('');

  const initialAppointmentDate = useMemo(() => (
    appointmentDateTime ? new Date(appointmentDateTime) : undefined
  ), [appointmentDateTime]);

  const initialAppointmentTime = useMemo(() => (
    appointmentDateTime ? ({
      hours: getHours(new Date(appointmentDateTime)),
      minutes: getMinutes(new Date(appointmentDateTime)),
    }) : undefined
  ), [appointmentDateTime]);

  const [appointmentScheduleDate, setAppointmentScheduleDate] = useState<Date | undefined>(initialAppointmentDate);
  const [appointmentScheduleTime, setAppointmentScheduleTime] = useState<any | undefined>(initialAppointmentTime);
  const [consentedDate, setConsentedDate] = useState(currentConsentedDate && new Date(currentConsentedDate));
  const { protocolsData } = Services.Referrals.useGetReferralProtocol(id as string);
  const { timezoneData } = Services.Referrals.useGetReferralSiteTimezone(id);
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolInfoDto>();
  const [currentIsVisited, setCurrentIsVisited] = useState<boolean>(Boolean(isVisited));
  const [canNextStep, setCanNextStep] = useState<boolean>(false);
  const [possibleNextStep, setPossibleNextStep] = useState<string>();
  const [isNewAppointmentNeeded, setIsNewAppointmentNeeded] = useState<boolean>(false);

  const { history, isFetching, refetch: refetchHistory } = Services.Referrals.useGetReferralHistory(id);
  const { activity: referralActivities } = Services.Referrals.useGetReferralActivity(id);
  useEffect(() => {
    if (selectedStatus !== ReferralStatus.APPOINTMENT_SCHEDULED) {
      setAppointmentScheduleDate(initialAppointmentDate);
      setAppointmentScheduleTime(initialAppointmentTime);
    }
  }, [selectedStatus, initialAppointmentDate, initialAppointmentTime]);

  useEffect(() => {
    if (protocolsData) {
      setSelectedProtocol(protocolsData?.selectedProtocol);
    }
  }, [protocolsData]);

  const { closeModal } = Store.useModal();

  const intl = ReactIntl.useIntl();

  return (
    <UpdatePatientStatusProvider
      value={{
        id,
        status,
        selectedStatus,
        parentStatus,
        appointmentScheduleDate,
        appointmentScheduleTime,
        initialAppointmentDate,
        initialAppointmentTime,
        consentedDate,
        currentConsentedDate,
        setSelectedStatus,
        setParentStatus,
        setAppointmentScheduleDate,
        setAppointmentScheduleTime,
        setConsentedDate,
        setCurrentIsVisited,
        closeModal,
        setCanNextStep,
        protocolsData,
        history,
        isFetching,
        selectedProtocol,
        currentIsVisited,
        setSelectedProtocol,
        timezoneData,
        appointmentDateTime,
        canNextStep,
        setIsNewAppointmentNeeded,
        isNewAppointmentNeeded,
        possibleNextStep,
        setPossibleNextStep,
        refetchHistory,
        referralActivities,
        referralDate,
      }}
    >
      <Modal
        nested
        sx={{ width: '620px' }}
        onClose={closeModal}
      >
        <MultiStep>
          <Modal.Header
            title={intl.formatMessage({
              id: 'is.update_patient_status_modal.title',
              defaultMessage: 'Update Status',
            })}
            sx={{
              height: '74px',
            }}
          />

          <Modal.Body
            sx={{
              height: breakpoints({ _: '450px', sm: '480px' }),
              overflow: 'scroll',
              padding: '4px 20px',
            }}
          >
            <MultiStep.Step>
              <UpdatePatientMainStatus />
            </MultiStep.Step>

            {parentStatus === ReferralStatus.APPOINTMENT_SCHEDULED && (
              <MultiStep.Step>
                <UpdatePatientAppointmentSchedule />
              </MultiStep.Step>
            )}

            {parentStatus === 'IS_APPOINTMENT_HAPPEND' &&
              (
                <MultiStep.Step>
                  <AppointmentOutcome />
                </MultiStep.Step>
              )}

            {parentStatus === 'IS_NEW_APPOINTMENT_NEEDED' &&
              (
                <MultiStep.Step>
                  <NewAppointmentNeeded />
                </MultiStep.Step>
              )}

            {parentStatus === ReferralStatus.CONSENTED && (
              <MultiStep.Step>
                <UpdateProtocol />
              </MultiStep.Step>
            )}

            <MultiStep.Step>
              <UpdatePatientSubStatus />
            </MultiStep.Step>
          </Modal.Body>

          <Modal.Footer>
            <UpdatePatientStatusFooter />
          </Modal.Footer>
        </MultiStep>
      </Modal>
    </UpdatePatientStatusProvider>
  );
};
