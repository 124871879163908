import { useEffect, useState } from 'react';
import { authing } from 'global/authing';
import { Routing } from 'global/routing';
import { setAccessToken } from 'utils/auth';
import { Services } from 'services';
import { Store } from 'store';
import { jwtDecode } from 'jwt-decode';
import { Environment } from 'global/environment';

const redirectToLogin = () => {
  if (navigator.onLine) {
    window.location.href = Routing.LOGIN.getPath();
  }
};

export function useAuthingGuard() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const { isAuthenticated } = Store.useUser();

  useEffect(() => {
    authing.getCurrentUser().then(user => {
      const token = user?.token ?? '';
      if (token && jwtDecode(token).iss?.includes(Environment.AUTHING_DOMAIN)) {
        setAccessToken(token);
        setIsSuccess(true);
      } else {
        redirectToLogin();
      }
    });
  }, []);

  Services.SiteEmployees.useGetCurrentSiteEmployee({
    enabled: isSuccess,
    onError: () => {
      redirectToLogin();
      setIsLoading(false);
    },
    onSuccess: () => setIsLoading(false),
  });

  return { isAuthenticated, isLoading };
}
