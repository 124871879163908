import React, { useState } from 'react';
import { Box } from '@clariness/box';
import { SearchField } from '@clariness/search-field';
import { Services } from 'services';
import { useHomePageContext } from 'pages';
import { FormattedMessage, useIntl } from 'react-intl';

export const ReferralTableSearchPatient = () => {
  const { filterOptions, defaultPaginationOptions, setFilterOptions, setPaginationOptions } = useHomePageContext();

  const [searchText, setSearchText] = useState('');

  const { referrals, isFetching } = Services.Referrals.useSearchReferrals({
    filterOptions: {
      studyIds: filterOptions?.studyIds,
      patientName: searchText,
    },
    enabled: Boolean(searchText.length > 2 && filterOptions?.studyIds),
  });

  const intl = useIntl();

  return (
    <Box
      sx={{
        flexShrink: 0,
        width: '300px',
        ml: 'auto!important', // important needed for safari to override flex gap margin
      }}
    >
      <SearchField
        placeholder={intl.formatMessage({
          id: 'is.main_page.search_patient.placeholder',
          defaultMessage: 'Find patients',
        })}
        isLoading={isFetching}
        showSuggestions={searchText.length > 2}
        onSearch={searchText => {
          setPaginationOptions(defaultPaginationOptions);
          setFilterOptions({
            studyIds: filterOptions?.studyIds,
            patientName: searchText.replace(/\./g, ''),
          });
        }}
        onChange={e => {
          setSearchText(e.target.value);

          if (e.target.value === '') {
            setFilterOptions(s => ({ ...s, patientName: undefined }));
          }
        }}
        data-test-id="referral-table-search-input"
      >
        {referrals?.total ? referrals.results.map(referral => (
          <SearchField.Suggestion
            key={referral.id}
            value={String(referral.patient.internalId)}
          >
            {`${referral.patient.firstName} ${referral.patient.lastName}`}
          </SearchField.Suggestion>
        )) : (
          <SearchField.NoResults>
            <FormattedMessage
              id="is.main_page.search_patient_suggestions.no_results"
              defaultMessage="No results"
            />
          </SearchField.NoResults>
        )}
      </SearchField>
    </Box>
  );
};
