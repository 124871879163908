import React from 'react';
import { LabeledContent } from 'components/content';
import { Services } from 'services';
import { usePatientDetailsContext } from 'components/modals/PatientDetailsModal';
import { Box } from '@clariness/box';
import { Text } from '@clariness/text';
import { ShouldRender } from 'components/ShouldRender';
import { BoxLoader } from 'components/loaders';
import { formatDate, displayContentValue } from 'utils/format';
import { FormattedMessage, useIntl } from 'react-intl';
import { ServiceError } from 'components/ServiceError';
import { displayActivityEvent } from './displayActivityEvent';

export const PatientDetailsActivity = () => {
  const { currentReferral } = usePatientDetailsContext();

  const {
    activity,
    isLoading,
    isError,
    refetch,
  } = Services.Referrals.useGetReferralActivity(currentReferral?.id);

  const intl = useIntl();

  if (isError) {
    return (
      <ServiceError onRetry={refetch}>
        <FormattedMessage
          id="is.patient_details_modal.activity.generic_error_message"
          defaultMessage="Something went wrong while we tried to display the patient's activity."
        />
      </ServiceError>
    );
  }

  return (
    <React.Fragment>
      <Box
        as="table"
        sx={{
          width: '100%',
          height: 'fit-content',

          '& th': {
            textAlign: 'left',
          },

          '& th:not(:last-child), td:not(:last-child)': {
            pr: '70px',
          },

          '& td': {
            pt: '10px',
            pb: '16px',
            color: '#535A5F',
          },
        }}
      >
        <Box as="thead">
          <tr>
            <Box
              as="th"
              sx={{ width: '20%' }}
            >
              <LabeledContent
                label={intl.formatMessage({
                  id: 'is.patient_details_modal.activity.date_heading',
                  defaultMessage: 'Date',
                })}
              />
            </Box>

            <Box as="th">
              <LabeledContent
                label={intl.formatMessage({
                  id: 'is.patient_details_modal.activity.activity_heading',
                  defaultMessage: 'Activity',
                })}
              />
            </Box>

            <Box
              as="th"
              sx={{ width: '20%' }}
            >
              <LabeledContent
                label={intl.formatMessage({
                  id: 'is.patient_details_modal.activity.author_heading',
                  defaultMessage: 'Author',
                })}
              />
            </Box>
          </tr>
        </Box>

        <Box as="tbody">
          {activity?.referralActivities?.map(activityEvent => (
            <tr key={activityEvent.id}>
              <td>
                <Text
                  variant="small"
                >
                  {formatDate(intl.formatDate(activityEvent.createdAt && new Date(activityEvent.createdAt)))}
                </Text>
              </td>

              <td>
                <Text
                  variant="small"
                >
                  {displayActivityEvent(activityEvent.event, {
                    note: activityEvent.note,
                    referralStatus: activityEvent.referralStatus,
                    scheduledDate: activityEvent.appointmentDateTime,
                    timeZone: activity.timeZone,
                  })}
                </Text>
              </td>

              <td>
                <Text
                  variant="small"
                >
                  {displayContentValue(activityEvent.createdByName)}
                </Text>
              </td>
            </tr>
          ))}
        </Box>
      </Box>

      <ShouldRender when={isLoading}>
        <BoxLoader />
      </ShouldRender>
    </React.Fragment>
  );
};

