import posthog from 'posthog-js';
import { addToEventQueue } from './eventQueue';

export function captureEventV2<T extends { EventName?: string }>({ EventName, ...properties }: T): void {
  // TODO: Enable Posthog & CookieBot https://clariness.atlassian.net/browse/CLN-12
  return;

  if (posthog.has_opted_out_capturing()) {
    addToEventQueue({ eventName: EventName, properties });
  } else {
    posthog.capture(EventName ?? '', properties);
  }

}
