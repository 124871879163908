import { FormattedMessage } from 'react-intl';
import { ReferralStatus } from 'global/referralStatus';

export const subReferralStatuses = {
  [ReferralStatus.CONTACTED]: {
    [ReferralStatus.CONTACTED__CALLBACK]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.contacted_callback.title"
          defaultMessage="Callback"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.contacted_callback.description"
          defaultMessage="This patient has asked me to call them back."
        />
      ),
    },
    [ReferralStatus.CONTACTED__MEDICAL_RECORDS]: {
      title: (
        <FormattedMessage
          id="is.referral_status.contacted_waiting_for_medical_records.title"
          defaultMessage="Waiting for medical records"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.contacted_waiting_for_medical_records.description"
          defaultMessage="Waiting for patient to share medical records"
        />
      ),
    },
    [ReferralStatus.CONTACTED__PATIENT_AVAILABILITY]: {
      title: (
        <FormattedMessage
          id="is.referral_status.contacted_patient_availability.title"
          defaultMessage="Waiting for patient availability"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.contacted_patient_availability.description"
          defaultMessage="Waiting for patient to share their availability to schedule an appointment."
        />
      ),
    },
    [ReferralStatus.CONTACTED__WASHOUT_PERIOD]: {
      title: (
        <FormattedMessage
          id="is.referral_status.contacted_washout_period.title"
          defaultMessage="Washout period"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.contacted_washout_period.description"
          defaultMessage="Waiting for patient to complete washout period."
        />
      ),
    },
  },

  [ReferralStatus.WAITING_FOR_INFORMATION]: {
    [ReferralStatus.WAITING_FOR_INFORMATION__MEDICAL_RECORD]: {
      title: (
        <FormattedMessage
          id="is.referral_status.contacted_waiting_for_medical_records.title"
          defaultMessage="Waiting for medical records"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.contacted_waiting_for_medical_records.description"
          defaultMessage="Waiting for patient to share medical records."
        />
      ),
    },

    [ReferralStatus.WAITING_FOR_INFORMATION__WAITING_FOR_ICF]: {
      title: (
        <FormattedMessage
          id="is.referral_status.waiting_for_icf_signing.title"
          defaultMessage="Waiting for ICF signed"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.waiting_for_icf_signing.description"
          defaultMessage="Waiting for the patient to sign ICF."
        />
      ),
    },

    [ReferralStatus.WAITING_FOR_INFORMATION__WASHOUT_PERIOD]: {
      title: (
        <FormattedMessage
          id="is.referral_status.waiting_washout_period.title"
          defaultMessage="Washout period"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.waiting_washout_period.description"
          defaultMessage="Waiting for patient to complete washout period."
        />
      ),
    },
  },
 
  [ReferralStatus.DROPPED]: {
    [ReferralStatus.DROPPED__UNABLE_TO_REACH]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.dropped_unable_to_reach.title"
          defaultMessage="Unable to reach"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.dropped_unable_to_reach.description"
          defaultMessage="I have been unable to reach the patient."
        />
      ),
    },
    [ReferralStatus.DROPPED__FAILED_IN_EXCLUSION_CRITERIA]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.dropped_failed_in_exclusion_criteria.title"
          defaultMessage="Failed In-/Exclusion Criteria"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.dropped_failed_in_exclusion_criteria.description"
          defaultMessage="The patient has failed the exclusion criteria."
        />
      ),
    },
    [ReferralStatus.DROPPED__NOT_INTERESTED]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.dropped_not_interested.title"
          defaultMessage="Not interested"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.dropped_not_interested.description"
          defaultMessage="The patient is not interested."
        />
      ),
    },
    [ReferralStatus.DROPPED__BAD_OR_DUPLICATE_PROFILE]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.dropped_bad_or_duplicate_profile.title"
          defaultMessage="Bad or duplicate profile"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.dropped_bad_or_duplicate_profile.description"
          defaultMessage="The patient had a bad / duplicate profile."
        />
      ),
    },
    [ReferralStatus.DROPPED__TOO_FAR_AWAY]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.dropped_too_far_away.title"
          defaultMessage="Too far away"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.dropped_too_far_away.description"
          defaultMessage="The patient is too far away from the site."
        />
      ),
    },
    [ReferralStatus.DROPPED__NO_SHOW]: {
      title: (
        <FormattedMessage
          id="is.referral_status.dropped_no_show.title"
          defaultMessage="No show"
        />
      ),
      description: (
        <FormattedMessage
          id="is.referral_status.dropped_no_show.description"
          defaultMessage="Patient missed their scheduled appointment."
        />
      ),
    },
    [ReferralStatus.DROPPED__OTHER]: {
      title: (
        <FormattedMessage
          id="is.update_referral_status.dropped_other.title"
          defaultMessage="Other"
        />
      ),
      description: (
        <FormattedMessage
          id="is.update_referral_status.dropped_otherdescription"
          defaultMessage="Any other reason."
        />
      ),
    },
  },
};
