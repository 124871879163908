import React from 'react';

export const Step: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

Step.defaultProps = {
  __MULTI_STEP_TYPE: 'Step',
};
