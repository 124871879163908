import React from 'react';
import * as ReactIntl from 'react-intl';
import { DatePickerField } from '@clariness/date-picker-field';
import { Flex } from '@clariness/flex';
import { TimePickerField } from '@clariness/time-picker-field';
import { useUpdatePatientStatusContext } from 'components/modals/UpdatePatientStatusModal';
import { formatDate } from 'utils/format';
import { isDate, setHours } from 'date-fns';
import { ThemeUIStyleObject } from 'theme-ui';
import { Box } from '@clariness/box';
import { UpdateStatusLayout } from '../UpdateStatusLayout/UpdateStatusLayout';

const defaultHours = 8;

export const UpdatePatientAppointmentSchedule: React.FC = () => {
  const {
    appointmentScheduleDate,
    appointmentScheduleTime,
    setAppointmentScheduleDate,
    setAppointmentScheduleTime,
  } = useUpdatePatientStatusContext();
  
  const datepickerStyle: ThemeUIStyleObject = {
    '.react-datepicker__input-container > div': { gap: 0, padding: 0 },
    '.react-datepicker-popper': {
      zIndex: 100,
      background: 'var(--theme-ui-colors-hgl7)',
    },
    '.react-datepicker__input-container > div > span:nth-of-type(2)': { margin: '0 !important' },
    '.react-datepicker__input-container > div > span:nth-of-type(2) > input': {
      borderColor: 'var(--theme-ui-colors-text4)',
    },
  };

  const intl = ReactIntl.useIntl();

  return (
    <UpdateStatusLayout
      heading={intl.formatMessage({
        id: 'is.update_patient_status_modal.step_3_title',
        defaultMessage: 'When is your appointment with the patient?',
      })}
    >
      <Flex
        sx={{
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            ...datepickerStyle,
          }}
        >
          <DatePickerField
            position="bottom left"
            label={intl.formatMessage({
              id: 'is.update_patient_status.date_picker_label',
              defaultMessage: 'Date',
            })}
            placeholder={intl.formatMessage({
              id: 'is.update_patient_status.date_picker_placeholder',
              defaultMessage: 'Select date',
            })}
            selected={appointmentScheduleDate}
            dateFormat={appointmentScheduleDate && formatDate(intl.formatDate(appointmentScheduleDate))}
            onChange={(date: Date) => {
              if (!isDate(date)) {
                return;
              } if (appointmentScheduleTime) {
                setAppointmentScheduleDate(date);
              } else {
                setAppointmentScheduleDate(setHours(date, defaultHours));
              }
            }}
          />
        </Box>
        <Box
          sx={{
            ...datepickerStyle,
          }}
        >
          <TimePickerField
            position="bottom left"
            defaultHours={defaultHours}
            formatResolver={intl.formatTime}
            label={intl.formatMessage({
              id: 'is.update_patient_status.time_picker_label',
              defaultMessage: 'Time',
            })}
            placeholder={intl.formatMessage({
              id: 'is.update_patient_status.time_picker_placeholder',
              defaultMessage: 'Select time',
            })}
            selected={appointmentScheduleDate}
            formattedDate={appointmentScheduleTime && intl.formatTime(appointmentScheduleDate)}
            onChange={(newDate, time) => {
              setAppointmentScheduleDate(newDate);
              setAppointmentScheduleTime(time);
            }}
          />
        </Box>
      </Flex>
    </UpdateStatusLayout>
  );
};
