import React from 'react';
import { Icon, IconProps } from '@clariness/icon';

type ArrowProps = IconProps & {
  direction?: 'left' | 'right' | 'top' | 'bottom';
}

const directionRotation = {
  right: '0deg',
  left: '180deg',
  top: '-90deg',
  bottom: '90deg',
};

export const Arrow: React.FC<ArrowProps> = ({
  size = 20,
  direction = 'right',
  sx, 
  ...props 
}) => {
  return (
    <Icon
      sx={{
        transform: `rotate(${directionRotation[direction]})`,
        ...sx,
      }}
      {...props}
    >
      <svg 
        width={size}
        viewBox="0 0 20 20" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M7.5 15L12.5 10L7.5 5" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
