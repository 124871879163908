import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { canRegisterAPI } from './api';

const SITE_EMPLOYEE_CAN_REGISTER_KEY = '@site-employees/can-register';

export function useCanRegister(options?: InferMutationOptions<typeof canRegisterAPI>) {
  const { showNotification } = Store.useNotifications();
  
  const intl = useIntl();

  const { mutate, isLoading } = ReactQuery.useMutation(
    SITE_EMPLOYEE_CAN_REGISTER_KEY,
    canRegisterAPI,
    {
      onError: error => showNotification({
        variant: 'error',
        description: intl.formatMessage({ id: error.translationKey, defaultMessage: error.message }),
      }),
      ...options,
    },
  );

  return {
    checkIfCanRegister: mutate,
    isLoading,
  };
}
