import { OpenAPI } from 'common/api';

const REFRESH_TOKEN_KEY = 'AfEs?e24AfMMAae';

let inActiveUser = false;
let inactiveTime = 0;
let documentHiddenTime: any; // to store the timestamp of when user switches tab
let inActiveTimer: any;
let activeTab = true; 
const allowedTime = 14; // Max time user can stay idle 

function resetInactiveTime() {
  inactiveTime = 0;
}

function checkInactive() {
  if (inactiveTime >= allowedTime) {
    inActiveUser = true;
  }
}

function startInActiveTimer() {
  if (inActiveTimer) {
    clearInterval(inActiveTimer);
  }
  inActiveTimer = setInterval(() => {
    inactiveTime += 1;
    checkInactive();
  }, 60000);
}
function stopActiveInterval() {
  clearInterval(inActiveTimer);
}

startInActiveTimer();

// Most modern browsers suspend timers when tab is inactive
// Handling user inactivity while tab is inactive by time comparison
function handleVisibilityChange() {
  if (document.hidden) {
    stopActiveInterval();
    activeTab = false;
    documentHiddenTime = new Date();
  } else {
    activeTab = true;
    inActiveUser = false;
    // setting to current timestamps at which user switches back to tab, (this information is irrelevant if user is active)
    documentHiddenTime = new Date(); 
    resetInactiveTime();
    startInActiveTimer();
  }
}

//Check if the browser supports visibility change events
if (typeof document.hidden !== 'undefined') {
  document.addEventListener('visibilitychange', handleVisibilityChange);
} else {
  // eslint-disable-next-line no-alert
  alert("Your browser seems to be obselete. Please upgrade your browser to it's latest version or use a different browser");
}

window.addEventListener('mousemove', resetInactiveTime);
window.addEventListener('keydown', resetInactiveTime);

export function setAccessToken(token: string | null) {
  OpenAPI.TOKEN = token || undefined;
}

export function setRefreshToken(token: string) {
  const encryptedToken = window.btoa(token);
  sessionStorage.setItem(REFRESH_TOKEN_KEY, encryptedToken);
}

export function getRefreshToken(): string | null {
  const currentTime: any = new Date();
  const timeDifferenceInMinutes =
    (currentTime - documentHiddenTime) / (1000 * 60);
  if (inActiveUser || (!activeTab && timeDifferenceInMinutes >= allowedTime)) {
    return null;
  }
  const encryptedToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
  return encryptedToken ? window.atob(encryptedToken) : null;
}

export function removeRefreshToken() {
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
}
