import React from 'react';
import { GlobalProvider } from 'app/GlobalProvider';
import { Routes } from 'app/Routes';
import { ModalContainer } from 'components/modals';
import { Notifications } from 'app/Notifications';
// import posthog from 'posthog-js';
// import { Environment } from 'global/environment';
// import { useEventTracking } from 'utils/events';

const App: React.FC = () => {

  // TODO: Enable Posthog in https://clariness.atlassian.net/browse/CLN-14
  // init posthog, don't capture events when developing
  // if (!(window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost'))) {
  //   posthog.init(`${Environment.POSTHOG_TOKEN}`, {
  //     api_host: `${Environment.POSTHOG_HOST}`,
  //     autocapture: false,
  //     opt_in_site_apps: true,
  //   });
  //   posthog.opt_out_capturing(); // opt out of tracking until user gives Cookiebot consent
  // }
  //
  // useEventTracking();

  return (
    <GlobalProvider>
      <Routes />
      <ModalContainer />
      <Notifications />
    </GlobalProvider>
  );
};

export default App;
