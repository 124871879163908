import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { SiteEmployeeDto } from 'common/api';
import { useIntl } from 'react-intl';
import { updateCurrentSiteEmployeeAPI } from './api';

const UPDATE_CURRENT_SITE_EMPLOYEE_KEY = '@site-employees/update-current';

export function useUpdateCurrentSiteEmployee(options?: InferMutationOptions<typeof updateCurrentSiteEmployeeAPI>) {
  const { showNotification } = Store.useNotifications();

  const { user, setUser } = Store.useUser();
  
  const intl = useIntl();
  
  const { mutate, isLoading } = ReactQuery.useMutation(
    UPDATE_CURRENT_SITE_EMPLOYEE_KEY,
    updateCurrentSiteEmployeeAPI,
    {
      ...options,
      onError: (error, data, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.update_account_details',
            defaultMessage: 'Unfortunately, an error occurred while we tried to save your updates. Please try again.',
          }),
        });
        
        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
      onSuccess: (data, variables, context) => {
        setUser(data);
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'is.update_account_details.success_notification_message',
            defaultMessage: 'Your account details have been updated',
          }),
        });
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    updateCurrentSiteEmployee: (siteEmployee: Partial<SiteEmployeeDto>) => (
      mutate({ ...user, ...siteEmployee } as SiteEmployeeDto) 
    ),
    isLoading,
  };
}
