import React, { useState } from 'react';
import { Modal } from '@clariness/modal';
import { MultiStep } from 'components/MultiStep';
import * as ReactIntl from 'react-intl';
import { Store } from 'store';
import { generateContext } from 'utils/context';
import { breakpoints } from 'utils/breakpoints';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { Services } from 'services';
import { BoxLoader } from 'components/loaders';
import {
  PatientDetailsActivity, PatientDetailsFooter,
  PatientDetailsNotes,
  PatientDetailsProfile,
  PatientDetailsTabMenu,
} from './components';
import { PatientDetailsContext, PatientDetailsModalProps } from './types';

export const [usePatientDetailsContext, PatientDetailsProvider] = generateContext<PatientDetailsContext>();

export const PatientDetailsModal: React.FC<PatientDetailsModalProps> = ({ id, referral, referrals }) => {
  const [currentReferral, setCurrentReferral] = useState<any>(referral);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const intl = ReactIntl.useIntl();

  const { redirect } = useRedirect();

  const { closeModal } = Store.useModal();

  const { isLoading } = Services.Referrals.useGetReferral(id, {
    enabled: Boolean(id && !currentReferral),
    onSuccess: data => setCurrentReferral({
      id: data.id,
      studyId: data.study?.id,
      appointmentDateTime: data.appointmentDateTime,
      patientInternalId: data.patient.internalId as number,
      firstName: data.patient.firstName,
      lastName: data.patient.lastName,
      referralDate: data.referralDate,
      status: data.status,
      patientLanguage: data.patient.languageCode,
      consentedDate: data.consentedDate,
      isVisited: data.isVisited,
    }),
  });

  return (
    <PatientDetailsProvider
      value={{
        currentReferral,
        referrals: referrals || [],
        isEditingNotes,
        setCurrentReferral,
        setIsEditingNotes,
      }}
    >
      <Modal
        nested
        sx={{ width: '1270px' }}
        onClose={() => {
          closeModal();
          redirect(Routing.HOME.getPath());
        }}
      >
        <MultiStep>
          <Modal.Header
            showHideIcon={false}
            title={intl.formatMessage({
              id: 'is.patient_details_modal.title',
              defaultMessage: 'Patient Details',
            })}
          >
            <PatientDetailsTabMenu />
          </Modal.Header>

          <Modal.Body
            sx={{
              position: 'relative',
              height: breakpoints({ _: '450px', sm: '539px' }),
              overflow: 'auto',
            }}
          >
            {isLoading ? (
              <BoxLoader />
            ) : (
              <React.Fragment>
                <MultiStep.Step>
                  <PatientDetailsProfile />
                </MultiStep.Step>

                <MultiStep.Step>
                  <PatientDetailsActivity />
                </MultiStep.Step>

                <MultiStep.Step>
                  <PatientDetailsNotes />
                </MultiStep.Step>
              </React.Fragment>
            )}
          </Modal.Body>

          <Modal.Footer borderTop>
            <PatientDetailsFooter />
          </Modal.Footer>
        </MultiStep>
      </Modal>
    </PatientDetailsProvider>
  );
};
