import { getRequest } from 'utils/http';
import { OpenAPI } from 'common/api';
import { Referral } from 'pages/Home/referralsTableData';
import { Environment } from 'global/environment';

export function getPdf(referral?: Referral) {
  const url = `${Environment.BACKEND_API}/referral/currentuser/${referral?.id}/pdf`;
  return getRequest(url, {
    options: {
      headers: {
        Authorization: `Bearer ${OpenAPI.TOKEN}`,
      },
      responseType: 'blob',
    },
  });
}
