/* eslint-disable max-len */
import React from 'react';
import { Icon } from '@clariness/icon';

export const AuthPageLayoutLogo: React.FC = () => {
  return (
    <Icon sx={{ mb: '18px' }}>
      <svg
        width="39"
        viewBox="0 0 39 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.0812 33.4727C37.1101 17.2674 16.803 -1.88034 17.1408 1.31807C17.2674 2.59743 17.3096 3.83414 17.2252 5.02822C16.5497 4.98557 15.9164 5.07086 15.2409 5.32673C14.481 5.62525 6.83941 9.50598 2.44868 21.2335C0.548841 26.3083 0 30.786 0 36.4152C0.0422186 48.0148 7.76822 52.5778 12.7078 52.3646C14.5232 52.2793 15.832 52.2367 16.803 52.1087C15.7475 54.4969 14.0166 58.1217 12.159 61.2348C11.1035 62.9833 14.8609 64.945 15.7475 63.495C27.3154 45.584 19.7583 16.6704 19.7583 16.6704C19.7583 16.6704 30.2285 29.7625 24.4868 49.8059C27.6532 48.4839 38.5456 42.5988 38.0812 33.4727Z"
          fill="#76BC58"
        />
      </svg>
    </Icon>
  );
};
