import React from 'react';
import { Button } from '@clariness/button';
import { Flex } from '@clariness/flex';
import { FormattedMessage } from 'react-intl';
import { usePatientDetailsContext } from 'components/modals/PatientDetailsModal';
import { ReferralDto } from 'common/api';
import { Services } from 'services';

type PatientDetailsUpdateNoteProps = {
  referral?: ReferralDto;
  note?: string;
  setNote: (note?: string) => void;
}

export const PatientDetailsUpdateNote = ({ referral, note, setNote }: PatientDetailsUpdateNoteProps) => {
  const { setIsEditingNotes } = usePatientDetailsContext();

  const { updateReferralNote, isLoading } = Services.Referrals.useUpdateReferralNote({
    onSuccess: () => setIsEditingNotes(false),
  });

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '20px',
        py: '20px',
      }}
    >
      <Button
        variant="secondary"
        size="medium"
        onClick={() => {
          setNote(referral?.note);
          setIsEditingNotes(false);
        }}
      >
        <FormattedMessage
          id="is.patient_details_modal.notes.cancel_button"
          defaultMessage="Cancel"
        />
      </Button>

      <Button
        size="medium"
        disabled={note === referral?.note}
        loading={isLoading}
        onClick={() => updateReferralNote({ id: referral?.id, note })}
      >
        <FormattedMessage
          id="is.patient_details_modal.notes.save_button"
          defaultMessage="Save"
        />
      </Button>
    </Flex>
  );
};
