import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Box } from '@clariness/box';
import { DoubleArrowIcon } from '@clariness/icon';
import { StyleObject } from '@clariness/base';
import { Text } from '@clariness/text';
import { ShouldRender } from 'components/ShouldRender';

type ContentBoxProps = {
  onScrollBottom: () => void;
  sx?: StyleObject;
}

export const ContentBox = ({ sx, children, onScrollBottom }: PropsWithChildren<ContentBoxProps>) => {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  const $contentBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ($contentBox.current) {
      const contentBoxElement = $contentBox.current;
      const onScroll = () => {
        const hasReachedBottom = (
          contentBoxElement.scrollHeight - contentBoxElement.scrollTop - contentBoxElement.clientHeight < 1
        );

        setHasScrolledToBottom(hasReachedBottom);

        if (hasReachedBottom) {
          onScrollBottom();
        }
      };

      contentBoxElement.addEventListener('scroll', onScroll);

      return () => contentBoxElement.removeEventListener('scroll', onScroll);
    }

    return () => {};
  }, [onScrollBottom]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        ref={$contentBox}
        data-testid="content-box"
        sx={{
          p: '16px 22px',
          height: '292px',
          width: '300px',
          overflowY: 'auto',
          borderRadius: '20px',
          backgroundColor: 'bg.0',
          border: '1px solid #535A5F',
          textAlign: 'start',
          ...sx,
        }}
      >
        <Text sx={{ fontSize: '15px' }}>
          {children}
        </Text>
      </Box>

      <ShouldRender when={!hasScrolledToBottom}>
        <Box
          sx={{
            position: 'absolute',
            bottom: '1px',
            left: '0px',
            height: '70px',
            width: '100%',
            background: 'linear-gradient(rgba(255, 255, 255, 0.3), rgb(255, 255, 255) 50%)',
            mx: '1px',
            borderRadius: '20px',
          }}
        />

        <DoubleArrowIcon
          as="button"
          sx={{
            position: 'absolute',
            bottom: '0px',
            left: '50%',
            width: 'auto',
            height: 'auto',
            transform: 'translate(-50%, 50%)',
            backgroundColor: '#EFEFEF',
            p: '8px',
            color: 'text-primary',
          }}
          onClick={() => {
            if ($contentBox.current) {
              $contentBox.current.scrollTop = $contentBox?.current.scrollHeight;
            }
          }}
        />
      </ShouldRender>
    </Box>
  );
};
