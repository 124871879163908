import { serviceRequest } from 'utils/http';
import { ProtocolInfoDto, Service } from 'common/api';

type UpdateReferralProtocol = {
  referralId: string;
  protocol: ProtocolInfoDto;
}

export function updateReferralProtocolAPI({ referralId, protocol }: UpdateReferralProtocol) {
  return serviceRequest(() => Service.referralControllerUpdateCurrentUserProtocol(referralId, protocol));
}
