import React, { ChangeEvent } from 'react';
import { Filter } from '@clariness/filter';
import { LabeledCheckbox } from '@clariness/labeled-checkbox';
import { LabeledParentCheckbox } from '@clariness/labeled-parent-checkbox';
import { useIntl } from 'react-intl';
import * as _ from 'lodash-es';
import { useReferralTableFilterContext } from 'pages/Home/components';
import { parseReferralStatus } from 'utils/format';
import { ReferralStatusType } from 'global/referralStatus';
import { Tooltip } from '@clariness/tooltip';
import { Box } from '@clariness/box';
import { ThemeUIStyleObject } from 'theme-ui';
import { useHomePageContext } from 'pages';
import { referralFilterStatuses } from './referralFilterStatuses';

export const ReferralTableFilterStatus = () => {
  const { localFilterOptions, setLocalFilterOptions } = useReferralTableFilterContext();
  const { filterOptions } = useHomePageContext();

  const intl = useIntl();

  const isChecked = (value: string) => (
    Boolean(localFilterOptions?.statuses?.find(status => _.isEqual(status, value)))
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>, value: any) => {
    if (e.target.checked) {
      const statuses = localFilterOptions?.statuses || [];
      setLocalFilterOptions({ ...localFilterOptions, statuses: [...statuses, value] });
    } else {
      const statuses = localFilterOptions?.statuses
        ?.filter(status => !_.isEqual(status, value));
      setLocalFilterOptions({ ...localFilterOptions, statuses });
    }
  };

  const toolbarBoxStyle: ThemeUIStyleObject = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
  };

  return (
    <Filter
      label={filterOptions?.statuses?.length ? `
      ${`${filterOptions?.statuses?.length} ${intl.formatMessage({
        id: 'is.main_page.referrals_table_label.selected_referrals',
        defaultMessage: 'Selected',
      })}`}` : intl.formatMessage({
        id: 'is.main_page.referrals_table_label.select',
        defaultMessage: 'Select',
      })}
      isSelected={Boolean(localFilterOptions?.statuses?.length)}
      data-test-id="filter-status-select"
    >
      <Filter.List>
        {_.map(referralFilterStatuses, (status, key: ReferralStatusType) => {
          if (status.children) {
            const statuses = localFilterOptions?.statuses || [];
            const selectedChildStatuses = statuses?.filter(status => parseReferralStatus(status) === key) || [];
            const childStatusKeysLength = Object.keys(status.children).length;
            const isParentChecked = isChecked(key) || selectedChildStatuses?.length === childStatusKeysLength;
            const isPartiallySelected = Boolean(selectedChildStatuses?.length && !isParentChecked);
        
            return (
              <Filter.ListItem key={key}>
                <LabeledParentCheckbox
                  label={(
                    <Tooltip trigger={(
                      <Box sx={toolbarBoxStyle}>
                        {status.description}
                      </Box>
                    )}
                    >
                      {status.description}
                    </Tooltip>
                  )}
                  checked={isParentChecked}
                  isPartiallySelected={isPartiallySelected}
                  onChange={e => {
                    if (isPartiallySelected || e.target.checked) {
                      setLocalFilterOptions({
                        ...localFilterOptions,
                        statuses: _.difference([...statuses, key], selectedChildStatuses),
                      });
                    } else {
                      setLocalFilterOptions({
                        ...localFilterOptions,
                        statuses: _.pull(statuses, key, ...selectedChildStatuses),
                      });
                    }
                  }}
                >
                  {_.map(status.children, ((childStatus, childKey) => (
                    <LabeledCheckbox
                      key={childKey}
                      label={(
                        <Tooltip trigger={(
                          <Box sx={toolbarBoxStyle}>
                            {childStatus.description}
                          </Box>
                        )}
                        >
                          {childStatus.description}
                        </Tooltip>
                      )}
                      checked={isChecked(childKey) || isParentChecked}
                      onChange={e => {
                        // behaviour requirement: if all child statuses are selected, show only parent status instead
                        if (isParentChecked) {
                          // if parent status is selected -> remove parent status, add siblings
                          const siblings = _.map(status.children, (childStatus, childStatusKey) => childStatusKey)
                            .filter(statusKey => statusKey !== childKey) as ReferralStatusType[];
                          setLocalFilterOptions({
                            ...localFilterOptions,
                            statuses: _.pull([...statuses, ...siblings], key),
                          });
                        } else if (e.target.checked && selectedChildStatuses.length === childStatusKeysLength - 1) {
                          // if all other siblings are selected -> remove siblings, add parent
                          setLocalFilterOptions({
                            ...localFilterOptions,
                            statuses: _.difference([...statuses, key], selectedChildStatuses),
                          });
                        } else {
                          onChange(e, childKey);
                        }
                      }}
                    />
                  )))}
                </LabeledParentCheckbox>
              </Filter.ListItem>
            );
          }

          return (
            <Filter.ListItem key={key}>
              <LabeledCheckbox
                label={(
                  <Tooltip trigger={(
                    <Box sx={toolbarBoxStyle}>
                      {status.description}
                    </Box>
                  )}
                  >
                    {status.description}
                  </Tooltip>
                )}
                checked={isChecked(key)}
                onChange={e => onChange(e, key)}
              />
            </Filter.ListItem>
          );
        })}
      </Filter.List>
    </Filter>
  );
};
