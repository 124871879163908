import { useState } from 'react';
import { useIntl } from 'react-intl';
import { authing } from 'global/authing';
import { setAccessToken, removeRefreshToken } from 'utils/auth';
import { useRedirect } from 'utils/routing';
import { Routing } from 'global/routing';
import { Services } from 'services';
import { Store } from 'store';
import { ErrorType } from 'utils/http';
// import { Service } from 'common/api';
// import { captureEventV2 } from 'utils/events/captureEvent';
// import { Events } from '@clariness/tracking-event-runtime';
import { ErrorMessage } from './types';
import { handleLoginError } from './handleLoginError';

// const UNAUTHORIZED = 'unauthorized';
// const PLEASE_CHANGE_YOUR_PASSSWORD = 'please change your password';

export function useLogin() {
  const [error, setError] = useState<ErrorMessage | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = Store.useUser();
  const { redirect } = useRedirect();

  const intl = useIntl();

  const { showNotification } = Store.useNotifications();

  const login = (email: string, password: string) => {
    setIsLoading(true);
    setError(null);

    authing
      .loginByEmail(email, password)
      .then(data => {
        if (data.token) {
          setAccessToken(data.token);
          setIsSuccess(true);
        }
      })
      .catch(error => {
        console.log(error);
        const errorMessage = handleLoginError(error, intl);
        setError(errorMessage);
        showNotification({ variant: 'error', description: errorMessage.description });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (user) {
    redirect(Routing.HOME.getPath());
  }

  const siteEmployeeService = Services.SiteEmployees.useGetCurrentSiteEmployee({
    enabled: isSuccess,
    onSuccess: () => redirect(Routing.HOME.getPath()),
    onError: error => {
      if ([403, 404].includes((error as ErrorType).statusCode)) {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'is.login.no_permissions',
            defaultMessage: "You don't have the right permissions to log in",
          }),
        });
      }
      setAccessToken(null);
      removeRefreshToken();
      setIsSuccess(false);
    },
  });

  return {
    login,
    isLoading: isLoading || siteEmployeeService.isLoading,
    error,
  };
}
