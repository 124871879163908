import React from 'react';
import { Box } from '@clariness/box';
import { Tooltip, TooltipProps } from '@clariness/tooltip';

export const TableCellTooltip: React.FC<TooltipProps | any> = ({ trigger, children, triggerStyles, ...props }) => {
  return (
    <Tooltip
      position="top right"
      trigger={(
        <Box
          sx={{
            textOverflow: 'ellipsis',
            maxWidth: '100px',
            overflow: 'hidden',
            ...triggerStyles,
          }}
        >
          {trigger}
        </Box>
      )}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
