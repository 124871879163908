import * as ReactQuery from 'react-query';
import { InferMutationOptions, Services } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { updateReferralProtocolAPI } from './api';

const UPDATE_REFERRAL_PROTOCOL_KEY = '@referrals/update-referral-protocol';

export function useUpdateReferralProtocol(
  options?: InferMutationOptions<typeof updateReferralProtocolAPI>,
) {
  const queryClient = ReactQuery.useQueryClient();

  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading, isError } = ReactQuery.useMutation(
    UPDATE_REFERRAL_PROTOCOL_KEY,
    updateReferralProtocolAPI,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueriesData(
          [Services.Referrals.KEYS.GET_REFERRAL_KEY],
          (currentData: any) => ({
            ...currentData,
            selectedProtocol: variables.protocol,
          }),
        );

        queryClient.setQueriesData(
          [Services.Referrals.KEYS.GET_REFERRAL_PROTOCOL_KEY],
          (currentData: any) => ({
            ...currentData,
            selectedProtocol: variables.protocol,
          }),
        );

        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'server.success.update_referral_protocol',
            defaultMessage: 'Protocol Updated successfully.',
          }),
        });

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
      onError: (error, data, context) => {
        showNotification({
          variant: 'error',
          description: intl.formatMessage({
            id: 'server.errors.update_referral_protocol_error',
            defaultMessage:
              "Unfortunately, something failed when updating the patient's protocol. Please, try again.",
          }),
        });

        if (options?.onError) {
          options.onError(error, data, context);
        }
      },
    },
  );

  return {
    updateReferralProtocolAPI: mutate,
    isLoading,
    isError,
  };
}
