import * as ReactQuery from 'react-query';
import { getSiteEmployeeProductUpdatesAPI } from './api';

const GET_SITE_EMPLOYEE_PRODUCT_UPDATES_KEY = '@product-updates/get-site-employee-product-updates';

export function useGetSiteEmployeeProductUpdates(siteEmployeeId?: string) {
  const { data, isLoading, isError, refetch } = ReactQuery.useQuery(
    GET_SITE_EMPLOYEE_PRODUCT_UPDATES_KEY, () => getSiteEmployeeProductUpdatesAPI(siteEmployeeId ?? ''), {
      enabled: !!siteEmployeeId,
    },
  );

  return {
    productUpdates: data || [],
    isLoading,
    isError,
    refetch,
  };
}
