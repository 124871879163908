import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Flex } from '@clariness/flex';
import { Services } from 'services';
import { Table } from 'components/Table';
import { generateContext } from 'utils/context';
import { ReferralSearchFilterDto, ReferralSearchPaginationDto } from 'common/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import * as ReactTable from 'react-table';
import { ServiceError } from 'components/ServiceError';
import { Store } from 'store';
import { useParams } from 'react-router-dom';
// import { getRefreshToken } from 'utils/auth/tokens';
import { Text } from '@clariness/text';
import { FunnelIcon } from '@clariness/icon';
import { Box } from '@clariness/box';

// import { logout } from 'utils/auth';
import {
  ReferralTableExportXls,
  ReferralTableFilter,
} from './components';
import { mapReferralsToAccessor, referralColumns } from './referralsTableData';

const MAX_REFERRALS_ROWS = (() => {
  if (window.innerHeight < 761) {
    return 6;
  }

  if (window.innerHeight < 900) {
    return 8;
  }

  return 10;
})();

type SelectedStudy = {
  id: string,
  name: string,
}

type FilterTags = {
  selectedStudies: SelectedStudy[] | undefined;
}

type HomePageContext = {
  filterOptions?: ReferralSearchFilterDto,
  filterTags?: FilterTags;
  defaultPaginationOptions: ReferralSearchPaginationDto | undefined;
  selectedRows: ReactTable.Row[];
  setFilterOptions: Dispatch<SetStateAction<ReferralSearchFilterDto | undefined>>
  setFilterTags: Dispatch<SetStateAction<FilterTags | undefined>>
  setPaginationOptions: Dispatch<SetStateAction<ReferralSearchPaginationDto | undefined>>
  unSelectedRowIds: string[];
  selectedRowIds: string[];
}

export const [useHomePageContext, HomePageContextProvider] = generateContext<HomePageContext>();

export const Home = () => {
  const {
    referrals,
    filterOptions,
    setFilterOptions,
    setPaginationOptions,
    defaultPaginationOptions,
    fetchReferrals,
    refetch,
    isFetching,
    isError,
  } = Services.Referrals.useSearchReferrals({ take: MAX_REFERRALS_ROWS });

  const { user } = Store.useUser();

  const referralsData = useMemo(() => (
    referrals ? referrals.results.map(mapReferralsToAccessor) : []
    // make deep comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [JSON.stringify(referrals)]);

  const [selectedRows, setSelectedRows] = useState<ReactTable.Row[]>([]);

  const [unSelectedRowIds, setUnselectedRowIds] = useState<string[]>([]);
  const [selectedRowIds, setselectedRowIds] = useState<string[]>([]);

  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [filterTags, setFilterTags] = useState<FilterTags>();

  const intl = useIntl();

  const { id } = useParams<any>();

  const { openModal } = Store.useModal();

  const desktopWidth = 1366;

  useEffect(() => {
    if (id && !referralsData.length) {
      openModal({
        modalType: 'PatientDetails',
        modalProps: { id, referrals: [] },
      });
    }
  }, [openModal, referralsData, id]);

  //logout if refresh token is empty
  // TODO: Bring back (commented for temporal JWT login)
  // useEffect(() => {
  //   if (!getRefreshToken()) {
  //     logout();
  //   }
  // }, []);

  return (
    <HomePageContextProvider
      value={{
        filterOptions,
        defaultPaginationOptions,
        selectedRows,
        setFilterOptions,
        setPaginationOptions,
        unSelectedRowIds,
        selectedRowIds,
        filterTags,
        setFilterTags,
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          py: '40px',
          px: window?.innerWidth < desktopWidth ? '80px' : '110px',
          marginBottom: '8px',
        }}
      >
        <Flex justifyContent="space-between">
          <Text variant="h3">
            <FormattedMessage
              id="is.patient_list.title"
              defaultMessage="Patient List"
            />
          </Text>

          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: `${showFilters && '#CAE7BE'}`,
              display: 'flex',
              alignItems: 'center',
              marginRight: '16px',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setShowFilters(!showFilters)}
          >
            <FunnelIcon
              style={{
                margin: '6px 0 0 3px',
                width: '30px',
                color: `${!showFilters ? '#3E3E3E' : '#398717'}`,
              }}
            />
          </Box>
        </Flex>

        {
          user?.sites?.length === 1 && (
          <Text
            variant="pBold"
            marginBottom="28px"
          >
            {user.sites[0].name}
          </Text>
          )
        }

        <Flex>
          <ReferralTableFilter showFilters={showFilters} />
        </Flex>

        <Table
          columns={referralColumns}
          data={referralsData}
          totalRows={referrals?.total}
          maxRowsPerPage={MAX_REFERRALS_ROWS}
          isLoading={isFetching}
          onChange={fetchReferrals}
          onSelect={setSelectedRows}
          selectedRows={selectedRows}
          setUnselectedRows={setUnselectedRowIds}
          unSelectedRows={unSelectedRowIds}
          setSelectedRows={setselectedRowIds}
        >

          <ShouldRender when={isError}>
            <ServiceError onRetry={refetch}>
              <FormattedMessage
                id="is.main_page.referrals_table.generic_error_message"
                defaultMessage="Something went wrong while we tried to display your referrals."
              />
            </ServiceError>
          </ShouldRender>

          <ShouldRender when={Boolean(referrals && !referrals.results.length)}>
            {filterOptions?.patientName ? (
              <Table.NoResults
                title={intl.formatMessage({
                  id: 'is.main_page.referrals_table.no_matching_patients_title',
                  defaultMessage: 'We couldn\'t find any patients matching "{ searchText }".',
                }, { searchText: filterOptions.patientName })}
                description={intl.formatMessage({
                  id: 'is.main_page.referrals_table.no_matching_patients_description',
                  defaultMessage: 'Please check for typos or try searching something else.',
                })}
              />
            ) : (
              <Table.NoResults
                title={intl.formatMessage({
                  id: 'is.main_page.referrals_table.no_referrals_title',
                  defaultMessage: 'Looks like you don\'t have any referrals yet.',
                })}
              />
            )}
          </ShouldRender>
        </Table>

        <ReferralTableExportXls />
      </Flex>
    </HomePageContextProvider>
  );
};
