import { SignOut } from './SignOut/SignOut';
import { Help } from './Help/Help';
import { User } from './User/User';
import { Arrow } from './Arrow/Arrow';
import { Bell } from './Bell/Bell';

export const Icons = {
  SignOut,
  Help,
  User,
  Arrow,
  Bell,
};
