/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SiteInfoDto } from './SiteInfoDto';

export type SiteEmployeeDto = {
    id?: string;
    firstName: string;
    lastName: string;
    position?: string;
    email: string;
    phone?: string;
    phone2?: string;
    warmTransferPhone?: string;
    speciality?: string;
    notifications?: string;
    languageCode: string;
    sites: Array<SiteInfoDto>;
    /**
     * Optimistic concurrency check
     */
    ocVersion?: number;
    emailIsVerified?: boolean;
    emailVerifyToken?: string;
    emailVerifyTokenExpirationDate?: string;
    registrationConfirmed?: boolean;
    registrationTokenExpirationDate?: string;
    title?: SiteEmployeeDto.title;
    lastLogin?: string;
    numberOfLogins?: number;
    privacyPolicyUrl?: string;
}

export namespace SiteEmployeeDto {

    export enum title {
        PROF = 'PROF',
        PROF_DR = 'PROF_DR',
        DR = 'DR',
        MRS = 'MRS',
        MR = 'MR',
        MS = 'MS',
    }


}
