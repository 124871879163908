export const Environment = {
  BACKEND_API: process.env.REACT_APP_BACKEND_API || '',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  AUTH0_CLIENT: process.env.REACT_APP_AUTH0_CLIENT || '',
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  AUTHING_APP_ID: process.env.REACT_APP_AUTHING_APP_ID || '',
  AUTHING_DOMAIN: process.env.REACT_APP_AUTHING_DOMAIN || '',
  AUTH0_API: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
  POSTHOG_HOST: process.env.REACT_APP_POSTHOG_HOST || '',
  POSTHOG_TOKEN: process.env.REACT_APP_POSTHOG_TOKEN || '',
  COOKIE_BOT_ID: process.env.REACT_APP_COOKIE_BOT_ID || '',
  CALLBACK_TIMESLOT_ENABLED: process.env.REACT_APP_CALLBACK_TIMESLOT_ENABLED || '',
};
