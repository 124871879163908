import React, { useEffect, useState } from 'react';
import { AccountPageLayout } from 'pages/Account/components';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@clariness/flex';
import { Link } from '@clariness/link';
import { Button } from '@clariness/button';
import { Services } from 'services';
import { InvestigatorNotificationUpdateDto, MultipleInvestigatorNotificationUpdateDto } from 'common/api';
import { Store } from 'store';
import { groupBy, mapValues } from 'lodash';
import { captureEventV2 } from 'utils/events/captureEvent';
import { Events } from '@clariness/tracking-event-runtime';
import { BoxLoader } from 'components/loaders';
import { NewSiteAppointments } from './NewSiteAppointments';
import { NewReferralNotification } from './NewReferralNotification';

export type formData = {
  studyName: string,
  value: Array<InvestigatorNotificationUpdateDto>
}

export const AccountNotification = () => {

  const [formDataList, setFormDataList] = useState<Array<formData>>([]);
  const { user } = Store.useUser();
  const { refetch, data, isFetching } = Services.SiteEmployees.useGetInvestigatorStudies();
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [notifications, setNotifications] = useState<MultipleInvestigatorNotificationUpdateDto>({
    investigatorNotificationUpdateDto: [],
  });

  useEffect(() => {
    if (data) {
      setNotifications({
        investigatorNotificationUpdateDto: data.investigatorNotificationUpdateDto,
      });
      const formDataList: Array<formData> = [];
      const groupBySite = mapValues(groupBy(data.investigatorNotificationUpdateDto, 'siteName'));
      for (const [key, value] of Object.entries(groupBySite)) {
        formDataList.push({
          studyName: key,
          value,
        });
      }
      setFormDataList(formDataList);
    }
  }, [isFetching, data]);

  const { updateNotification } = Services.SiteEmployees.useUpdateInvestigatorNotification({
    onSuccess: () => {
      refetch();
    },
  });
  return (
    <AccountPageLayout>
      <AccountPageLayout.Heading>
        <FormattedMessage
          id="is.account_notification.heading"
          defaultMessage="Notifications"
        />
      </AccountPageLayout.Heading>

      <AccountPageLayout.Paragraph>
        <FormattedMessage
          id="is.account_notification.main_paragraph"
          defaultMessage="By turning on notifications,
           you agree to receive email notifications in accordance with our {privacyPolicyLink}."
          values={{
            privacyPolicyLink:
              (
                <Link href={user?.privacyPolicyUrl}>
                  <FormattedMessage
                    id="is.footer.link.privacy_policy"
                    defaultMessage="Privacy policy"
                  />
                </Link>
              ),
          }}
        />
      </AccountPageLayout.Paragraph>
      {isFetching ? <BoxLoader /> : (
        <AccountPageLayout.Form
          onSubmit={() => {
            captureEventV2(Events.InvestigatorService
              .InvestigatorClickedOnSaveInAccountNotificationPage({ InvestigatorID: user?.id ?? '' }));
            updateNotification(notifications);
          }}
        >
          <Flex
            gap={3}
            sx={{ flexDirection: 'column' }}
          >
            <NewReferralNotification
              formDataList={formDataList}
              isFetching={isFetching}
              setIsSaveEnabled={setIsSaveEnabled}
              notifications={notifications}
              setNotifications={setNotifications}
            />
            <NewSiteAppointments
              formDataList={formDataList}
              isFetching={isFetching}
              setIsSaveEnabled={setIsSaveEnabled}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          </Flex>

          <Button
            type="submit"
            disabled={isSaveEnabled}
            data-test-id="notifications-submit-button"
          >
            <FormattedMessage
              id="is.account_language.save_language_button"
              defaultMessage="Save"
            />
          </Button>
        </AccountPageLayout.Form>
      )}
    </AccountPageLayout>
  );
};
