import React, { useEffect, useState } from 'react';
import { Services } from 'services';
import { usePatientDetailsContext } from 'components/modals/PatientDetailsModal';
import { BoxLoader } from 'components/loaders';
import { ShouldRender } from 'components/ShouldRender';
import { LabeledContent } from 'components/content';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex } from '@clariness/flex';
import { PencilIcon } from '@clariness/icon';
import { Textarea } from '@clariness/textarea';
import { ServiceError } from 'components/ServiceError';
import { PatientDetailsUpdateNote } from './components';

export const PatientDetailsNotes: React.FC = () => {
  const { currentReferral, isEditingNotes, setIsEditingNotes } = usePatientDetailsContext();

  const { referral, isLoading, isError, refetch } = Services.Referrals.useGetReferral(currentReferral?.id);

  const [note, setNote] = useState(referral?.note);

  const intl = useIntl();

  useEffect(() => {
    setNote(referral?.note);
    return () => setIsEditingNotes(false);
  }, [setIsEditingNotes, referral]);

  if (isError) {
    return (
      <ServiceError onRetry={refetch}>
        <FormattedMessage
          id="is.patient_details_modal.notes.generic_error_message"
          defaultMessage="Something went wrong while we tried to display the patient's notes."
        />
      </ServiceError>
    );
  }

  return (
    <React.Fragment>
      <LabeledContent
        sx={{
          '& hr': {
            width: '150px',
          },
        }}
        label={intl.formatMessage({
          id: 'is.patient_details_modal.notes.title',
          defaultMessage: 'Notes',
        })}
        icon={(
          <PencilIcon
            onClick={() => setIsEditingNotes(true)}
            sx={{
              color: isEditingNotes ? 'green.500' : '#979797',
              cursor: !isEditingNotes && 'pointer',
            }}
          />
        )}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Textarea
            readOnly={!isEditingNotes}
            value={note || ''}
            placeholder={intl.formatMessage({
              id: 'is.patient_details_modal.notes.placeholder',
              defaultMessage: 'You can add your notes here.',
            })}
            onChange={e => setNote(e.target.value)}
            onClick={() => {
              if (!isEditingNotes) {
                setIsEditingNotes(true);
              }
            }}
          />

          <ShouldRender when={isEditingNotes}>
            <PatientDetailsUpdateNote
              referral={referral}
              note={note}
              setNote={setNote}
            />
          </ShouldRender>
        </Flex>
      </LabeledContent>

      <ShouldRender when={isLoading}>
        <BoxLoader />
      </ShouldRender>
    </React.Fragment>
  );
};
