import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'services';
import { Store } from 'store';
import { useIntl } from 'react-intl';
import { logout } from 'utils/auth';
import { updatePasswordAPI } from './api';

const UPDATE_PASSWORD_KEY = '@site-employees/update-password';

export function useUpdatePassword(options?: InferMutationOptions<typeof updatePasswordAPI>) {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { mutate, isLoading } = ReactQuery.useMutation(
    UPDATE_PASSWORD_KEY,
    updatePasswordAPI,
    {
      ...options,
      onError: (error, variables, context) => {
        const defaultErrorMessage = error.statusCode === 400 && error.message?.includes('PasswordHistoryError') ?
          {
            id: 'server.errors.already_used_password',
            defaultMessage: 'You have used this password recently, please choose a different password.',
          } :
          {
            id: 'server.errors.change_password_error',
            defaultMessage: 'Unfortunately, an error occurred while we tried to change your password. Please try again.',
          };

        const errorMessage = error.isTranslationFromServer ?
          { id: error.translationKey, defaultMessage: error.message } :
          defaultErrorMessage;

        showNotification({
          variant: 'error',
          description: intl.formatMessage({ id: errorMessage.id, defaultMessage: errorMessage.defaultMessage }),
        });

        if (options?.onError) {
          options.onError(error, variables, context);
        }
      },

      onSuccess: (data, variables, context) => {
        showNotification({
          variant: 'success',
          description: intl.formatMessage({
            id: 'is.update_password.success_notification_message',
            defaultMessage: 'You have successfully updated your password',
          }),
        });

        setTimeout(() => logout(), 1000);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    updatePassword: mutate,
    isLoading,
  };
}
