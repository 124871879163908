import React, { PropsWithChildren, useEffect, useState } from 'react';
import * as ReactTable from 'react-table';
import { Box } from '@clariness/box';
import { generateContext } from 'utils/context';
import { ShouldRender } from 'components/ShouldRender';
import { BoxLoader } from 'components/loaders';
import { TableBody, TableCellTooltip, TableFooter, TableHead, TableNoResults } from './components';

type SortBy = {
  id: string;
  desc?: boolean;
}

type OnChangeState = {
  pageSize: number;
  pageIndex: number;
  sortBy: SortBy[]
}

type TableProps = {
  data: Record<string, unknown>[];
  columns: any;
  totalRows?: number;
  maxRowsPerPage?: number;
  isLoading?: boolean;
  onChange?: (state: OnChangeState) => void;
  onSelect: React.Dispatch<React.SetStateAction<ReactTable.Row[]>>;
  selectedRows: any;
  setUnselectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  unSelectedRows: string[],
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

type TableContext = ReactTable.TableInstance & {
  originalColumns?: any;
  maxRowsPerPage?: number;
  isLoading?: boolean;
}

type HeaderContext = {
  selectedRows: any;
  maxRowsPerPage: number;
  totalRows: number;
  allRowsSelected: boolean;
  unSelectedRows: string[];
  totalSelectedCount: number;
  onSelectAllClicked: () => void;
}

export const [useTableContext, TableContextProvider] = generateContext<TableContext>();
export const [useHeaderContext, HeaderContextProvider] = generateContext<HeaderContext>();

export function Table({
  data,
  columns,
  totalRows = 0,
  maxRowsPerPage = 10,
  isLoading,
  children,
  onChange,
  onSelect,
  selectedRows,
  unSelectedRows,
  setUnselectedRows,
  setSelectedRows,
}: PropsWithChildren<TableProps>) {

  const tableInstance = ReactTable.useTable({
    columns,
    data,
    pageSize: maxRowsPerPage,
    manualPagination: true,
    manualSortBy: true,
    autoResetPage: false,
    autoResetSortBy: false,
    pageCount: Math.ceil((totalRows) / maxRowsPerPage),
    totalRows,
    getRowId: React.useCallback(row => {
      return row.id;
    }, []),
    stateReducer: (newState, action) => {
      if (action.type === 'toggleAllRowsSelected') {
        return {
          ...newState,
          selectedRowIds: {},
        };
      }
      return newState;
    },
    autoResetSelectedRows: false,

  }, ReactTable.useSortBy, ReactTable.usePagination, ReactTable.useRowSelect);

  const [totalSelectedCount, setTotalSelectedCount] = useState<number>(0);

  const { getTableProps,
    state,
    gotoPage,
    selectedFlatRows,
    state: { selectedRowIds },
    rows,
    toggleAllRowsSelected,
  } = tableInstance;

  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);

  const onRowClickHandler = (row: ReactTable.Row) => {
    if (allRowsSelected) {
      if (row.isSelected) {
        setUnselectedRows(prev => [...prev, row.id]);
      } else {
        setUnselectedRows(prev => prev.filter(id => row.id !== id));
      }
    }
  };

  const onSelectAllClicked = () => {
    if (allRowsSelected) {
      setAllRowsSelected(false);
      setUnselectedRows([]);
      toggleAllRowsSelected(false);
      return;
    }
    toggleAllRowsSelected(true);
    setAllRowsSelected(true);
  };

  useEffect(() => {
    setSelectedRows(Object.keys(selectedRowIds));
    setTotalSelectedCount(Object.keys(selectedRowIds).length);
  }, [selectedRowIds, setSelectedRows, setTotalSelectedCount]);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [onChange, state]);

  useEffect(() => {
    if (totalRows <= maxRowsPerPage) {
      gotoPage(0);
    }
  }, [totalRows, maxRowsPerPage, gotoPage]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedFlatRows);
    }
  }, [onSelect, selectedFlatRows]);

  useEffect(() => {
    if (allRowsSelected) {
      for (const row of rows) {
        if (!unSelectedRows.find(unSelectedRow => unSelectedRow === row.id)) {
          row.toggleRowSelected(true);
        } else {
          row.toggleRowSelected(false);
        }
      }

    }
  }, [allRowsSelected, rows, unSelectedRows, setUnselectedRows]);

  tableInstance.originalColumns = columns;
  tableInstance.maxRowsPerPage = maxRowsPerPage;
  tableInstance.isLoading = isLoading;

  return (
    <HeaderContextProvider value={{
      selectedRows,

      maxRowsPerPage,
      totalRows,
      allRowsSelected,
      unSelectedRows,
      totalSelectedCount,
      onSelectAllClicked,
    }}
    >
      <TableContextProvider value={tableInstance}>
        <Box
          sx={{
            display: !data.length && 'flex',
            flexDirection: !data.length && 'column',
            minHeight: !data.length && `${65.2 * maxRowsPerPage}px`,
            position: 'relative',
            overflowY: 'auto',
            boxShadow: '0px 3px 10px rgba(159, 161, 171, 0.3)',
          }}
        >
          <Box
            {...getTableProps()}
            as="table"
            sx={{
              width: '100%',
              borderCollapse: 'collapse',
              color: 'text1',
              fontFamily: 'Noto Sans',
              fontSize: '1.125rem',

              '& thead tr th': {
                position: 'sticky',
                top: '0px',
              },

              '& tfoot tr td': {
                position: 'sticky',
                bottom: '0px',
              },
            }}
          >
            <TableHead />
            <TableBody onClick={onRowClickHandler} />
            <TableFooter />
          </Box>

          {children}

          <ShouldRender when={isLoading}>
            <BoxLoader />
          </ShouldRender>
        </Box>
      </TableContextProvider>
    </HeaderContextProvider>
  );
}

Table.CellTooltip = TableCellTooltip;
Table.NoResults = TableNoResults;
