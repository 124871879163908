import * as ReactQuery from 'react-query';

import { Store } from 'store';
import { useIntl } from 'react-intl';
import { MultipleInvestigatorNotificationUpdateDto } from 'common/api';
import { getGetInvestigatorStudiesAPI } from './api';

const GET_INVESTIGATOR_STUDIES_KEY = '@site-employees/get-investigator-studies';

export function useGetInvestigatorStudies() : 
  {
    data: MultipleInvestigatorNotificationUpdateDto;
    isFetching: boolean;
    refetch: ReactQuery.UseQueryResult['refetch'];
    } {
  const { showNotification } = Store.useNotifications();

  const intl = useIntl();

  const { refetch, data, isFetching } = ReactQuery.useQuery(GET_INVESTIGATOR_STUDIES_KEY, getGetInvestigatorStudiesAPI, {
    onError: () => {
      showNotification({
        variant: 'error',
        description: intl.formatMessage({
          id: 'server.errors.get_notification_error',
          defaultMessage: 'Unfortunately, something failed when fetching your notifications. Please, try again later.',
        }),
      });
    },
  });

  return {
    data: data as MultipleInvestigatorNotificationUpdateDto,
    isFetching,
    refetch,
  };
}
