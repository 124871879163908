import * as ReactQuery from 'react-query';
import { useCallback, useState } from 'react';
import { ReferralSearchFilterDto, ReferralSearchPaginationDto, ReferralSearchSortDto } from 'common/api';
import { InferQueryOptions } from 'services';
import { searchReferralsAPI } from './api';

export const SEARCH_REFERRALS_KEY = '@referrals/search-referrals';

type SearchReferralsOptions = InferQueryOptions<typeof searchReferralsAPI> & {
  take?: number;
  filterOptions?: ReferralSearchFilterDto,
}

type PaginationOptions = ReferralSearchPaginationDto | undefined;

export function useSearchReferrals({ take, ...options }: SearchReferralsOptions) {
  const defaultPaginationOptions = {
    take,
    skip: 0,
    sorting: [],
  };

  const [filterOptions, setFilterOptions] = useState<ReferralSearchFilterDto | undefined>(options?.filterOptions);
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>(defaultPaginationOptions);

  const filter = options.filterOptions || filterOptions;
  const { data, isLoading, isError, isFetching, refetch } = ReactQuery.useQuery(
    [SEARCH_REFERRALS_KEY, filter, paginationOptions],
    () => searchReferralsAPI({ filter, pagination: paginationOptions }),
    {
      keepPreviousData: true,
      ...options,
    },
  );

  const fetchReferrals = useCallback(state => {
    setPaginationOptions({
      take,
      skip: take ? take * state.pageIndex : undefined,
      sorting: [
        {
          column: ReferralSearchSortDto.column.LAST_UPDATE,
          asc: true,
        },
      ],
    });
  }, [take]);

  return {
    referrals: data,
    filterOptions,
    setFilterOptions,
    setPaginationOptions,
    defaultPaginationOptions,
    isLoading,
    isFetching,
    isError,
    fetchReferrals,
    refetch,
  };
}
