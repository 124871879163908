/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllocationDto } from '../models/AllocationDto';
import type { AuditHistoryLog } from '../models/AuditHistoryLog';
import type { CallbackTimeslotDto } from '../models/CallbackTimeslotDto';
import type { CallLogDto } from '../models/CallLogDto';
import type { CcActivityEventDto } from '../models/CcActivityEventDto';
import type { ChangeCurrentPasswordDto } from '../models/ChangeCurrentPasswordDto';
import type { ChangeForgottenPasswordDto } from '../models/ChangeForgottenPasswordDto';
import type { ContactCenterCallPostParametersDto } from '../models/ContactCenterCallPostParametersDto';
import type { ContactCenterCallPostResultDto } from '../models/ContactCenterCallPostResultDto';
import type { ContactCenterSmsPostParametersDto } from '../models/ContactCenterSmsPostParametersDto';
import type { CountryDto } from '../models/CountryDto';
import type { CreateSiteCallbackTimeslotDto } from '../models/CreateSiteCallbackTimeslotDto';
import type { EscalationCountDTO } from '../models/EscalationCountDTO';
import type { ForgotPasswordDto } from '../models/ForgotPasswordDto';
import type { GetSmsTemplateDto } from '../models/GetSmsTemplateDto';
import type { ImportCsvResponseDto } from '../models/ImportCsvResponseDto';
import type { IndicationWithIcd10CodeDto } from '../models/IndicationWithIcd10CodeDto';
import type { InvestigatorDto } from '../models/InvestigatorDto';
import type { InvestigatorUpdateDto } from '../models/InvestigatorUpdateDto';
import type { LanguageDto } from '../models/LanguageDto';
import type { LogInstance } from '../models/LogInstance';
import type { MessageBusSecretKeyDto } from '../models/MessageBusSecretKeyDto';
import type { MultipleInvestigatorNotificationUpdateDto } from '../models/MultipleInvestigatorNotificationUpdateDto';
import type { NumberOfContacts } from '../models/NumberOfContacts';
import type { PatientDto } from '../models/PatientDto';
import type { PossibleCallTypes } from '../models/PossibleCallTypes';
import type { ProcessCall } from '../models/ProcessCall';
import type { ProductUpdateDto } from '../models/ProductUpdateDto';
import type { ProtocolInfoDto } from '../models/ProtocolInfoDto';
import type { ProtocolListDto } from '../models/ProtocolListDto';
import type { PssNoteDto } from '../models/PssNoteDto';
import type { ReferralActivitiesDto } from '../models/ReferralActivitiesDto';
import type { ReferralDto } from '../models/ReferralDto';
import type { ReferralInfoDto } from '../models/ReferralInfoDto';
import type { ReferralInvestigatorDto } from '../models/ReferralInvestigatorDto';
import type { ReferralLockDto } from '../models/ReferralLockDto';
import type { ReferralNoteChangeDto } from '../models/ReferralNoteChangeDto';
import type { ReferralNoteDto } from '../models/ReferralNoteDto';
import type { ReferralRelocationDto } from '../models/ReferralRelocationDto';
import type { ReferralSearchParametersDto } from '../models/ReferralSearchParametersDto';
import type { ReferralSearchResultDto } from '../models/ReferralSearchResultDto';
import type { ReferralStatusChangeDto } from '../models/ReferralStatusChangeDto';
import type { ReferralUpdateDto } from '../models/ReferralUpdateDto';
import type { ReferralXlsxDto } from '../models/ReferralXlsxDto';
import type { SccActivityEventDto } from '../models/SccActivityEventDto';
import type { SccHistoryFiltersDto } from '../models/SccHistoryFiltersDto';
import type { SendAutomatedEmailToPatientDto } from '../models/SendAutomatedEmailToPatientDto';
import type { SendEmailToPatientDto } from '../models/SendEmailToPatientDto';
import type { SendInvitationLinkDto } from '../models/SendInvitationLinkDto';
import type { SendRegistrationEmailDto } from '../models/SendRegistrationEmailDto';
import type { SiteCallbackTimeslotDto } from '../models/SiteCallbackTimeslotDto';
import type { SiteDto } from '../models/SiteDto';
import type { SiteEmailDto } from '../models/SiteEmailDto';
import type { SiteEmployeeDto } from '../models/SiteEmployeeDto';
import type { SiteEmployeeExistsDto } from '../models/SiteEmployeeExistsDto';
import type { SiteEmployeeInfoDto } from '../models/SiteEmployeeInfoDto';
import type { SiteEmployeeInvitedCheckDto } from '../models/SiteEmployeeInvitedCheckDto';
import type { SiteEmployeeRegisterDto } from '../models/SiteEmployeeRegisterDto';
import type { SiteEmployeeStudyDto } from '../models/SiteEmployeeStudyDto';
import type { SiteEmployeeUpdateDto } from '../models/SiteEmployeeUpdateDto';
import type { SiteEscalationDTO } from '../models/SiteEscalationDTO';
import type { SiteHavingStudyDto } from '../models/SiteHavingStudyDto';
import type { SiteInfoDto } from '../models/SiteInfoDto';
import type { SiteNoteDto } from '../models/SiteNoteDto';
import type { SitePerformanceActivityDto } from '../models/SitePerformanceActivityDto';
import type { SitePerformancePossibleOptionsDto } from '../models/SitePerformancePossibleOptionsDto';
import type { SiteSearchParametersDto } from '../models/SiteSearchParametersDto';
import type { SiteSearchResult } from '../models/SiteSearchResult';
import type { SiteUpdateDto } from '../models/SiteUpdateDto';
import type { SiteWithAllStudiesDto } from '../models/SiteWithAllStudiesDto';
import type { SmsLogDto } from '../models/SmsLogDto';
import type { SpmAllocationSearchResult } from '../models/SpmAllocationSearchResult';
import type { SpmDto } from '../models/SpmDto';
import type { SpmParameterDto } from '../models/SpmParameterDto';
import type { SpmSearchResult } from '../models/SpmSearchResult';
import type { StudyBaseInfoDto } from '../models/StudyBaseInfoDto';
import type { StudyCallbackTimeslotDto } from '../models/StudyCallbackTimeslotDto';
import type { StudyDto } from '../models/StudyDto';
import type { StudySiteDto } from '../models/StudySiteDto';
import type { StudySiteProtocolsDto } from '../models/StudySiteProtocolsDto';
import type { StudySitePutAdminDto } from '../models/StudySitePutAdminDto';
import type { StudySiteSearchParametersDto } from '../models/StudySiteSearchParametersDto';
import type { StudySiteSearchResult } from '../models/StudySiteSearchResult';
import type { StudySiteUpdateDto } from '../models/StudySiteUpdateDto';
import type { SupportedCountryDto } from '../models/SupportedCountryDto';
import type { TimeZoneDto } from '../models/TimeZoneDto';
import type { TwilioLogDto } from '../models/TwilioLogDto';
import type { TwilioPhoneNumberDto } from '../models/TwilioPhoneNumberDto';
import type { UpdateAllocationDto } from '../models/UpdateAllocationDto';
import type { UpdateStudyAllocationDto } from '../models/UpdateStudyAllocationDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class Service {
  /**
   * @returns any
   * @throws ApiError
   */
  public static appControllerGetHealthCheck(): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/`,
    });
  }

  /**
   * Get activity history of the referral
   * @param referralId
   * @param filterType
   * @returns CcActivityEventDto
   * @throws ApiError
   */
  public static auditLogControllerGetCcActivityHistory(
    referralId: string,
    filterType: string,
  ): CancelablePromise<Array<CcActivityEventDto>> {
    return __request({
      method: 'GET',
      path: `/audit-log/cc-activity-history`,
      query: {
        referralId: referralId,
        filterType: filterType,
      },
    });
  }

  /**
   * Get audit logs in entity ids
   * @param entityIds
   * @param siteId
   * @param studyId
   * @param limit
   * @returns AuditHistoryLog
   * @throws ApiError
   */
  public static auditLogControllerGetByEntityIds(
    entityIds: any,
    siteId: string,
    studyId: string,
    limit?: number,
  ): CancelablePromise<Array<AuditHistoryLog>> {
    return __request({
      method: 'GET',
      path: `/audit-log/${entityIds}`,
      query: {
        siteId: siteId,
        studyId: studyId,
        limit: limit,
      },
    });
  }

  /**
   * Get activity history of the site
   * @param requestBody
   * @returns SccActivityEventDto
   * @throws ApiError
   */
  public static auditLogControllerGetSccActivityHistory(
    requestBody: SccHistoryFiltersDto,
  ): CancelablePromise<Array<SccActivityEventDto>> {
    return __request({
      method: 'PUT',
      path: `/audit-log/scc-activity-history`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * create call log
   * @param requestBody
   * @returns CallLogDto
   * @throws ApiError
   */
  public static auditLogControllerCreateCallLog(requestBody: CallLogDto): CancelablePromise<CallLogDto> {
    return __request({
      method: 'PUT',
      path: `/audit-log/possibleCallTypes`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * create Twilio call log for contact center
   * @param requestBody
   * @returns CallLogDto
   * @throws ApiError
   */
  public static auditLogControllerLogTwilioCall(requestBody: CallLogDto): CancelablePromise<CallLogDto> {
    return __request({
      method: 'POST',
      path: `/audit-log/logTwilioCall`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * create Twilio sms log for contact center
   * @param requestBody
   * @returns SmsLogDto
   * @throws ApiError
   */
  public static auditLogControllerLogTwilioEvent(requestBody: SmsLogDto): CancelablePromise<SmsLogDto> {
    return __request({
      method: 'POST',
      path: `/audit-log/logTwilioSms`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get referral Twilio Logs
   * @param phone
   * @returns TwilioLogDto
   * @throws ApiError
   */
  public static auditLogControllerFetchTwilioLogs(phone: string): CancelablePromise<Array<TwilioLogDto>> {
    return __request({
      method: 'GET',
      path: `/audit-log/twilioLogs/${phone}`,
    });
  }

  /**
   * Get indications
   * @returns IndicationWithIcd10CodeDto
   * @throws ApiError
   */
  public static indicationControllerList(): CancelablePromise<Array<IndicationWithIcd10CodeDto>> {
    return __request({
      method: 'GET',
      path: `/indication`,
    });
  }

  /**
   * Get indication
   * @param id
   * @returns IndicationWithIcd10CodeDto
   * @throws ApiError
   */
  public static indicationControllerGet(id: number): CancelablePromise<IndicationWithIcd10CodeDto> {
    return __request({
      method: 'GET',
      path: `/indication/${id}`,
    });
  }

  /**
   * Get the languages of studies
   * @returns LanguageDto
   * @throws ApiError
   */
  public static studyControllerGetStudiesLanguages(): CancelablePromise<Array<LanguageDto>> {
    return __request({
      method: 'GET',
      path: `/study/languages`,
    });
  }

  /**
   * Get site employee studies
   * @param siteId
   * @param employeeId
   * @returns SiteEmployeeStudyDto
   * @throws ApiError
   */
  public static studyControllerGetSiteEmployeeStudies(
    siteId: string,
    employeeId: string,
  ): CancelablePromise<Array<SiteEmployeeStudyDto>> {
    return __request({
      method: 'GET',
      path: `/study/siteEmployeeStudy`,
      query: {
        siteId: siteId,
        employeeId: employeeId,
      },
    });
  }

  /**
   * Search studies
   * @param requestBody
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerSearchAll(requestBody: SiteSearchParametersDto): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'PUT',
      path: `/study/search/all`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Search studies
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerGetArSubs(): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'GET',
      path: `/study/getArSubs/all`,
    });
  }

  /**
   * Get studies
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerList(): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'GET',
      path: `/study`,
    });
  }

  /**
   * Get list of studies
   * @returns StudyBaseInfoDto
   * @throws ApiError
   */
  public static studyControllerStudyFitlerList(): CancelablePromise<Array<StudyBaseInfoDto>> {
    return __request({
      method: 'GET',
      path: `/study/list`,
    });
  }

  /**
   * Get current user studies
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerCurrentUserStudies(): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'GET',
      path: `/study/my`,
    });
  }

  /**
   * Get current user studies for filter
   * @param email
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerCurrentUserStudiesForFilter(email: string): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'GET',
      path: `/study/spm-studies/${email}`,
    });
  }

  /**
   * Get study
   * @param id
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerGet(id: string): CancelablePromise<StudyDto> {
    return __request({
      method: 'GET',
      path: `/study/${id}`,
    });
  }

  /**
   * Get the list of spm unassigned studies
   * @param id
   * @returns StudyDto
   * @throws ApiError
   */
  public static studyControllerSpmStudyList(id: string): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'GET',
      path: `/study/spmStudyList/${id}`,
    });
  }

  /**
   * Get site employees
   * @param siteid
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerList(siteid?: string): CancelablePromise<Array<SiteEmployeeDto>> {
    return __request({
      method: 'GET',
      path: `/siteemployee`,
      query: {
        siteid: siteid,
      },
    });
  }

  /**
   * Create site employee
   * @param requestBody
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerCreate(requestBody: SiteEmployeeUpdateDto): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'POST',
      path: `/siteemployee`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get site employees basic data
   * @param siteid
   * @returns SiteEmployeeInfoDto
   * @throws ApiError
   */
  public static siteEmployeeControllerListInfo(siteid?: string): CancelablePromise<Array<SiteEmployeeInfoDto>> {
    return __request({
      method: 'GET',
      path: `/siteemployee/info`,
      query: {
        siteid: siteid,
      },
    });
  }

  /**
   * Get current site employee
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerGetCurrentSiteEmployee(): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'GET',
      path: `/siteemployee/current`,
    });
  }

  /**
   * Update current site employee
   * @param requestBody
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerUpdateCurrentSiteEmployee(
    requestBody: SiteEmployeeUpdateDto,
  ): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'PUT',
      path: `/siteemployee/current`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get current site employee
   * @param siteId
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerGetBySiteId(siteId: string): CancelablePromise<Array<SiteEmployeeDto>> {
    return __request({
      method: 'GET',
      path: `/siteemployee/by-site-id/${siteId}`,
    });
  }

  /**
   * Login (temporarily for JWT token)
   * TODO: Remove
   */
  public static login(username: string, password: string): CancelablePromise<{ accessToken: string }> {
    return __request({
      method: 'POST',
      path: `/auth/login`,
      body: { username, password },
      mediaType: 'application/json',
    });
  }

  /**
   * Update current user login info
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerUpdateCurrentUserLoginInfo(): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'PUT',
      path: `/siteemployee/currentUserLoginInfo`,
    });
  }

  /**
   * Update current site employee password
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerUpdateCurrentPassword(
    requestBody: ChangeCurrentPasswordDto,
  ): CancelablePromise<void> {
    return __request({
      method: 'PUT',
      path: `/siteemployee/current/updatePassword`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Sends password reset email to site employee email
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerSendForgotPasswordEmail(requestBody: ForgotPasswordDto): CancelablePromise<void> {
    return __request({
      method: 'POST',
      path: `/siteemployee/forgotPassword`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Changes the user password if the input token is correct
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerChangeForgottenPassword(
    requestBody: ChangeForgottenPasswordDto,
  ): CancelablePromise<void> {
    return __request({
      method: 'POST',
      path: `/siteemployee/changeForgottenPassword`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Sends invitation link email to site employee in order to activate an account
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerSendInvitationLinkEmail(
    requestBody: SendInvitationLinkDto,
  ): CancelablePromise<void> {
    return __request({
      method: 'POST',
      path: `/siteemployee/send-invitation-link`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Check if site employee exists in dev database, workaround for database restore issue (auth0 users not cleaned up)
   * @param email
   * @returns SiteEmployeeExistsDto
   * @throws ApiError
   */
  public static siteEmployeeControllerResetPasswordCheck(email: string): CancelablePromise<SiteEmployeeExistsDto> {
    return __request({
      method: 'GET',
      path: `/siteemployee/resetPasswordCheck`,
      query: {
        email: email,
      },
    });
  }

  /**
   * Get site employee
   * @param id
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerGet(id: string): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'GET',
      path: `/siteemployee/${id}`,
    });
  }

  /**
   * Update site employee
   * @param id
   * @param requestBody
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerUpdate(
    id: string,
    requestBody: SiteEmployeeUpdateDto,
  ): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'PUT',
      path: `/siteemployee/${id}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete site employee
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerDelete(id: string): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      path: `/siteemployee/${id}`,
      errors: {
        412: `Error that should be passed to user.`,
      },
    });
  }

  /**
   * Get site employee by email
   * @param email
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerGetByEmail(email: string): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'GET',
      path: `/siteemployee/getByEmail/${email}`,
    });
  }

  /**
   * Confirm site employee registration and add them to Auth0
   * @param requestBody
   * @returns SiteEmployeeDto
   * @throws ApiError
   */
  public static siteEmployeeControllerFinishRegistration(
    requestBody: SiteEmployeeRegisterDto,
  ): CancelablePromise<SiteEmployeeDto> {
    return __request({
      method: 'POST',
      path: `/siteemployee/finishRegistration`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Send registration email to site employee
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerSendRegistrationEmail(
    requestBody: SendRegistrationEmailDto,
  ): CancelablePromise<void> {
    return __request({
      method: 'POST',
      path: `/siteemployee/sendRegistrationEmail`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Check if the registration email is already registered
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static siteEmployeeControllerCanRegisterEmail(
    requestBody: SiteEmployeeInvitedCheckDto,
  ): CancelablePromise<void> {
    return __request({
      method: 'POST',
      path: `/siteemployee/canRegisterEmail`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove site employee from site
   * @param siteEmployeeId
   * @param siteId
   * @returns StudyDto
   * @throws ApiError
   */
  public static siteEmployeeControllerRemoveFromSite(
    siteEmployeeId: string,
    siteId: string,
  ): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'PUT',
      path: `/siteemployee/${siteEmployeeId}/removeFromSite/${siteId}`,
    });
  }

  /**
   * Get all site employee product updates
   * @param siteEmployeeId
   * @returns ProductUpdateDto
   * @throws ApiError
   */
  public static productUpdateControllerGet(siteEmployeeId: string): CancelablePromise<Array<ProductUpdateDto>> {
    return __request({
      method: 'GET',
      path: `/product-update/${siteEmployeeId}`,
    });
  }

  /**
   * Set product update to read
   * @param id
   * @returns ProductUpdateDto
   * @throws ApiError
   */
  public static productUpdateControllerSetRead(id: string): CancelablePromise<ProductUpdateDto> {
    return __request({
      method: 'PATCH',
      path: `/product-update/set-read/${id}`,
    });
  }

  /**
   * Sync data with Lokalise
   * @returns string
   * @throws ApiError
   */
  public static productUpdateControllerSync(): CancelablePromise<string> {
    return __request({
      method: 'POST',
      path: `/product-update/sync`,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static translationControllerGetTranslations(): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/translation`,
    });
  }

  /**
   * Get sites by name and department
   * @param siteName
   * @param department
   * @returns SiteDto
   * @throws ApiError
   */
  public static siteControllerGetSiteByNameAndDepartment(
    siteName?: string,
    department?: string,
  ): CancelablePromise<SiteDto> {
    return __request({
      method: 'GET',
      path: `/site/getDuplicated`,
      query: {
        siteName: siteName,
        department: department,
      },
    });
  }

  /**
   * Get sites
   * @param includeSubsites
   * @param studyId
   * @param studyLanguage
   * @param city
   * @param siteType
   * @param countryCode
   * @param siteStatus
   * @param zipCode
   * @param siteName
   * @param siteAddress
   * @param department
   * @param isRelocation
   * @param skip
   * @param take
   * @returns SiteDto
   * @throws ApiError
   */
  public static siteControllerList(
    includeSubsites?: boolean,
    studyId?: string,
    studyLanguage?: string,
    city?: string,
    siteType?:
      | 'UNIVERSITY'
      | 'HOSPITAL'
      | 'VIRTUAL'
      | 'OTHER'
      | 'SMO'
      | 'PROFESSIONAL_RESEARCH_SITE'
      | 'DOCTORS_PRACTICE',
    countryCode?: string,
    siteStatus?: 'SETUP' | 'CANCELED' | 'ACTIVE' | 'INACTIVE' | 'PAUSED' | 'CLOSEOUT' | 'COMPLETED',
    zipCode?: string,
    siteName?: string,
    siteAddress?: string,
    department?: string,
    isRelocation?: boolean,
    skip?: number,
    take?: number,
  ): CancelablePromise<Array<SiteDto>> {
    return __request({
      method: 'GET',
      path: `/site`,
      query: {
        includeSubsites: includeSubsites,
        studyId: studyId,
        studyLanguage: studyLanguage,
        city: city,
        siteType: siteType,
        countryCode: countryCode,
        siteStatus: siteStatus,
        zipCode: zipCode,
        siteName: siteName,
        siteAddress: siteAddress,
        department: department,
        isRelocation: isRelocation,
        skip: skip,
        take: take,
      },
    });
  }

  /**
   * Create site
   * @param requestBody
   * @returns SiteDto
   * @throws ApiError
   */
  public static siteControllerCreate(requestBody: SiteUpdateDto): CancelablePromise<SiteDto> {
    return __request({
      method: 'POST',
      path: `/site`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get sites
   * @param includeSubsites
   * @param studyId
   * @param studyLanguage
   * @param city
   * @param siteType
   * @param countryCode
   * @param siteStatus
   * @param zipCode
   * @param siteName
   * @param siteAddress
   * @param department
   * @param isRelocation
   * @param skip
   * @param take
   * @returns SiteSearchResult
   * @throws ApiError
   */
  public static siteControllerListPreview(
    includeSubsites?: boolean,
    studyId?: string,
    studyLanguage?: string,
    city?: string,
    siteType?:
      | 'UNIVERSITY'
      | 'HOSPITAL'
      | 'VIRTUAL'
      | 'OTHER'
      | 'SMO'
      | 'PROFESSIONAL_RESEARCH_SITE'
      | 'DOCTORS_PRACTICE',
    countryCode?: string,
    siteStatus?: 'SETUP' | 'CANCELED' | 'ACTIVE' | 'INACTIVE' | 'PAUSED' | 'CLOSEOUT' | 'COMPLETED',
    zipCode?: string,
    siteName?: string,
    siteAddress?: string,
    department?: string,
    isRelocation?: boolean,
    skip?: number,
    take?: number,
  ): CancelablePromise<SiteSearchResult> {
    return __request({
      method: 'GET',
      path: `/site/listPreview`,
      query: {
        includeSubsites: includeSubsites,
        studyId: studyId,
        studyLanguage: studyLanguage,
        city: city,
        siteType: siteType,
        countryCode: countryCode,
        siteStatus: siteStatus,
        zipCode: zipCode,
        siteName: siteName,
        siteAddress: siteAddress,
        department: department,
        isRelocation: isRelocation,
        skip: skip,
        take: take,
      },
    });
  }

  /**
   * Get countries having sites
   * @returns CountryDto
   * @throws ApiError
   */
  public static siteControllerListCountries(): CancelablePromise<Array<CountryDto>> {
    return __request({
      method: 'GET',
      path: `/site/countries`,
    });
  }

  /**
   * Get site
   * @param id
   * @param includesubsites
   * @returns SiteDto
   * @throws ApiError
   */
  public static siteControllerGet(id: string, includesubsites?: boolean): CancelablePromise<SiteDto> {
    return __request({
      method: 'GET',
      path: `/site/${id}`,
      query: {
        includesubsites: includesubsites,
      },
    });
  }

  /**
   * Update site
   * @param id
   * @param requestBody
   * @returns SiteDto
   * @throws ApiError
   */
  public static siteControllerUpdate(id: string, requestBody: SiteUpdateDto): CancelablePromise<SiteDto> {
    return __request({
      method: 'PUT',
      path: `/site/${id}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete site
   * @param id
   * @returns void
   * @throws ApiError
   */
  public static siteControllerDelete(id: string): CancelablePromise<void> {
    return __request({
      method: 'DELETE',
      path: `/site/${id}`,
      errors: {
        412: `Error that should be passed to user.`,
      },
    });
  }

  /**
   * Send all data via message bus
   * @param requestBody
   * @returns MessageBusSecretKeyDto
   * @throws ApiError
   */
  public static siteControllerSendAllToMessageBus(
    requestBody: MessageBusSecretKeyDto,
  ): CancelablePromise<MessageBusSecretKeyDto> {
    return __request({
      method: 'POST',
      path: `/site/sendAllToMB`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get countrys
   * @returns CountryDto
   * @throws ApiError
   */
  public static countryControllerList(): CancelablePromise<Array<CountryDto>> {
    return __request({
      method: 'GET',
      path: `/country`,
    });
  }

  /**
   * Get country
   * @param code
   * @returns CountryDto
   * @throws ApiError
   */
  public static countryControllerGet(code: string): CancelablePromise<CountryDto> {
    return __request({
      method: 'GET',
      path: `/country/${code}`,
    });
  }

  /**
   * Send a ping message to NewLeaf to validate the system. A new message will be returned, and you will receive the measured time that is needed for the whole flow.
   * @returns any
   * @throws ApiError
   */
  public static messageBusControllerPing(): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/message-bus/ping`,
    });
  }

  /**
   * Send a ping message to myself to validate the system. A new message will be returned, and you will receive the measured time that is needed for the whole flow.
   * @returns any
   * @throws ApiError
   */
  public static messageBusControllerPingSelf(): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/message-bus/pingSelf`,
    });
  }

  /**
   * Send messages from DLQ to main queue for reprocesing
   * @param requestBody
   * @returns MessageBusSecretKeyDto
   * @throws ApiError
   */
  public static messageBusControllerFromDlqToMainQueue(
    requestBody: MessageBusSecretKeyDto,
  ): CancelablePromise<MessageBusSecretKeyDto> {
    return __request({
      method: 'POST',
      path: `/message-bus/fromDlqToMainQueue`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get languages
   * @returns LanguageDto
   * @throws ApiError
   */
  public static languageControllerList(): CancelablePromise<Array<LanguageDto>> {
    return __request({
      method: 'GET',
      path: `/language`,
    });
  }

  /**
   * Get language
   * @param code
   * @returns LanguageDto
   * @throws ApiError
   */
  public static languageControllerGet(code: string): CancelablePromise<LanguageDto> {
    return __request({
      method: 'GET',
      path: `/language/${code}`,
    });
  }

  /**
   * Get referrals
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerList(): CancelablePromise<Array<ReferralDto>> {
    return __request({
      method: 'GET',
      path: `/referral`,
    });
  }

  /**
   * Run migration to add Show to investigators to all existing questions in a json
   * @returns string
   * @throws ApiError
   */
  public static referralControllerRunVisibleToInvestigatorsMigration(): CancelablePromise<string> {
    return __request({
      method: 'GET',
      path: `/referral/run-visible-to-investigators-migration`,
    });
  }

  /**
   * Search referrals
   * @param requestBody
   * @returns ReferralSearchResultDto
   * @throws ApiError
   */
  public static referralControllerSearch(
    requestBody: ReferralSearchParametersDto,
  ): CancelablePromise<ReferralSearchResultDto> {
    return __request({
      method: 'PUT',
      path: `/referral/search`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Search contact center list
   * @param requestBody
   * @returns ReferralSearchResultDto
   * @throws ApiError
   */
  public static referralControllerCclistSearch(
    requestBody: ReferralSearchParametersDto,
  ): CancelablePromise<ReferralSearchResultDto> {
    return __request({
      method: 'PUT',
      path: `/referral/search/cclist`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Search referrals by patient phone number
   * @param phoneNumber
   * @returns ReferralInfoDto
   * @throws ApiError
   */
  public static referralControllerSearchByPatientPhoneNumber(phoneNumber: string): CancelablePromise<ReferralInfoDto> {
    return __request({
      method: 'GET',
      path: `/referral/search/patientphone/${phoneNumber}`,
    });
  }

  /**
   * Get referral CSV
   * @param referralId
   * @param patientId
   * @returns string
   * @throws ApiError
   */
  public static referralControllerOfflineScreener(referralId: string, patientId?: string): CancelablePromise<string> {
    return __request({
      method: 'GET',
      path: `/referral/offlineScreener`,
      query: {
        referralId: referralId,
        patientId: patientId,
      },
    });
  }

  /**
   * Get external link to Offline Screener on NL
   * @param id
   * @returns string
   * @throws ApiError
   */
  public static referralControllerOfflineScreenerGenerateUrl(id: string): CancelablePromise<string> {
    return __request({
      method: 'GET',
      path: `/referral/offlineScreener/generate-url/${id}`,
    });
  }

  /**
   * Get referral
   * @param id
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerGet(id: string): CancelablePromise<ReferralDto> {
    return __request({
      method: 'GET',
      path: `/referral/${id}`,
    });
  }

  /**
   * Get referral
   * @param id
   * @returns binary
   * @throws ApiError
   */
  public static referralControllerGetPdfForCurrentUser(id: string): CancelablePromise<Blob> {
    return __request({
      method: 'GET',
      path: `/referral/currentuser/${id}/pdf`,
    });
  }

  /**
   * Get referral
   * @param id
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerGetForCurrentUser(id: string): CancelablePromise<ReferralDto> {
    return __request({
      method: 'GET',
      path: `/referral/currentuser/${id}`,
    });
  }

  /**
   * Get referral
   * @param id
   * @returns ReferralInfoDto
   * @throws ApiError
   */
  public static referralControllerGetSiteTimeZoneCurrentUser(id: string): CancelablePromise<ReferralInfoDto> {
    return __request({
      method: 'GET',
      path: `/referral/currentuser/siteTimeZone/${id}`,
    });
  }

  /**
   * Get referral
   * @param id
   * @returns ReferralInvestigatorDto
   * @throws ApiError
   */
  public static referralControllerGetForCurrentUserAdmin(id: string): CancelablePromise<ReferralInvestigatorDto> {
    return __request({
      method: 'GET',
      path: `/referral/currentuseradmin/${id}`,
    });
  }

  /**
   * Get referral activities
   * @param id
   * @param returnUserName
   * @param includeCcStatues
   * @returns ReferralActivitiesDto
   * @throws ApiError
   */
  public static referralControllerGetActivities(
    id: string,
    returnUserName?: boolean,
    includeCcStatues?: boolean,
  ): CancelablePromise<ReferralActivitiesDto> {
    return __request({
      method: 'GET',
      path: `/referral/${id}/activities`,
      query: {
        returnUserName: returnUserName,
        includeCCStatues: includeCcStatues,
      },
    });
  }

  /**
   * Get referral status history
   * @param id
   * @param returnUserName
   * @returns ReferralActivitiesDto
   * @throws ApiError
   */
  public static referralControllerGetHistory(
    id: string,
    returnUserName?: any,
  ): CancelablePromise<ReferralActivitiesDto> {
    return __request({
      method: 'GET',
      path: `/referral/${id}/history`,
      query: {
        returnUserName: returnUserName,
      },
    });
  }

  /**
   * Change referral status
   * @param id
   * @param requestBody
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerChangeStatus(
    id: string,
    requestBody: ReferralStatusChangeDto,
  ): CancelablePromise<ReferralDto> {
    return __request({
      method: 'PATCH',
      path: `/referral/${id}/status`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Relocate referral
   * @param requestBody
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerRelocateReferral(requestBody: ReferralRelocationDto): CancelablePromise<ReferralDto> {
    return __request({
      method: 'PATCH',
      path: `/referral/relocation`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Change referral status
   * @param id
   * @param requestBody
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerChangeStatusAsCurrentUser(
    id: string,
    requestBody: ReferralStatusChangeDto,
  ): CancelablePromise<ReferralDto> {
    return __request({
      method: 'PATCH',
      path: `/referral/${id}/currentuser/status`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Change referral status
   * @param id
   * @param requestBody
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerChangeNoteAsCurrentUser(
    id: string,
    requestBody: ReferralNoteChangeDto,
  ): CancelablePromise<ReferralDto> {
    return __request({
      method: 'PATCH',
      path: `/referral/${id}/currentuser/note`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get referral excel file
   * @param requestBody
   * @returns string xlsx file in base64 format
   * @throws ApiError
   */
  public static referralControllerDownloadXlsx(requestBody: ReferralXlsxDto): CancelablePromise<string> {
    return __request({
      method: 'POST',
      path: `/referral/downloadXlsx`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Send all data via message bus
   * @param requestBody
   * @returns MessageBusSecretKeyDto
   * @throws ApiError
   */
  public static referralControllerSendAllStatusesToMessageBus(
    requestBody: MessageBusSecretKeyDto,
  ): CancelablePromise<MessageBusSecretKeyDto> {
    return __request({
      method: 'POST',
      path: `/referral/sendAllStatusesToMB`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Import Offline Screener Answers
   * @param referralId
   * @param formData
   * @returns ImportCsvResponseDto
   * @throws ApiError
   */
  public static referralControllerImportOfflineScreenerAnswers(
    referralId: string,
    formData: {
      file?: Blob;
    },
  ): CancelablePromise<ImportCsvResponseDto> {
    return __request({
      method: 'POST',
      path: `/referral/import-offline-screener-answers/${referralId}`,
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * update patient availability
   * @param requestBody
   * @returns PatientDto
   * @throws ApiError
   */
  public static referralControllerUpdatePatientAvailability(requestBody: PatientDto): CancelablePromise<PatientDto> {
    return __request({
      method: 'PATCH',
      path: `/referral/patient`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * update referral
   * @param requestBody
   * @returns ReferralDto
   * @throws ApiError
   */
  public static referralControllerUpdateReferral(requestBody: ReferralUpdateDto): CancelablePromise<ReferralDto> {
    return __request({
      method: 'PATCH',
      path: `/referral/update`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Send automated email to patient
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static referralControllerSendAutomatedEmailToPatient(
    requestBody: SendAutomatedEmailToPatientDto,
  ): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/referral/sendAutomatedEmailToPatient`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Send email to patient
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static referralControllerSendEmailToPatient(requestBody: SendEmailToPatientDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/referral/sendEmailToPatient`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get email template
   * @param referralId
   * @param templateKey
   * @returns any
   * @throws ApiError
   */
  public static referralControllerGetEmailTemplate(
    referralId: string,
    templateKey:
      | 'INVITE_TO_RESCHEDULE'
      | 'SCHEDULED_MEETING_CONFIRMATION'
      | 'INVALID_PHONE_NUMBER'
      | 'NEWSLETTER_INVITATION'
      | 'SITE_EMPLOYEE_INVITATION'
      | 'RESET_PASSWORD'
      | 'NEW_APPOINTMENT'
      | 'NEW_REFERRAL'
      | 'WEEKLY_NEW_REFERRAL'
      | 'PATIENT_APPOINTMENT_REMINDER'
      | 'FOLLOWUP_ON_CLINLIFE_REGISTRATION'
      | 'FINAL_ATTEMPT_NOTICE',
  ): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/referral/getEmailTemplate`,
      query: {
        referralId: referralId,
        templateKey: templateKey,
      },
    });
  }

  /**
   * Get SMS template
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static referralControllerGetSmsTemplate(requestBody: GetSmsTemplateDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/referral/getSmsTemplate`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Generate reschedule contact time url
   * @param referralId
   * @returns string
   * @throws ApiError
   */
  public static referralControllerGetRescheduleContactTimeUrl(referralId: string): CancelablePromise<string> {
    return __request({
      method: 'GET',
      path: `/referral/rescheduleContactTimeUrl/${referralId}`,
    });
  }

  /**
   * Update referral protocol
   * @param referralId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static referralControllerUpdateCurrentUserProtocol(
    referralId: string,
    requestBody: ProtocolInfoDto,
  ): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/referral/update-protocol/${referralId}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get referral protocol
   * @param referralId
   * @returns ProtocolListDto
   * @throws ApiError
   */
  public static referralControllerGetCurrentUserProtocol(referralId: string): CancelablePromise<ProtocolListDto> {
    return __request({
      method: 'POST',
      path: `/referral/get-protocol/${referralId}`,
    });
  }

  /**
   * Get Twilio number details by country code
   * @param countryCode
   * @param patientId
   * @returns TwilioPhoneNumberDto
   * @throws ApiError
   */
  public static referralControllerGetTwilioNumberDetails(
    countryCode: string,
    patientId: number,
  ): CancelablePromise<TwilioPhoneNumberDto> {
    return __request({
      method: 'GET',
      path: `/referral/twilio-number-details/${countryCode}/${patientId}`,
    });
  }

  /**
   * Get Twilio number details by country code for sites
   * @param countryCode
   * @returns TwilioPhoneNumberDto
   * @throws ApiError
   */
  public static referralControllerGetTwilioNumberDetailsScc(
    countryCode: string,
  ): CancelablePromise<TwilioPhoneNumberDto> {
    return __request({
      method: 'GET',
      path: `/referral/twilio-number-details-scc/${countryCode}`,
    });
  }

  /**
   * Check if referral is locked by other employee
   * @param referralId
   * @returns ReferralLockDto
   * @throws ApiError
   */
  public static referralControllerCheckReferralLock(referralId: string): CancelablePromise<ReferralLockDto> {
    return __request({
      method: 'GET',
      path: `/referral/checkReferralLock/${referralId}`,
    });
  }

  /**
   * release referral lock
   * @param referralId
   * @returns ReferralLockDto
   * @throws ApiError
   */
  public static referralControllerReleaseReferralLock(referralId: string): CancelablePromise<ReferralLockDto> {
    return __request({
      method: 'POST',
      path: `/referral/releaseReferralLock/${referralId}`,
    });
  }

  /**
   * add/update referral availability
   * @param requestBody
   * @returns ReferralInvestigatorDto
   * @throws ApiError
   */
  public static referralControllerAddReferralAvailability(
    requestBody: ReferralUpdateDto,
  ): CancelablePromise<ReferralInvestigatorDto> {
    return __request({
      method: 'POST',
      path: `/referral/addAvailability`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create a note for pss in site contact center
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static pssNoteControllerCreate(requestBody: PssNoteDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/pss-note`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get pss notes
   * @param studyId
   * @param siteId
   * @param languageCode
   * @param all
   * @returns any
   * @throws ApiError
   */
  public static pssNoteControllerGet(
    studyId: string,
    siteId: string,
    languageCode: string,
    all: boolean,
  ): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/pss-note`,
      query: {
        studyId: studyId,
        siteId: siteId,
        languageCode: languageCode,
        all: all,
      },
    });
  }

  /**
   * Get supported countries
   * @returns SupportedCountryDto
   * @throws ApiError
   */
  public static supportedCountryControllerList(): CancelablePromise<Array<SupportedCountryDto>> {
    return __request({
      method: 'GET',
      path: `/supportedcountry`,
    });
  }

  /**
   * Get Time zones
   * @param countryCode
   * @returns TimeZoneDto
   * @throws ApiError
   */
  public static supportedCountryControllerTimeZone(countryCode?: string): CancelablePromise<Array<TimeZoneDto>> {
    return __request({
      method: 'GET',
      path: `/supportedcountry/timezone`,
      query: {
        countryCode: countryCode,
      },
    });
  }

  /**
   * Get patient
   * @param internalId
   * @returns PatientDto
   * @throws ApiError
   */
  public static patientControllerGet(internalId: number): CancelablePromise<PatientDto> {
    return __request({
      method: 'GET',
      path: `/patient/${internalId}`,
    });
  }

  /**
   * Get patient by id
   * @param id
   * @returns PatientDto
   * @throws ApiError
   */
  public static patientControllerGetById(id: number): CancelablePromise<PatientDto> {
    return __request({
      method: 'GET',
      path: `/patient/byId/${id}`,
    });
  }

  /**
   * Update patient phone number with email and preserve history
   * @param id
   * @param phoneNumber
   * @param email
   * @returns string
   * @throws ApiError
   */
  public static patientControllerPatchContactDetails(id: number, phoneNumber: string, email: string): CancelablePromise<string> {
    return __request({
      method: 'PATCH',
      path: `/patient/contact-details`,
      query: {
        id,
        phoneNumber,
        email,
      },
    });
  }

  /**
   * Get sites added to studies that have a call center
   * @returns SiteHavingStudyDto
   * @throws ApiError
   */
  public static studySiteControllerGetSitesHavingStudy(): CancelablePromise<Array<SiteHavingStudyDto>> {
    return __request({
      method: 'GET',
      path: `/studysite/sites`,
    });
  }

  /**
   * Get sites that have go contact enabled
   * @param email
   * @param requestBody
   * @returns StudySiteSearchResult
   * @throws ApiError
   */
  public static studySiteControllerGetSitesWithContactFlag(
    email: string,
    requestBody: StudySiteSearchParametersDto,
  ): CancelablePromise<StudySiteSearchResult> {
    return __request({
      method: 'POST',
      path: `/studysite/sites-with-contact-flag/${email}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Put site-study
   * @param requestBody
   * @returns StudySiteDto
   * @throws ApiError
   */
  public static studySiteControllerPut(requestBody: StudySiteUpdateDto): CancelablePromise<StudySiteDto> {
    return __request({
      method: 'PUT',
      path: `/studysite`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Put site-study from admin panel
   * @param requestBody
   * @returns StudySiteDto
   * @throws ApiError
   */
  public static studySiteControllerPutAdmin(requestBody: StudySitePutAdminDto): CancelablePromise<StudySiteDto> {
    return __request({
      method: 'PUT',
      path: `/studysite/admin`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Put site-study from admin panel
   * @param id
   * @returns StudyCallbackTimeslotDto
   * @throws ApiError
   */
  public static studySiteControllerUpdateGoContactFlag(id: string): CancelablePromise<StudyCallbackTimeslotDto> {
    return __request({
      method: 'PUT',
      path: `/studysite/updateGoContactFlag/${id}`,
    });
  }

  /**
   * Get studies by site
   * @param siteId
   * @param skip
   * @param take
   * @returns StudySiteSearchResult
   * @throws ApiError
   */
  public static studySiteControllerGetBySiteId(
    siteId: string,
    skip: number,
    take: number,
  ): CancelablePromise<StudySiteSearchResult> {
    return __request({
      method: 'GET',
      path: `/studysite/get-by-site/${siteId}/${skip}/${take}`,
    });
  }

  /**
   * Get site-study
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static studySiteControllerGet(id: string): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/studysite/${id}`,
    });
  }

  /**
   * Delete site-study
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static studySiteControllerDelete(id: string): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      path: `/studysite/${id}`,
    });
  }

  /**
   * Get site-study for Site Contact center
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static studySiteControllerGetForSiteContactCenter(id: string): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/studysite/scc/${id}`,
    });
  }

  /**
   * Get sites by study id
   * @param studyId
   * @param spmId
   * @param code
   * @returns SiteWithAllStudiesDto
   * @throws ApiError
   */
  public static studySiteControllerGetSitesAndStudiesByStudyId(
    studyId: string,
    spmId: number,
    code: string,
  ): CancelablePromise<Array<SiteWithAllStudiesDto>> {
    return __request({
      method: 'GET',
      path: `/studysite/sitesByStudyId/${studyId}/${spmId}/${code}`,
    });
  }

  /**
   * Get All protocols and performance data for a study site
   * @param studySiteId
   * @returns StudySiteProtocolsDto
   * @throws ApiError
   */
  public static studySiteControllerGetStudySiteProtocols(
    studySiteId: number,
  ): CancelablePromise<Array<StudySiteProtocolsDto>> {
    return __request({
      method: 'GET',
      path: `/studysite/protocols/${studySiteId}`,
    });
  }

  /**
   * Get site-study investigators
   * @param siteStudyId
   * @returns InvestigatorDto
   * @throws ApiError
   */
  public static investigatorControllerGet(siteStudyId: number): CancelablePromise<Array<InvestigatorDto>> {
    return __request({
      method: 'GET',
      path: `/investigator`,
      query: {
        siteStudyId: siteStudyId,
      },
    });
  }

  /**
   * Post site-study investigator
   * @param requestBody
   * @returns InvestigatorDto
   * @throws ApiError
   */
  public static investigatorControllerPut(requestBody: InvestigatorUpdateDto): CancelablePromise<InvestigatorDto> {
    return __request({
      method: 'PUT',
      path: `/investigator`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove investigators for all related protocols and locales
   * @param studySite
   * @param siteEmployeeId
   * @returns InvestigatorDto
   * @throws ApiError
   */
  public static investigatorControllerDeleteByStudySiteAndEmployee(
    studySite: string,
    siteEmployeeId: string,
  ): CancelablePromise<Array<InvestigatorDto>> {
    return __request({
      method: 'DELETE',
      path: `/investigator`,
      query: {
        studySite: studySite,
        siteEmployeeId: siteEmployeeId,
      },
    });
  }

  /**
   * Remove investigator
   * @param id
   * @returns InvestigatorDto
   * @throws ApiError
   */
  public static investigatorControllerDelete(id: number): CancelablePromise<InvestigatorDto> {
    return __request({
      method: 'DELETE',
      path: `/investigator/${id}`,
    });
  }

  /**
   * Get site-study investigators
   * @returns MultipleInvestigatorNotificationUpdateDto
   * @throws ApiError
   */
  public static investigatorControllerGetInvestigatorStudies(): CancelablePromise<MultipleInvestigatorNotificationUpdateDto> {
    return __request({
      method: 'GET',
      path: `/investigator/studies`,
    });
  }

  /**
   * update investigator notifications
   * @param requestBody
   * @returns InvestigatorDto
   * @throws ApiError
   */
  public static investigatorControllerUpdateInvestigatorNotifications(
    requestBody: MultipleInvestigatorNotificationUpdateDto,
  ): CancelablePromise<InvestigatorDto> {
    return __request({
      method: 'PUT',
      path: `/investigator/updateNotification`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get patient contact history by referralId and phone number
   * @param referralId
   * @param phoneNumber
   * @param filterType
   * @returns LogInstance
   * @throws ApiError
   */
  public static contactCenterControllerGetLogs(
    referralId: string,
    phoneNumber: string,
    filterType: string,
  ): CancelablePromise<Array<LogInstance>> {
    return __request({
      method: 'GET',
      path: `/contact-center/logs`,
      query: {
        referralId: referralId,
        phoneNumber: phoneNumber,
        filterType: filterType,
      },
    });
  }

  /**
   * Create a Twilio task
   * @param requestBody
   * @returns ContactCenterCallPostResultDto
   * @throws ApiError
   */
  public static contactCenterControllerCall(
    requestBody: ContactCenterCallPostParametersDto,
  ): CancelablePromise<ContactCenterCallPostResultDto> {
    return __request({
      method: 'POST',
      path: `/contact-center/call`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * send sms to patients
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static contactCenterControllerSms(requestBody: ContactCenterSmsPostParametersDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/contact-center/sms`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Updates referral status in case the call counter is greater than 10
   * @param referralId
   * @param phoneNumber
   * @returns ProcessCall
   * @throws ApiError
   */
  public static contactCenterControllerProcessCall(
    referralId: string,
    phoneNumber: string,
  ): CancelablePromise<ProcessCall> {
    return __request({
      method: 'PATCH',
      path: `/contact-center/process-call`,
      query: {
        referralId: referralId,
        phoneNumber: phoneNumber,
      },
    });
  }

  /**
   * create callbackTimeslot
   * @param id
   * @param requestBody
   * @returns PatientDto
   * @throws ApiError
   */
  public static callbackTimeslotControllerCreateCallbackTimeslot(
    id: string,
    requestBody: CallbackTimeslotDto,
  ): CancelablePromise<PatientDto> {
    return __request({
      method: 'POST',
      path: `/callback-timeslot/${id}/callbackTimeslot`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * update referral callbackTimeslot
   * @param id
   * @param requestBody
   * @returns CallbackTimeslotDto
   * @throws ApiError
   */
  public static callbackTimeslotControllerUpdateCallbackTimeslot(
    id: number,
    requestBody: CallbackTimeslotDto,
  ): CancelablePromise<CallbackTimeslotDto> {
    return __request({
      method: 'PATCH',
      path: `/callback-timeslot/callbackTimeslot/${id}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Increment number of contacts per contact time slot
   * @param referralId
   * @returns NumberOfContacts
   * @throws ApiError
   */
  public static callbackTimeslotControllerIncrementContactCounter(
    referralId: string,
  ): CancelablePromise<NumberOfContacts> {
    return __request({
      method: 'PATCH',
      path: `/callback-timeslot/increment-contact-counter/${referralId}`,
    });
  }

  /**
   * Get number of contacts per contact time slot
   * @param referralId
   * @returns NumberOfContacts
   * @throws ApiError
   */
  public static callbackTimeslotControllerNumberOfContacts(referralId: string): CancelablePromise<NumberOfContacts> {
    return __request({
      method: 'GET',
      path: `/callback-timeslot/number-of-contacts/${referralId}`,
    });
  }

  /**
   * Create a note
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static referralNoteControllerCreate(requestBody: ReferralNoteDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/referral-note`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get spm list
   * @returns AllocationDto
   * @throws ApiError
   */
  public static spmControllerGetList(): CancelablePromise<Array<AllocationDto>> {
    return __request({
      method: 'GET',
      path: `/spm/list`,
    });
  }

  /**
   * Search spm
   * @param requestBody
   * @returns SpmSearchResult
   * @throws ApiError
   */
  public static spmControllerSearchSpm(requestBody: SpmParameterDto): CancelablePromise<SpmSearchResult> {
    return __request({
      method: 'POST',
      path: `/spm/getSpms`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * get spm by id
   * @param id
   * @returns SpmDto
   * @throws ApiError
   */
  public static spmControllerGetSpmById(id: string): CancelablePromise<SpmDto> {
    return __request({
      method: 'GET',
      path: `/spm/getById/${id}`,
    });
  }

  /**
   * Get spm allocations
   * @param studyName
   * @param siteName
   * @param countryName
   * @param id
   * @param take
   * @param skip
   * @returns SpmAllocationSearchResult
   * @throws ApiError
   */
  public static spmControllerGetSpmAllocationsBySpmId(
    studyName: string,
    siteName: string,
    countryName: string,
    id: string,
    take: number,
    skip: number,
  ): CancelablePromise<SpmAllocationSearchResult> {
    return __request({
      method: 'GET',
      path: `/spm/getAllocationsBySpmId/${id}/${take}/${skip}`,
      query: {
        studyName: studyName,
        siteName: siteName,
        countryName: countryName,
      },
    });
  }

  /**
   * Post save spm
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static spmControllerPut(requestBody: SpmDto): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      path: `/spm`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * patch update spm
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static spmControllerUpdate(requestBody: SpmDto): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      path: `/spm`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * patch add allocation
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static spmControllerAddAllocation(requestBody: UpdateAllocationDto): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      path: `/spm/addAllocation`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * delete allocation allocation
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static spmControllerDeleteAllocation(id: string): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      path: `/spm/deleteAllocation/${id}`,
    });
  }

  /**
   * Remove Spm
   * @param email
   * @returns any
   * @throws ApiError
   */
  public static spmControllerDelete(email: string): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      path: `/spm/${email}`,
    });
  }

  /**
   * Allocate study to spm
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static spmControllerAllocateStudy(requestBody: UpdateStudyAllocationDto): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      path: `/spm/allocateStudy`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get list of the study allocations
   * @param id
   * @param take
   * @param skip
   * @returns any
   * @throws ApiError
   */
  public static spmControllerSpmStudies(id: string, take: number, skip: number): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/spm/studies/${id}/${take}/${skip}`,
    });
  }

  /**
   * Get list of the study allocations by email
   * @param email
   * @returns StudyDto
   * @throws ApiError
   */
  public static spmControllerSpmStudiesByEmail(email: string): CancelablePromise<Array<StudyDto>> {
    return __request({
      method: 'GET',
      path: `/spm/studies/${email}`,
    });
  }

  /**
   * Get list of the sites allocated to spm by email
   * @param email
   * @returns SiteInfoDto
   * @throws ApiError
   */
  public static spmControllerSpmSitesByEmail(email: string): CancelablePromise<Array<SiteInfoDto>> {
    return __request({
      method: 'GET',
      path: `/spm/sites/${email}`,
    });
  }

  /**
   * Remove study allocation
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static spmControllerDeleteStudyAllocation(id: string): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      path: `/spm/deleteStudyAllocation/${id}`,
    });
  }

  /**
   * create callbackTimeslot
   * @param id
   * @param requestBody
   * @returns SiteCallbackTimeslotDto
   * @throws ApiError
   */
  public static siteCallbackTimeslotControllerCreateSiteCallbackTimeslot(
    id: number,
    requestBody: CreateSiteCallbackTimeslotDto,
  ): CancelablePromise<SiteCallbackTimeslotDto> {
    return __request({
      method: 'POST',
      path: `/site-callback-timeslot/${id}/siteCallbackTimeslot`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * update referral callbackTimeslot
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static siteCallbackTimeslotControllerUpdateSiteCallbackTimeslot(
    id: number,
    requestBody: CreateSiteCallbackTimeslotDto,
  ): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      path: `/site-callback-timeslot/siteCallbackTimeslot/${id}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * update referral callbackTimeslot
   * @param studyId
   * @param siteId
   * @param languageCode
   * @returns SiteCallbackTimeslotDto
   * @throws ApiError
   */
  public static siteCallbackTimeslotControllerGetSiteCallbackTimeslotByStudySiteId(
    studyId: string,
    siteId: string,
    languageCode: string,
  ): CancelablePromise<Array<SiteCallbackTimeslotDto>> {
    return __request({
      method: 'GET',
      path: `/site-callback-timeslot/siteCallbackTimeslot/${studyId}/${siteId}/${languageCode}`,
    });
  }

  /**
   * inactivate callbackTimeslot
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static siteCallbackTimeslotControllerInactivateCallBackTimeSlot(id: number): CancelablePromise<any> {
    return __request({
      method: 'PATCH',
      path: `/site-callback-timeslot/inactivateCallBackTimeSlot/${id}`,
    });
  }

  /**
   * update referral callbackTimeslot
   * @param id
   * @returns PossibleCallTypes
   * @throws ApiError
   */
  public static siteCallbackTimeslotControllerGetPossibleCallTypes(id: number): CancelablePromise<PossibleCallTypes> {
    return __request({
      method: 'GET',
      path: `/site-callback-timeslot/possibleCallTypes/${id}`,
    });
  }

  /**
   * get site performance by studySite Id
   * @param id
   * @returns SitePerformanceActivityDto
   * @throws ApiError
   */
  public static sitePerformanceControllerGet(id: number): CancelablePromise<Array<SitePerformanceActivityDto>> {
    return __request({
      method: 'GET',
      path: `/sitePerformance/${id}`,
    });
  }

  /**
   * create site performance entry
   * @param id
   * @param requestBody
   * @returns SitePerformanceActivityDto
   * @throws ApiError
   */
  public static sitePerformanceControllerCreate(
    id: number,
    requestBody: SitePerformanceActivityDto,
  ): CancelablePromise<SitePerformanceActivityDto> {
    return __request({
      method: 'PUT',
      path: `/sitePerformance/${id}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * get site performance possible options to add by studySiteId
   * @param studySiteId
   * @returns SitePerformancePossibleOptionsDto
   * @throws ApiError
   */
  public static sitePerformanceControllerGetPerformanceOptions(
    studySiteId: number,
  ): CancelablePromise<Array<SitePerformancePossibleOptionsDto>> {
    return __request({
      method: 'GET',
      path: `/sitePerformance/performanceOptions/${studySiteId}`,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static siteEscalationControllerFindAll(): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/site-escalations`,
    });
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static siteEscalationControllerCreate(requestBody: SiteEscalationDTO): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/site-escalations`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static siteEscalationControllerFindOne(id: number): CancelablePromise<any> {
    return __request({
      method: 'GET',
      path: `/site-escalations/${id}`,
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static siteEscalationControllerUpdate(id: number, requestBody: SiteEscalationDTO): CancelablePromise<any> {
    return __request({
      method: 'PUT',
      path: `/site-escalations/${id}`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static siteEscalationControllerRemove(id: number): CancelablePromise<any> {
    return __request({
      method: 'DELETE',
      path: `/site-escalations/${id}`,
    });
  }

  /**
   * @param studyId
   * @param siteId
   * @returns EscalationCountDTO
   * @throws ApiError
   */
  public static siteEscalationControllerFindByStudySiteId(
    studyId: string,
    siteId: string,
  ): CancelablePromise<Array<EscalationCountDTO>> {
    return __request({
      method: 'GET',
      path: `/site-escalations/by-site/${studyId}/${siteId}`,
    });
  }

  /**
   * Send email to the site
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static siteEmailControllerSendEmail(requestBody: SiteEmailDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/site-email/send`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create a note for site in site contact center
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static siteNoteControllerCreate(requestBody: SiteNoteDto): CancelablePromise<any> {
    return __request({
      method: 'POST',
      path: `/site-note`,
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
