import * as React from 'react';
import { Store } from 'store';
import { Modals } from 'components/modals';

export const ModalContainer = () => {
  const { modalType, modalProps } = Store.useModal();

  if (!modalType) {
    return null;
  }

  const OpenedModal = Modals[modalType];

  // This error can be ignored since we want typechecking to be done when we open a specific modal and
  // ModalContainer doesn't have enough info to properly infer types unless we open a specific modal
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <OpenedModal {...modalProps} />;
};
