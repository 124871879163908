import React, { ChangeEvent } from 'react';
import { Filter } from '@clariness/filter';
import { LabeledCheckbox } from '@clariness/labeled-checkbox';
import * as _ from 'lodash-es';
import { useReferralTableFilterContext } from 'pages/Home/components';
import { useIntl } from 'react-intl';

export const ReferralTableFilterDistance = () => {
  const { localFilterOptions, setLocalFilterOptions } = useReferralTableFilterContext();

  const intl = useIntl();

  const isChecked = (value: Record<string, unknown>) => (
    Boolean(localFilterOptions?.distance?.find(distance => _.isEqual(distance, value)))
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>, value: Record<string, unknown>) => {
    if (e.target.checked) {
      const distance = localFilterOptions?.distance || [];
      setLocalFilterOptions({ ...localFilterOptions, distance: [...distance, value] });
    } else {
      const distance = localFilterOptions?.distance
        ?.filter(distance => !_.isEqual(distance, value));
      setLocalFilterOptions({ ...localFilterOptions, distance });
    }
  };

  return (
    <Filter
      label={intl.formatMessage({
        id: 'is.main_page.referrals_table.distance_filter_label',
        defaultMessage: 'Distance',
      })}
      isSelected={Boolean(localFilterOptions?.distance?.length)}
      data-test-id="filter-distance-select"
    >
      <Filter.List>
        <Filter.ListItem>
          <LabeledCheckbox
            label={intl.formatMessage({
              id: 'is.main_page.referrals_table.distance_filter.under_10',
              defaultMessage: 'Under 10km',
            })}
            checked={isChecked({ from: 0, to: 10 })}
            onChange={e => onChange(e, { from: 0, to: 10 })}
          />
        </Filter.ListItem>

        <Filter.ListItem>
          <LabeledCheckbox
            label={intl.formatMessage({
              id: 'is.main_page.referrals_table.distance_filter.10_20',
              defaultMessage: '10 - 20km',
            })}
            checked={isChecked({ from: 10, to: 20 })}
            onChange={e => onChange(e, { from: 10, to: 20 })}
          />
        </Filter.ListItem>

        <Filter.ListItem>
          <LabeledCheckbox
            label={intl.formatMessage({
              id: 'is.main_page.referrals_table.distance_filter.20_50',
              defaultMessage: '20 - 50km',
            })}
            checked={isChecked({ from: 20, to: 50 })}
            onChange={e => onChange(e, { from: 20, to: 50 })}
          />
        </Filter.ListItem>

        <Filter.ListItem>
          <LabeledCheckbox
            label={intl.formatMessage({
              id: 'is.main_page.referrals_table.distance_filter.over_50',
              defaultMessage: 'Over 50km',
            })}
            checked={isChecked({ from: 50 })}
            onChange={e => onChange(e, { from: 50 })}
          />
        </Filter.ListItem>
      </Filter.List>
    </Filter>
  );
};
