import { useModal } from './modals';
import { useUser } from './user';
import { useNotifications } from './notifications';

export * from './StoreProvider';

export const Store = {
  useModal,
  useUser,
  useNotifications,
};
